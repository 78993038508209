import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import { DependentItemContent, Row, Content, EditDependentContent } from './style';
import { DependentData, Context } from '../../store';
import EditDependentItem from './EditDependentItem';
import { RELATIONSHIP_DATA } from '../../utils';
import {SUFFIX_OPTION} from "../infoDetails/config";

const DependentItem = ({ data, addDependent, confirmDependent, civil, editIndex, setOpenExitDependent, cannotRemove }: {
  data: DependentData;
  civil: string;
  addDependent: boolean;
  confirmDependent: (confirmData: DependentData, editedData?: DependentData, isEdit?: 'isEdit' | 'isRemove', editIndex?: number,) => void;
  editIndex: number;
  setOpenExitDependent: React.Dispatch<React.SetStateAction<boolean>>;
  cannotRemove?: boolean
}) => {
  const { store: { dependentDatas } } = useContext(Context);
  const [openEdit, setOpenEdit] = useState(false);

  const cancelClick = useCallback(() => {
    setOpenEdit(false);
    setOpenExitDependent(false);
  }, [setOpenExitDependent]);
  const eidtClcik = () => {
    setOpenEdit(true);
    setOpenExitDependent(true);
  };
  useEffect(() => {
    if (addDependent) {
      cancelClick();
    }
  }, [addDependent, cancelClick]);
  const confirmThisDependent = (dependent: DependentData) => {
    cancelClick();
    confirmDependent(dependent, data, 'isEdit', editIndex);
  };

  const suffix = useMemo(() => (
    data.suffix && data.suffix !== 'NA'? SUFFIX_OPTION.find(item => item.value === data.suffix)?.label : ''
  ), [data.suffix]);

  return (
    <Content>
      {!openEdit && (
        <DependentItemContent addDependent={addDependent ? 0 : 1}>
          <Row marginBottom={37} cannotRemove={cannotRemove}>
            <div className="title-text">{`Dependent ${(dependentDatas.indexOf(data) + 1)}`}</div>
            <div className="edit-text" onClick={eidtClcik}>EDIT</div>
            {!cannotRemove && <div className="remove-text" onClick={() => confirmDependent(data, undefined, 'isRemove', editIndex)}>REMOVE</div>}
          </Row>
          <Row>
            <div className="sub-title-text">Name</div>
            <div className="sub-value-text">{data.name}</div>
          </Row>
          <Row>
            <div className="sub-title-text">Relationship</div>
            <div className="sub-value-text">{RELATIONSHIP_DATA[data.relationShip]}</div>
          </Row>
          <Row>
            <div className="sub-title-text">Suffix</div>
            <div className="sub-value-text">{suffix}</div>
          </Row>
          <Row>
            <div className="sub-title-text">Date of Birth</div>
            <div className="sub-value-text">{moment(new Date(data.dob)).utc().format('MM/DD/YYYY')}</div>
          </Row>
          {/* <Row>
            <div className="sub-title-text">Place of Birth</div>
            <div className="sub-value-text">{data.placeOfBirth}</div>
          </Row> */}
          <Row>
            <div className="sub-title-text">Gender</div>
            <div className="sub-value-text">{data.gender}</div>
          </Row>
          <Row>
            <div className="sub-title-text">Addtional Cost</div>
            <div className="sub-value-text">{`PHP ${data.amount}/month`}</div>
          </Row>
        </DependentItemContent>
      )}
      {openEdit && (
        <EditDependentContent>
          <EditDependentItem addDependent={confirmThisDependent} cancel={cancelClick} editData={data} civil={civil} />
        </EditDependentContent>
      )}
    </Content>
  );
};

export default DependentItem;
