import React from 'react';
import styled from 'styled-px2vw';

const LoadingContainer = styled.div`
  width: auto;
  height: 80px;

  span{
    display: inline-block;
    width: 16px;
    height: 100%;
    border-radius: 8px;
    background: #0084FF;
    margin: 0 6px;
    animation: load 1.04s ease infinite;
  }
  @keyframes load{
    0%, 100%{
      height: 80px;
      background: #0084FF;
    }
    50%{
      height: 120px;
      margin-top: -40px;
      background: #0084FF;
    }
  }
  & span:nth-child(2){
    animation-delay:0.13s;
  }
  & span:nth-child(3){
    animation-delay:0.26s;
  }
  & span:nth-child(4){
    animation-delay:0.39s;
  }
  & span:nth-child(5){
    animation-delay:0.52s;
  }

`;

const CustomerLoading = () => {
  return (
    <LoadingContainer>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </LoadingContainer>
  );
};

export default CustomerLoading;
