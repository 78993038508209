import React, { FC, useMemo, useState, useEffect, useRef } from 'react';

import Button from '../../components/Button';
import { VCodeWrap, VCodeTipWrap, DigitDivGroup, DigitWrap, DigitDiv, VerificationCodeTitle, VCodeError } from './style';
import { Input } from '../CheckOut/style';
import { MOBILE_CODE } from './config';

// const DigitNumber = 6;

interface IVerifyMobileNumberProps {
  mobileNumber?: string;
  vCode: any,
  pageId: string;
  onChange: (val: any) => void;
  countDown: number;
  sendSMSCode: () => void;
  sendError?: boolean;
  digitNumber?: number;
  onChangeCountDown?: (value: any) => void;
}

const VerifyMobileNumber:FC<IVerifyMobileNumberProps> = ({
  mobileNumber,
  vCode,
  pageId,
  onChange,
  countDown,
  sendSMSCode,
  sendError,
  digitNumber = 4,
  onChangeCountDown,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (pageId === 'vCode') inputRef.current.focus();
  }, [pageId]);

  const inputError = useMemo(() => (
    mobileNumber ? mobileNumber.length !== 10 : false
  ), [mobileNumber]);

  const isVCode = useMemo(() => (pageId !== 'confirm'), [pageId]);

  const currentFocusedDigitDivClassName = (index: number) => {
    if (!isFocused) {
      return '';
    }
    if (index === digitNumber - 1 && vCode.length === digitNumber) {
      return 'current-digit-div current-digit-div2';
    }
    const curIndex = vCode.length === 0 ? 0 : vCode.length;
    return curIndex === index ? 'current-digit-div' : '';
  };

  const onChangeInput = (value: any) => {
    const curVal = value.replace(/[^\d]/g, '');
    onChange(pageId === 'confirm' ? curVal : curVal.toString());
  };

  return (
    <>
      <VerificationCodeTitle>{MOBILE_CODE(mobileNumber)?.[pageId]?.filedTitle}</VerificationCodeTitle>
      {
        pageId === 'confirm' ? (
          <Input
            value={mobileNumber}
            onChange={e => onChange(e.target.value.replace(/[^\d]/g, ''))}
            width={646}
            addonBefore={isVCode ? '' : '+63'}
            inputMode="numeric"
            type="text"
            phone={0}
            error={inputError}
          />
        ) : (
          <DigitDivGroup>
            <Input
              className="code-input"
              value={vCode}
              onChange={e => onChangeInput(e.target.value)}
              width={646}
              ref={inputRef}
              inputMode="numeric"
              type="text"
              maxLength={digitNumber}
              phone={1}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            <DigitWrap>
              {
                Array.from(Array(digitNumber).keys()).map(d => (
                  <DigitDiv
                    className={currentFocusedDigitDivClassName(d)}
                    key={d}
                    onClick={() => inputRef.current.focus()}
                    digitNumber={digitNumber}
                    error={sendError}
                  >
                    {vCode.split('')[d] || ''}
                  </DigitDiv>
                ))
              }
            </DigitWrap>
          </DigitDivGroup>
        )
      }
      {
        sendError ? (
          <VCodeError>The code is wrong. Please retry.</VCodeError>
        ) : null
      }
      {
        isVCode ? (
          <VCodeWrap>
            <VCodeTipWrap>
              {/* {
                sendError ? (
                  <div className="code-error">The code is wrong. Please retry.</div>
                ) : null
              } */}
              Didn‘t get the code？
            </VCodeTipWrap>
            <Button
              width={97}
              height={40}
              margin="margin-left: 22px;"
              types={countDown ? 'disabled' : 'ordinary'}
              onClick={sendSMSCode}
            >
              {countDown ? `${countDown}s` : 'Resend code'}
              {/* {countDown ? (
                <>
                  <CountDown value={countDown} format="ss" onChange={onChangeCountDown} />
                  s
                </>
              ) : 'Resend code'} */}
            </Button>
          </VCodeWrap>
        ) : null
      }
    </>
  );
};

export default VerifyMobileNumber;
