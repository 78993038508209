import { Toast } from 'antd-mobile';

export const closeLoading = () => {
  Toast.hide();
};
export const openLoading = () => {
  Toast.loading('loading...', 0, undefined, true);
};
export function getQueryVariable(name: string) {
  const reg = new RegExp("(^|&)" + name + '=([^&]*)(&|$)', 'i');
  const arr = window.location.href.split('?');
  if (arr.length === 1) {
    return null;
  }
  const r = arr[1].match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
export const MAX_DEPENDENT_LENGTH = 5;
export const CHILD_AGE = 18;
export const MAX_AGE = 65;
export const MIN_AGE_DAY = 14;
export const PARENT_AGE = 21;
export const PAY_SUCCESS_CODE = ['GR001', 'GR002'];
export const BALANCE_INSUFFICIENT = 'GR071';
export const PARENT_RELATIONSHIP = 'Parent';
export const PARENT_RELATIONSHIP_CODE = 3;
export const SPOUSE_RELATIONSHIP_CODE = 2;
export const SPOUSE_RELATIONSHIP = 'Spouse';
export const SIBLING_RELATIONSHIP = 'Sibling';
export const SIBLING_RELATIONSHIP_CODE = 4;
export const CHILD_RELATIONSHIP = 'Child';
export const CHILD_RELATIONSHIP_CODE = 5;
export const RELATIONSHIP_DATA: Record<string, any> = {
  [PARENT_RELATIONSHIP_CODE]: 'Parent',
  [SPOUSE_RELATIONSHIP_CODE]: 'Spouse',
  [SIBLING_RELATIONSHIP_CODE]: 'Sibling',
  [CHILD_RELATIONSHIP_CODE]: 'Child',
};
export const DATE_OF_BIRTH_EMPTY = 'Please enter Date of Birth.';
export const GENDER_EMPTY = 'Please select the gender.';
export const RELATIONSHIP_EMPTY = 'Please select the Relationship.';
export const SUFFIX_EMPTY = 'Please select the Suffix.';
export const ADULT_ERROR = 'Age should be greater than or equal to 18 years old and less than or equal to 64 years old.';
export const YOUNG_ERROR = 'Age should be greater than or equal to 14 days old and less than or equal to 21 years old.';
export const INPUT_NAME_ERROR = 'Please input a valid name.';
export const RELATIONSHIP_ERROR = 'You\'ve reached the maximum number of dependents. If you wish to add more, you may send us an email at BPIAIA.CustomerService@aia.com.';

export const SINGLE = 'Single';
export const MARRIED = 'Married';
export const SEPARATED = 'Separated';
export const WIDOWED = 'Widowed';
export const SINGLE_PARENT = 'Single Parent';

export const CIVIL_RELATIONS: any = {
  [SINGLE]: [
    { label: PARENT_RELATIONSHIP, value: PARENT_RELATIONSHIP_CODE },
    { label: SIBLING_RELATIONSHIP, value: SIBLING_RELATIONSHIP_CODE },
  ],
  [MARRIED]: [
    { label: SPOUSE_RELATIONSHIP, value: SPOUSE_RELATIONSHIP_CODE },
    { label: CHILD_RELATIONSHIP, value: CHILD_RELATIONSHIP_CODE },
  ],
  [SINGLE_PARENT]: [
    { label: CHILD_RELATIONSHIP, value: CHILD_RELATIONSHIP_CODE },
  ],
  [WIDOWED]: [
    { label: CHILD_RELATIONSHIP, value: CHILD_RELATIONSHIP_CODE },
  ],
  [SEPARATED]: [
    { label: CHILD_RELATIONSHIP, value: CHILD_RELATIONSHIP_CODE },
  ],
};

export const NAME_REG = /^[a-zA-Z0-9-.' ]+$/;

export function replaceString(info:any, searchValue:string = '\\', replaceValue:string = ''){
  if(info){
    if(typeof info === 'string'){
      return info.replace(searchValue, replaceValue)
    }
    else if(typeof info === 'object' && !Array.isArray(info)){
      const newInfo = info
      Object.keys(info).forEach((key)=>{
        if(typeof newInfo[key] === 'string'){
          newInfo[key] = newInfo[key].replace(searchValue, replaceValue)
        }
      })
      return newInfo
    }
    else if(Array.isArray(info)){
      info.forEach((element) => {
        return replaceString(element, searchValue, replaceValue)
      });
    }
  }
  return info
}

export const closeMessengerBrowser = ()=>{
  let isClosed = false;
  (window as any).MessengerExtensions.requestCloseBrowser((res:any) => {
    // webview closed
    isClosed = true;
  }, (error:any) => {
    // Toast.fail('Failed to close the page.', 1);
  });
  setTimeout(()=>{
    if(!isClosed){
        Toast.success('Closing the window...')
        let urlToClose = 'https://www.messenger.com/closeWindow/'
        urlToClose += `?image_url=${window.location.origin}/images/circle_light-green.png`
        urlToClose += '&display_text=Success'
        window.location.replace(urlToClose)
    }
  }, 1000)
}

export { default as isEmptyField } from './check-params';
