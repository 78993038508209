import React, { FC, useMemo } from 'react'
import styled from 'styled-px2vw'
import moment from 'moment'
import {CHECKOUT_FILED_MAP} from './config'
import {FIELD_NAME_MAP} from '../infoDetails/config'

const InfoDataViewWrapper = styled.div<{hasMarginTop?: boolean}>`
  margin-top: ${props => props.hasMarginTop ? '32px' : 0};
`;

const InfoDataViewItemList = styled.div``

const InfoDataViewTitleWrapper = styled.div<{isEdit?: boolean}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.isEdit ? 0 : '48px'};
`;

const InfoDataViewTitle = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  height: 32px;
  color: #000000;
`;

const InfoDataViewButtonWrapper = styled.div`
  display: flex;
`;

const InfoDataViewButton = styled.div<{disabled?: boolean}>`
  line-height: 32px;
  height: 32px;
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.disabled ? '#a0a0a0' : '#0084FF'};
  &:last-child {
    margin-left: 32px;
  }
`


const InfoDataViewItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  height: 48px;
  align-items: center;
  &:not(:first) {
    margin-top: 0;
  }
`

const InfoDataViewItemTitle = styled.div`
  font-family: 'GT Walsheim';
  font-weight: 400;
  font-size: 24px;
  color: #a0a0a0;
`

const InfoDataViewItemValue = styled.div`
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #333d47;
  max-width: 480px;
  text-align: right;
`

interface IFromatItem {
  key: string,
  title: string,
  format?: (val: any, originData?: any) => string
} 

const referralDataFromatArr: IFromatItem[] = [
  {
    key: 'branchCode',
    title: FIELD_NAME_MAP.branchCode,
  },
  {
    key: 'isReferrer',
    title: FIELD_NAME_MAP.isReferrer,
    format: val => val ? 'Yes' : 'No'
  },
  {
    key: 'bankReferrerCode',
    title: FIELD_NAME_MAP.bankReferrerCode,
  },
  {
    key: 'bse',
    title: FIELD_NAME_MAP.bse,
  },
]

const infoDataFromatArr:IFromatItem[] = [
  {
    key: 'firstName',
    title: 'Name',
    format: (val, originData) => [originData.firstName, originData.middleName, originData.lastName, originData.suffix === 'NA' ? '' : originData.suffix].filter(item => !!item).join(' ')
  },
  {
    key: 'birthday',
    title: FIELD_NAME_MAP.birthday,
    format: val => moment(val).format('DD/MM/YYYY')
  },
  {
    key: 'gender',
    title: FIELD_NAME_MAP.gender
  },
  {
    key: 'mobileNumber',
    title: FIELD_NAME_MAP.mobileNumber,
    format: val => `63 ${val}`
  }
]

const identificationDataFromatArr:IFromatItem[] = [
  {
    key: 'idType',
    title: CHECKOUT_FILED_MAP.idType
  },
  {
    key: 'idNo',
    title: CHECKOUT_FILED_MAP.idNo,
  },
  {
    key: 'idExpiryDate',
    title: CHECKOUT_FILED_MAP.idExpiryDate,
    format: val => val ? moment(val).format('DD/MM/YYYY') : '-'
  },
  {
    key: 'emailAddress',
    title: CHECKOUT_FILED_MAP.emailAddress,
  },
  {
    key: 'maritalStatus',
    title: CHECKOUT_FILED_MAP.maritalStatus,
  },
]

const viewInfo = {
  referrals: {
    header: 'Referral Codes',
    data: referralDataFromatArr,
  },
  info: {
    header: 'Personal Information',
    data: infoDataFromatArr,
  },
  identification: {
    header: 'Identification',
    data: identificationDataFromatArr,
  },
}

interface IInfoDataView {
  onAction: (action: string) => void,
  isEdit?: boolean,
  data: Record<string, any>,
  type: 'info' | 'identification' | 'referrals',
  branchCodeOptions?: {label: string, value: string}[],
  hasMarginTop?: boolean,
  disableEdit?: boolean
}

const InfoDataView:FC<IInfoDataView> = ({
  onAction,
  isEdit,
  data,
  type,
  branchCodeOptions,
  hasMarginTop,
  disableEdit
}) => {
  const currentView = viewInfo[type]
  const viewList = useMemo(() => {
    return currentView.data?.map(item => {
      let value = item.format ? item.format(data[item.key], data) : (data[item.key] || '-')
      if (item.key === 'branchCode' && data[item.key]) {
        const activeBranch = branchCodeOptions?.find(option => option.value === data[item.key])
        if (activeBranch) {
          value = activeBranch.label
        }
      }
      return { title: item.title, value }
    })
  }, [branchCodeOptions, data, currentView])

  return (
    <InfoDataViewWrapper hasMarginTop={hasMarginTop}>
      <InfoDataViewTitleWrapper isEdit={isEdit}>
          <InfoDataViewTitle>{ currentView.header }</InfoDataViewTitle>
          <InfoDataViewButtonWrapper>
            <InfoDataViewButton onClick={() => onAction(isEdit ? 'cancel' : 'edit')} disabled={disableEdit && !isEdit}>
              {isEdit ? 'CANCEL' : 'EDIT'}
            </InfoDataViewButton>
          </InfoDataViewButtonWrapper>
      </InfoDataViewTitleWrapper>
      {
        !isEdit && (
          <InfoDataViewItemList>
            {viewList.map((item, index) => (
              <InfoDataViewItem key={item.title}>
                  <InfoDataViewItemTitle>{item.title}</InfoDataViewItemTitle>
                  <InfoDataViewItemValue>{item.value}</InfoDataViewItemValue>
              </InfoDataViewItem>
            ))}
          </InfoDataViewItemList>
        )
      }
    </InfoDataViewWrapper>
  )
}

export default InfoDataView;