import React, { FC } from 'react';

import { SettlementItemContent, Row } from './style';
import { DependentData } from '../../store';
import { RELATIONSHIP_DATA } from '../../utils';

interface DependentSettlement {
  data: DependentData;
  dataIndex: number;
}

const DependentSettlement:FC<DependentSettlement> = ({ data, dataIndex }) => (
  <SettlementItemContent>
    <Row>
      <div className="title-text">{`Dependent ${dataIndex} - ${RELATIONSHIP_DATA?.[data.relationShip]}`}</div>
      <div className="value-text">{`PHP ${data.amount}`}</div>
    </Row>
  </SettlementItemContent>
);

export default DependentSettlement;
