import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import CheckOut from 'src/Pages/CheckOut';
import Payment from 'src/Pages/Payment';
import PaySuccess from 'src/Pages/PaySuccess';
import InfoDetails from 'src/Pages';
import FriendlyReminder from 'src/Pages/FriendlyReminder';


const RouterContainer = () => {
  return (
    <>
      <Switch>
        <Route path="/CheckOut">
          <CheckOut />
        </Route>
        <Route path="/info-details">
          <InfoDetails />
        </Route>
        <Route path="/Payment">
          <Payment />
        </Route>
        <Route path="/PaySuccess">
          <PaySuccess />
        </Route>
        <Route path="/friendlyReminder">
          <FriendlyReminder />
        </Route>
        <Route path="/">
          <InfoDetails />
        </Route>
      </Switch>
    </>
  );
};

export default RouterContainer;
