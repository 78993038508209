import styled from 'styled-px2vw';
import { Select } from 'antd';

import logoPng from '../../assets/images/logo.png';

const getPx = (px: number) => `${2 * px}px`;

export const ContentWrapper = styled.div<{inSummaryPage?: boolean}>`
  display: flex;
  flex-direction: column;
  padding: ${getPx(26)};
  ${props => props.inSummaryPage ? 'padding-bottom: 0;' : ''}
`;

export const Logo = styled.div`
  width: ${getPx(105)};
  height: ${getPx(37.5)};
  margin: 0 auto ${getPx(26)};
  background-image: url(${logoPng});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const DetailsContent = styled.div<{inSummaryPage?: boolean}>`
  margin-bottom: ${props => props.inSummaryPage ? 0 : '32px'};
  display: flex;
  flex-direction: column;
  width: 646px;
  font-size: 24px;
  line-height: 32px;

  .detail-title-text {
    margin-top: 32px;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
    transition: all .3s;

    .optional {
      margin-left: 16px;
      font-style: italic;
      color: #727272;
    }

    .required {
      margin-left: 8px;
      color: red;
    }

    &.disabled {
      color: #DCDCDC;
    }

    .info-icon {
      width: 32px;
      height: 32px;
      margin-left: 8px;
      vertical-align: text-bottom;
      cursor: pointer;
    }
  }

  .switch-text {
    margin: 32px 0 0 170px;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
  }

  .ant-switch {
    margin-top: 28px;
  }
  .ant-input-group-wrapper,
  .ant-input {
    margin-left: 0;
    margin-top: 0;
  }

  .ant-picker {
    margin-left: 0;
  }
`;

export const MobileNumberVerification = styled.div`
  font-size: 32px;
  line-height: 40px;
  color: #000000;

  .mobile-number {
    font-weight: 500;
  }
`;

export const PageTipTitle = styled.div`
  margin-bottom: ${getPx(16)};
  font-weight: bold;
  font-size: ${getPx(16)};
  line-height: ${getPx(20)};
  color: #000000;
`;

export const ErrorMessage = styled.div`
  margin-top: 16px;
  color: #D50B42;
  display: flex;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    vertical-align: bottom;
  }

  .error-message {
    width: fit-content;
  }
`;

export const TitleDescription = styled.div<{ marginTop?: number }>`
  margin-top: ${props => getPx(props?.marginTop || 0)};
  margin-bottom: ${getPx(20)};
  font-size: ${getPx(12)};
  line-height: ${getPx(16)};
  color: #9A9A9A;
`;

export const BottomButtonWrapper = styled.div`
  width: ${getPx(323)};
  position: absolute;
  bottom: ${getPx(26)};
  display: flex;
  justify-content: space-between;
`;

export const StyleSelect = styled(Select)<{ width?: number }>`
  width: ${props => getPx(props?.width || 323)};
  background: #FFFFFF;
  .ant-select-selector {
    width: ${getPx(323)};
    height: ${getPx(44)} !important;
    line-height: ${getPx(44)};
    border: ${getPx(1)} solid #DCDCDC !important;
    box-sizing: border-box;
    border-radius: ${getPx(4)} !important;
    padding: 0 ${getPx(14.5)} !important;

    .ant-select-selection-item {
      font-size: ${getPx(14)};
      line-height: ${getPx(44)};
    }
    .ant-select-item {
      width: ${getPx(300)};
      padding: ${getPx(5)} 0;
      margin-left: ${getPx(12)};
      border-bottom: ${getPx(1)} solid #EAEAEA;
    }
  }

  .ant-select-selection-placeholder {
    font-size: ${getPx(14)} !important;
    line-height: ${getPx(44)} !important;
    color: #A0A0A0;
  }

  .ant-select-arrow {
    width: ${getPx(14)};
    height: ${getPx(14)};
    top: ${getPx(20)};
    right: ${getPx(15)};
    svg {
      width: ${getPx(14)};
      height: ${getPx(14)};
    }
  }
`;


export const ReferralCodesTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: ${getPx(16)};
  line-height: ${getPx(20)};
  height: ${getPx(20)};
  text-transform: capitalize;
  color: #000000;
`;
