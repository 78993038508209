import React, { FC, useEffect, useMemo, useState } from 'react';
// import moment from 'moment-timezone';
import { cloneDeep } from '@apollo/client/utilities';
import { Toast } from 'antd-mobile';

import Button from '../../components/Button';
import {
  BottomButtonWrapper,
  DetailsContent,
  PageTipTitle,
  TitleDescription,
} from '../common/style';
import { FIELD_NAME_MAP, INFO_DETAILS_MAP_FIELDS, MOBILE_CODE } from './config';
import { Row } from '../CheckOut/style';
import { getQueryVariable, isEmptyField, closeLoading, openLoading, closeMessengerBrowser } from '../../utils';
import { backPage, getVerificationCode, verificationCode } from '../../api';
import VerifyMobileNumber from './VerifyMobileNumber';
import InputField from './InputField';

interface IInfoProps {
  infoDetails: Record<string, any>;
  onChangeInfoDetails: (dataKey: string, value: any) => void;
  mobile?: string;
  pageId: string;
  setPageId: React.Dispatch<React.SetStateAction<string>>;
  onInfoNext: () => void;
}

const Info:FC<IInfoProps> = ({
  infoDetails,
  onChangeInfoDetails,
  mobile,
  pageId,
  setPageId,
  onInfoNext,
}) => {
  const [noMiddleName, setNoMiddleName] = useState(false);

  const [mobileNumber, setMobileNumber] = useState('');
  const [vCode, setVCode] = useState('');
  const [countDown, setCountDown] = useState<number>(0);
  const [sendError, setSendError] = useState<boolean>(false);
  // const interval = useRef<number>();

  useEffect(() => {
    setMobileNumber(mobile || '');
  }, [mobile]);

  const curPageText = useMemo(() => (
    MOBILE_CODE(mobileNumber)?.[pageId]
  ), [mobileNumber, pageId]);

  const onChangeMiddleName = (state: boolean) => {
    setNoMiddleName(state);
    if (state) {
      onChangeInfoDetails('middleName', '');
    }
  };

  const nextDisabled = useMemo(() => {
    const checkParams = cloneDeep(infoDetails);
    if (noMiddleName) {
      delete checkParams.middleName;
    }
    return isEmptyField(checkParams);
  }, [infoDetails, noMiddleName]);

  // 测试数据 241876258972365setCountDown
  const facebookId = useMemo(() => (getQueryVariable('FacebookId') || '241876258972365'), []);

  const sendSMSCode = () => {
    setVCode('');
    openLoading();
    getVerificationCode({
      facebookId,
      pageId: 'vCode',
      mobileNumber,
    }).then(res => {
      console.log(res);
      closeLoading();
      if (res) {
        const seconds = 59;
        // const currentTime = moment();
        // const exceedTime = moment(currentTime).add(+seconds + 1, 's');
        setCountDown(seconds);
        // interval.current = setInterval(() => {
        //   const timestamp = +exceedTime - +moment();
        //   const diff = moment(timestamp).get('s');
        //   if (diff <= seconds && timestamp > 0) {
        //     setCountDown(diff);
        //   } else {
        //     setCountDown(0);
        //     clearInterval(interval.current);
        //   }moment
        // }, 1000);
        setPageId('vCode');
      }
    }).catch(err => {
      console.log(err);
      closeLoading();
      Toast.fail('There\'s something wrong with the server.');
    });
  };

  const close = () => {
    closeMessengerBrowser();
  };

  const onNext = () => {
    switch (pageId) {
      case 'info':
        onInfoNext();
        break;
      case 'confirm':
        sendSMSCode();
        break;
      default:
        openLoading();
        verificationCode({
          facebookId,
          pageId: 'vCode',
          verification: vCode,
        }).then(res => {
          console.log(res);
          closeLoading();
          const vCodeFailed = res.toString() !== 'true';
          setSendError(vCodeFailed);
          if (!vCodeFailed) {
            close();
          }
        }).catch(err => {
          console.log(err);
          closeLoading();
          Toast.fail('There\'s something wrong with the server.');
        });
        break;
    }
  };

  const onChange = (val: any) => {
    if (pageId === 'confirm') {
      console.log(val);
      setMobileNumber(val);
    } else {
      setVCode(val);
    }
  };

  const onBack = () => {
    openLoading();
    const onVCode = pageId === 'vCode';
    backPage({
      facebookId,
      pageId: onVCode ? 'confirm' : 'info',
    }).then(res => {
      closeLoading();
      console.log(res);
      if (onVCode) {
        setPageId('confirm');
        setVCode('');
      } else {
        setPageId('info');
      }
    }).catch(err => {
      console.log(err);
      closeLoading();
      Toast.fail('There\'s something wrong with the server.');
    });
  };

  const sendDisabled: Record<string, any> = useMemo(() => {
    const commonError = !mobileNumber || mobileNumber.length !== 10;
    return {
      confirm: commonError || countDown,
      vCode: vCode.length !== 6,
    };
  }, [mobileNumber, countDown, vCode]);

  return (
    <>
      <PageTipTitle>{curPageText?.title}</PageTipTitle>
      <TitleDescription>{curPageText?.desc}</TitleDescription>
      <DetailsContent>
        {
          pageId === 'info' ? INFO_DETAILS_MAP_FIELDS.slice(0, -1).map(item => (
            <InputField
              {...item}
              key={item.fieldName}
              details={infoDetails}
              onChangeDetails={onChangeInfoDetails}
              noMiddleName={noMiddleName}
              onChangeMiddleName={onChangeMiddleName}
              filedNameMap={FIELD_NAME_MAP}
              disabled={noMiddleName && infoDetails.fieldName === 'middleName'}
            />
          )) : (
            <VerifyMobileNumber
              mobileNumber={mobileNumber}
              vCode={vCode}
              pageId={pageId}
              onChange={onChange}
              countDown={countDown}
              sendSMSCode={sendSMSCode}
              sendError={sendError}
            />
          )
        }
      </DetailsContent>
      {
        pageId === 'info' ? (
          <Button
            onClick={onNext}
            types={nextDisabled ? 'disabled' : 'primary'}
          >
            NEXT
          </Button>
        ) : (
          <BottomButtonWrapper>
            <Row>
              <Button
                width={80}
                types="ordinary"
                onClick={onBack}
              >
                BACK
              </Button>
              <Button
                width={232}
                margin="margin-left: 22px;"
                types={sendDisabled?.[pageId] ? 'disabled' : 'primary'}
                onClick={onNext}
              >
                {pageId === 'confirm' && countDown ? `${countDown}s` : curPageText?.buttonText}
              </Button>
            </Row>
          </BottomButtonWrapper>
        )
      }
    </>
  );
};

export default Info;
