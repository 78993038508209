import TopPng from '../../../assets/images/termsAndConditions/top.png';
import MiddlePng from '../../../assets/images/termsAndConditions/middle.png';
import BottomPng from '../../../assets/images/termsAndConditions/bottom.png';

const TERMS_AND_CONDITIONS_CONFIG: {src: string, content: any[]}[] = [
  {
    src: TopPng,
    content: [{
      order: '1)',
      text: 'I request membership in the group insurance for which I am or may become eligible. I agree, if admitted, to the deduction of the appropriate contribution from my nominated account, if applicble, and to produce evidence of age if required'
    }, {
      order: '2)',
      text: 'I hereby declare and agree that all statements and answers contained herein are full, complete, and true and that this form shall be part of my enrollment to Group Insurance.'
    }, {
      order: '3)',
      text: 'DATA PRIVACY NOTICE. I also understand and acknowledge that the Company values my privacy and abides by the Principles of Transparency, Legitimate Purpose and Proportionality enshrined in the Philippine Data Privacy Act of 2012.',
      desc: '\n\nAccordingly, the Company processes, using any medium, any information pertaining to this application or insurance policy, and all submitted documents, to provide its insurance and investment products and services. The information and documents are also disclosed to the Company’s affiliations (including but not limited to any of its subsidiaries/affiliates in the Asia Pacific Region), its Brokers, Agents, and their employees and staff and to accredited/affiliated third parties or independ- ent/non-affiliated third parties, whether local or foreign. The Company will upload my medical information to a Medical Information Database accessible to life insurance companies for the purpose of enhancing risk assessment and preventing fraud. Once uploaded, all life insurance companies will only have limited access to the said medical information in order to protect my right to privacy in accordance with law. A copy of Circular Letter No. 2016-54 may be accessed at the Insurance Commission’s website at www.insurance.gov.ph.\n\nMy information and documents are retained by the Company (a) from execution until seven (7) years after termination of your policy, for hard documents in paper form, and (b) from execution until ten (10) years after termination of my policy, for documents in electronic form; but in no case shorter than may be required by appropriate orders and regulations. My information will be deleted/destroyed after this period.\n\nThe Company will use such information in the insurance policy and all related documents to conduct automated processing, data analytics, profiling, historical research (a) to improve the Company’s internal systems and processes, (b) for actuarial assumptions, (c) in internal and external company reports, and (d) to develop and implement business strategies.'
    }, {
      order: '4)',
      text: 'DATA PRIVACY CONSENT. By clicking submit, I agree to the following:\n\n',
      textList: [{
        order: 'i.',
        text: 'That my data will be, shared with BPI AIA’s accredited third parties for marketing campaigns, profiled, and used in the design of campaigns and offers for better servicing\n\n',
      }, {
        order: 'ii.',
        text: 'That my data will be kept for 3 years or for however long is required should I become a customer\n\n',
      }, {
        order: 'iii.',
        text: 'That my data may be used to contact me about the products and services of BPI AIA\n\n',
      }, {
        order: 'iv.',
        text: 'That I have read and understood BPI AIA’s privacy statement stated in https://www.bpi-aia.com.ph/en/index/privacy-state ment.html\n\n',
      }, {
        order: 'v.',
        text: 'That I may withdraw my consent regarding activities using my data anytime by visiting BPI AIA, calling the hotline, or emailing dpo@aia.com.',
      }]
    }, {
      order: '5)',
      text: 'I hereby acknowledge and warrant that I have acquired the consent of all parties pertinent to this transaction to disclose their information for the proper administration and provision of services requested from this transaction.'
    }, {
      order: '6)',
      text: 'I hereby hold free and harmless and undertake to indemnify the Company for any complaint, suit, damages, and the like which any party may file or claim against the Company in relation to this declaration and agreement.'
    }, {
      order: '7)',
      text: 'I understand that the benefits under this plan will be paid directly to me. In the event of death, the benefit will be paid to my first surviving class of the following beneficiaries:\n' +
        '(1) My spouse, (2) surviving children, (3) surviving parents, (4) brothers/sisters, (5) estate. Should I wish to designate a beneficiary, I may contact BPI AIA.'
    }, {
      order: '8)',
      text: 'I acknowledge that I will receive the Proof of Cover and other correspondence in electronic format via, including but not limited to, email and/or SMS. I will ensure that my contact information is valid and updated.'
    }, {
      order: '9)',
      text: 'I further agree that the insurance coverage under this application is based on the truth of the foregoing declarations and representations and is subject to the provisions of the Group Insurance issued by BPI AIA LIFE ASSURANCE CORPORATION to the Assured.'
    }],
  }, {
    src: MiddlePng,
    content: [{
      title: 'Life Insurance Benefit',
      text: 'If a Member dies by suicide within one (1) year from the effective date or latest reinstatement date, whichever is later, of such Member’s individual insurance coverage, the Company’s liability shall be limited to a return of premiums paid for such Member’s coverage. However, suicide committed in a state of insanity shall be compensable regardless of the date of commission.\n'
    }, {
      title: 'Accidental Death, Dismemberment and Disability Benefit Exclusions',
      text: 'No payment under this benefit shall be made for any loss caused directly or indirectly, wholly or partly:\n\n\n',
      textList: [{
        order: '(a)',
        text: 'by intentionally self-inflicted injury, suicide or any attempt thereat, while sane or insane;'
      }, {
        order: '(b)',
        text: 'by war, invasion, act of foreign enemy, acts of terrorism, hostilities or warlike operations (whether war be declared or not), mutiny, riot, civil commotion, strike, civil war, rebellion, revolution, insurrections, conspiracy, military or usurped power, martial law or state of siege, or any of the events or causes which determine the proclamation or maintenance of martial law or state of siege, seizure, quarantine, or under the order of any government or public or local authority;'
      }, {
        order: '(c)',
        text: 'by any weapon or instrument employing atomic fission, thermonuclear fusion or any form of radiation, whether in time of peace or war;'
      }, {
        order: '(d)',
        text: 'by homicide or assault, or any attempt thereof, or physical injuries, occasioned by the provocation of the Member;'
      }, {
        order: '(e)',
        text: 'by congenital anomalies and conditions arising therefrom;'
      }, {
        order: '(f)',
        text: 'by pregnancy and resulting childbirth, miscarriage or abortion;'
      }, {
        order: '(g)',
        text: 'by cosmetic or plastic surgery, except as a result of Injury;'
      }, {
        order: '(h)',
        text: 'while the Member is in active service in the Armed Forces of any country or any international authority;'
      }, {
        order: '(i)',
        text: 'while in any attempted commission of, or willful participation by the Member in any crime punishable under any prevailing law or ordinance of the Philippines or of any country in which the crime was attempted;'
      }, {
        order: '(j)',
        text: 'while resisting lawful arrest;'
      }, {
        order: '(k)',
        text: 'while entering, leaving, operating, servicing, or being in, on, or about any aerial or submarine device or conveyance, except while as a passenger (not as an operator or crew member), boarding or alighting from a certified passenger aircraft provided by a commercial airline on any regular, scheduled or non-scheduled, special or chartered flight, and operated by a properly certified pilot flying between duly established and maintained airports over an estab lished passenger route;'
      }, {
        order: '(l)',
        text: 'by dental care or surgery except to natural teeth as occasioned by Injury;'
      }, {
        order: '(m)',
        text: 'while under the influence of alcohol or unprescribed drugs;'
      }, {
        order: '(n)',
        text: 'by alcoholism or drug addiction;'
      }, {
        order: '(o)',
        text: 'by disease, bacterial infection or out of or consequent upon or contributed to by Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex (ARC);'
      }, {
        order: '(p)',
        text: 'by hernia, ptomaines, or other bacterial infections which are not pyogenic infections occurring at the same time with or because of any accidental cut or wound;'
      }, {
        order: '(q)',
        text: 'by circumcision, sterilization, artificial insemination, sex transformation, diagnosis and treatment of infertility, congenital deformities and defects;'
      }, {
        order: '(r)',
        text: 'by mental or nervous disorders;'
      }, {
        order: '(s)',
        text: 'by poison, gas or fumes voluntarily or involuntarily taken;'
      }, {
        order: '(t)',
        text: 'by automobile and motorcycle racing, judo, karate and similar martial arts, scuba diving, hang gliding and sky-diving;'
      }, {
        order: '(u)',
        text: 'by routine health checks, any investigation(s) not directly related to admission diagnosis, illness or injury, or any treatment or investigation which is not medically necessary, or convalescence, custodial or rest care.'
      }],
    }, {
      title: 'Hospital Income Benefit Exclusions',
      text: 'No payment shall be made under this benefit for Illness, Injury, Hospitalization or any charges caused directly, wholly or partly:',
    }, {
      textList: [{
        order: '(a)',
        text: 'by intentionally self-inflicted injury, suicide or any attempt thereat, while sane or insane;'
      }, {
        order: '(b)',
        text: 'by war, invasion, act of foreign enemy, acts of terrorism, hostilities or warlike operations (whether war be declared or not), mutiny, riot, civil commotion, strike, civil war, rebellion, revolution, insurrections, conspiracy, military or usurped power, martial law or state of siege, or any of the events or causes which determine the proclamation or maintenance of martial law or state of siege, seizure, quarantine, or nationalization by or under the order of any government or public or local authority;',
      }, {
        order: '(c)',
        text: 'by any weapon or instrument employing atomic fission, thermonuclear fusion or any form of radiation, whether in time of peace or war;',
      }, {
        order: '(d)',
        text: 'by murder, frustrated murder or any attempt thereof;',
      }, {
        order: '(e)',
        text: 'by homicide, frustrated homicide or any attempt thereof, or physical injuries, occasioned by the provocation of the Member;',
      }, {
        order: '(f)',
        text: 'by congenital anomalies and conditions arising therefrom;',
      }, {
        order: '(g)',
        text: 'by pregnancy and resulting childbirth, miscarriage or abortion;',
      }, {
        order: '(h)',
        text: 'by cosmetic or plastic surgery, except as a result of Injury;',
      }, {
        order: '(i)',
        text: 'while the Member is in active service in the Armed Forces of any country or any international authority and in such an event, we, upon written notification by the Assured, shall return the pro-rata premium for any such period of service;',
      }, {
        order: '(j)',
        text: 'while in any attempted commission of, or willful participation by the Member in, any crime punishable under any prevailing law or ordinance of the Philippines or of any country in which the crime was attempted;',
      }, {
        order: '(k)',
        text: 'while resisting lawful arrest;',
      }, {
        order: '(l)',
        text: 'while entering, leaving, operating, servicing, or being in, on, or about any aerial or submarine device or conveyance except as specifically provided herein;',
      }, {
        order: '(m)',
        text: 'by dental care or surgery except to natural teeth as occasioned by Injury;',
      }, {
        order: '(n)',
        text: 'routine health checks, any investigation(s) not directly related to admission diagnosis, illness or injury, or any treatment or investigation which is not medically necessary, or convalescence, custodial or rest care;',
      }, {
        order: '(o)',
        text: 'while under the influence of alcohol or unprescribed drugs;',
      }, {
        order: '(p)',
        text: 'by alcoholism or drug addiction;',
      }, {
        order: '(q)',
        text: 'by disease, bacterial infection or out of or consequent upon or contributed to by Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex (ARC);',
      }, {
        order: '(r)',
        text: 'by hernia, ptomaines, or other bacterial infections which are not pyogenic infections occurring at the same time with or because of any accidental cut or wound;',
      }, {
        order: '(s)',
        text: 'by circumcision, sterilization, artificial insemination, sex transformation, diagnosis and treatment of infertility, congenital deformities and defects;',
      }, {
        order: '(t)',
        text: 'by psychosis, mental or nervous disorders, sleep disturbance disorders;',
      }, {
        order: '(u)',
        text: 'by automobile and motorcycle racing, judo, karate and similar martial arts, scuba diving, hang-gliding and sky-diving;',
      }, {
        order: '(v)',
        text: 'by poison, gas or fumes voluntarily or involuntarily taken.',
      }],
    }],
  },
  {
    src: BottomPng,
    content: [{
      order: 'I.',
      text: 'I/We agree to be bound by obligations set out in relevant United Nations Security Council Resolutions relating to the prevention and suppression of the financing of weapons of mass destruction, including the freezing and unfreezing actions and prohibitions from conducting transactions with designated persons and entities.',
    }, {
      order: 'II.',
      text: 'I/We understand that the Company may prohibit transactions, terminate or consider void this Policy, at any time, effective from its issuance or otherwise, in the event I/we or any person or entity connected with this Policy, in the opinion of the Company, may have or have exposed the Company to the risk of being or becoming subject to any sanction, prohibition or restriction under the trade of economic sanctions, laws or regulations of the European Union, United Kingdom, United States of America, or any other applicable economic or trade sanction laws or regulations. The same shall apply in the event of our refusal to comply with the Customer Due Diligence (CDD) requirements which the Company is mandated to perform under the Anti-Money Laundering Act and relevant regulations.\n',
    }, {
      order: 'III.',
      text: 'I/We acknowledge and agree that if found to be repeatedly abusing certain facilities (e.g., policy loans, assignments etc.) in a manner that gives rise to suspicion of Money Laundering and Terrorist Financial (ML/TF) the Company reserves the right and shall be entitled to cancel or terminate this Policy/Policies.',
    }, {
      order: 'IV.',
      text: 'The Company reserves the right to terminate and/or change the foregoing. I/we understand that the Company will provide me/us with notice of any such termination or change.',
    }],
  },
];

export default TERMS_AND_CONDITIONS_CONFIG;
