import React from 'react';
import { Button as AntdButton } from 'antd';
import styled from 'styled-px2vw';
import { ButtonProps } from 'antd/lib/button';

const getPx = (px: number) => `${2 * px}px`;

// 分别对应组件的第三列，第四列，第二列，第一列
export type TButtonType = 'primary' | 'secondary' | 'ordinary' | 'disabled';

// 分别对应的高度 44 40 32
export type TButtonSize = 'large' | 'middle' | 'small';

export interface IButton extends ButtonProps {
  width?: number;
  height?: number;
  bgcolor?: string;
  ref?: any;
  color?: string;
  margin?: string;
  types?: TButtonType; // button的样式
  size?: TButtonSize; // button的大小
  themeColor?: string;
  id?: string;
  active?: boolean;
}

const WHITE = '#FFF';
const PRIMARY_COLOR = '#0084FF';
const DC_COLOR = '#DCDCDC';
const FONT_BOLD = 'font-weight: bold;';

const typesStyle = (types?: TButtonType, themeColor?: string) => {
  const primaryColor = themeColor || PRIMARY_COLOR;
  switch (types) {
    case 'ordinary':
      return `
          background: ${WHITE};
          border: 2px solid ${DC_COLOR};
          box-sizing: border-box;
          color: #202020;
          &.ant-btn:hover, &.ant-btn:focus {
            border-color: ${primaryColor};
            border-radius: 8px;
            color: ${primaryColor};
          }
        `;
    case 'primary':
      return `
        background: #0084FF;
        border-color: #0084FF;
        color: ${WHITE};
        ${FONT_BOLD};
        &.ant-btn:hover, &.ant-btn:focus {
          background: #0084FF;
          border-color: #0084FF;
          border-radius: 8px;
          color: ${WHITE};
        }
      `;
    case 'secondary':
      return `
        background: ${WHITE};
        border: 2px solid ${primaryColor};
        font-size: 28px;
        line-height: 36px;
        ${FONT_BOLD}
        color: ${primaryColor};
      `;
    default:
      return `
        background: #F1F0F0 !important;
        border: 2px solid #F1F0F0 !important;
        &.ant-btn[disabled] {
          color: #666666;
        }
      `;
  }
};

const StyledButton = styled(AntdButton)<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => getPx(props.width || 323)};
  height: ${props => getPx(props.height || 48)};
  border-radius: 8px;
  ${props => props.color && `color: ${props.color};`};
  ${props => props.margin && `${props.margin};`};
  ${props => typesStyle(props.types, props.themeColor)};
  ${
    props => props.active ?
      `border: 2px solid ${props.themeColor || PRIMARY_COLOR} !important;color: ${props.themeColor || PRIMARY_COLOR}!important;${FONT_BOLD}`
      : ''
  }
  font-size: 32px;
`;

const Button: React.FC<IButton> = ({
  width,
  height,
  types = 'primary',
  size = 'middle',
  margin,
  children,
  ...props
}) => (
  <StyledButton
    width={width}
    height={height}
    types={props.disabled ? 'disabled' : types}
    size={size}
    margin={margin}
    {...props}
    disabled={types === 'disabled' || props.disabled}
  >
    {children}
  </StyledButton>

);

export default Button;
