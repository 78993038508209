import axios, { AxiosRequestConfig } from 'axios';
import QS from 'querystring';
// import { message } from 'antd';

let requestRecord: any[] = [];

let refreshFlag = false;

const successNetworkStatus = [200, 201, 204, 205, 206];

// const failedNetWorkStatus = new Map([
//   [401, '未授权'],
//   [403, '无权限访问'],
//   [404, '接口找不到'],
//   [500, '服务器内部错误'],
//   [502, '网关错误'],
//   [503, '服务不可用，请稍后重试'],
//   [504, '网关超时'],
//   [505, 'HTTP版本不受支持'],
// ]);

const API_URL = process.env.REACT_APP_APIURL;

const instance = axios.create({    //创建axios实例，在这里可以设置请求的默认配置
  timeout: 300000, // 设置超时时间30s
  baseURL: API_URL,
  headers: { // 定义统一的请求头部
    post: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  },
});

instance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

// 添加请求拦截器
// instance.interceptors.request.use(function (config) {
//   requestRecord.push(config);
//   // 在发送请求之前做些什么
//   if (config.url !== 'https://users.dev.coherent.com.hk/users/auth/login/') config.headers['AUTHORIZATION'] = 'bearer ' + (localStorage.getItem('idToken') || '');
//   return config;
// }, function (error) {
//   // 对请求错误做些什么
//   return Promise.reject(error);
// });

const refreshToken = (config: AxiosRequestConfig) => {
  getToken().then(res => {
    if (!res.data || !res.data.id_token) {
      window.location.href = window.location.origin + '/login';
      return;
    }
    localStorage.setItem('idToken', res.data.id_token);
    config.headers.AUTHORIZATION = res.data.id_token;
    refreshFlag = false;
    requestRecord.forEach(requestConfig => {
      requestConfig.headers.AUTHORIZATION = res.data.id_token;
      instance(requestConfig);
    });
    requestRecord = [];
    return instance(config);
  }).catch(err => {
    // console.log('get token error', err);
    // message.info('登录过期');
    window.location.href = window.location.origin + '/login';
  });
};

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  if (successNetworkStatus.includes(response.status)) {
    if (response.data.error_code === 'UNAUTHORIZED') {
      if (!refreshFlag) {
        const config = response.config;
        refreshToken(config);
      }
    }
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
}, function async(error) {
  if (error.response && error.response.status) {
    // message.error(failedNetWorkStatus.get(error.response.status));
  }
  if (error.response) {
    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
    if (error.response.data.detail === 'Expired Token') {
      refreshToken(requestRecord[0]);

    }
  }
  // 对响应错误做点什么
  return Promise.reject(error);
});

interface IRequest {
  url: string;
  data?: any;
  config?: {
    [propsName: string]: any
  },
}

const getToken = () => {
  refreshFlag = true;
  return axios.post('https://users.dev.coherent.com.hk/users/refresh/', {
    refresh_token: localStorage.getItem('refreshToken') || ''
  });
};

export const fetchGet = ({ url, data, config }: IRequest): Promise<any> => {
  url = data ? `${url}?${QS.stringify(data)}` : url;
  return instance.get(url);
};

export const fetchPost = ({ url, config, data }: IRequest): Promise<any> => instance.post(url, data, config);

export const fetchPut = ({ url, config, data }: IRequest): Promise<any> => instance.put(url, data, config);

export const fetchDelete = ({ url, config }: IRequest): Promise<any> => instance.delete(url, config);
