import React, { useReducer } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-px2vw';

import { initialState, reducer, Context } from './store';
import { theme } from './constants';
import './index.css';
import Routes from './Routes';
import CustomerLoading from './layout/Loading';

const App = () => {
  const [store, dispatch] = useReducer(reducer, initialState);

  return (
    <ThemeProvider theme={{ ...theme }}>
      <Context.Provider value={{ store, dispatch }}>
        {
          store && store.loading && (
            <div className="spin-customer-loading">
              <CustomerLoading />
            </div>
          )
        }
        <div className="app">
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </div>
      </Context.Provider>
    </ThemeProvider>
  );
};

export default App;
