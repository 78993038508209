import styled from 'styled-px2vw';
import { Row as AntdRow } from 'antd';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  .select-text {
    font-size: 32px;
    line-height: 40px;
    color: #000;
    margin: 52px 0 0 55px;
  }
`;
export const CreditContent = styled.div`
  height: 74px; 
  width: 648px;
  margin: 65px 0 0 51px;
  border: 1px solid #0084FF;
  box-sizing: border-box;
  border-radius: 48px;
    .button-text {
    width: 438px;
    font-weight: bold;
    font-size: 32px;
    line-height: 72px;
    color: #0084FF;
    margin: 0 0 0 45px;
  }
`;

export const Row = styled(AntdRow)`
`;
