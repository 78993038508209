import { fetchGet, fetchPost, fetchPut } from '../utils/axios';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// ------FB Retail-------
const COMMON_URI = '/api/bpi-retail/info-details/';
// const COMMON_URI = 'http://192.168.10.8:5010/api/bpi-retail/info-details/';

export const recordInfoDetailsButton = (data: { userContext: string; type: number }) => fetchPost({
  url: `${COMMON_URI}record-info-details-button`,
  config,
  data,
});

export const getInfoDetails = (userContext: string) =>
  fetchGet({
    url: `${COMMON_URI}info-details?userContext=${userContext}`,
    config,
  }).then((res) => {
    console.log({ getInfoDetails: res })
    const mockedRes: any = {
      code: 0,
      data: {
        id: '3a00b08a-494e-19b5-fc5b-80beac772663',
        insurancePlanId: '3a00b08a-494e-19b5-fc5b-80beac772663',
        firstName: 'The',
        middleName: '',
        lastName: 'MyName',
        birthday: '2000-12-01',
        gender: 'Male',
        mobileNumber: '9179603172',
        suffix: 'Jr',
        hasPolicyOrder: false,
        pageId: 'otp',
        bankReferrerCode: '235637622',
        branchCode: '',
        bse: '800004293',
        isReferrer: 'Yes',
        refsInUrl: 'mocked-url',
        countDown: 1000,
      },
      subErrorCode: 1000,
    }
    return mockedRes
  }).catch((error)=>{
    // console.log({ getInfoDetails: error })
    const mockedRes: any = {
      code: 0,
      data: {
        id: '3a00b08a-494e-19b5-fc5b-80beac772663',
        insurancePlanId: '3a00b08a-494e-19b5-fc5b-80beac772663',
        firstName: 'The',
        middleName: '',
        lastName: 'MyName',
        birthday: '2000-12-01',
        gender: 'Male',
        mobileNumber: '9179603172',
        suffix: 'Jr',
        hasPolicyOrder: false,
        pageId: 'otp',
        bankReferrerCode: '235637622',
        branchCode: '',
        bse: '800004293',
        isReferrer: 'Yes',
        refsInUrl: 'mocked-url',
        countDown: 1000,
      },
      subErrorCode: 1000,
    }
    return mockedRes
  })

export const getBranchCodeList = () => fetchPost({
  url: `${COMMON_URI}branch-list`,
  data: {
    pageSize: 99999,
    pageIndex: 1,
  },
  config
});

export const backPage = (data: { facebookId: string; pageId: string; }) => fetchPut({
  url: `${COMMON_URI}back`,
  data,
  config,
});

export const backToInfosPage = (data: { userContext: string; pageId: string; }) => fetchGet({
  url: `${COMMON_URI}back-button`,
  data,
  config,
});

export const createUpdateInfoDetails = (data: Record<string, any>) => fetchPost({
  url: `${COMMON_URI}or-update-info-details`,
  data,
  config,
});

export const getVerificationCode = (data: { facebookId: string; pageId: string; mobileNumber: string }) => fetchGet({
  url: `${COMMON_URI}verification-code`,
  data,
  config,
});

export const getOTPVerificationCode = (data: { userContext: string }) => fetchGet({
  url: `${COMMON_URI}resend-code`,
  data,
  config,
});

export const verificationCode = (data: { facebookId: string; pageId: string; verification: string }) => fetchPost({
  url: `${COMMON_URI}verification`,
  data,
  config,
});

export const verificationOTPCode = (data: { userContext: string; verifyCode: string }) =>
  fetchGet({
    url: `${COMMON_URI}info-details-verify`,
    data,
    config,
  }).then((res) => {
    console.log({ verificationOTPCode: res })
    const mockedRes: any = {
      code: 0,
      data: { countDown: new Date(), firstName: 'My Name' },
      subErrorCode: 1000,
    }
    console.log({ res, mockedRes })

    return mockedRes
  }).catch((error)=>{
    // console.log({ verificationOTPCode })
    const mockedRes: any = {
      code: 0,
      data: { countDown: new Date(), firstName: 'My Name' },
      subErrorCode: 1000,
    }
    console.log({  mockedRes })

    return mockedRes
  })

export const infoDetailsConfirm = (data: Record<string, any>) => fetchPost({
  url: `${COMMON_URI}info-details-confirm`,
  data,
  config,
});

export const infoDetailsYes = (userContext: string) => fetchPost({
  url: `${COMMON_URI}info-details-yes?userContext=${userContext}`,
  config,
});

export const editInfoDetail = (data:any) => fetchPut({
  url: `${COMMON_URI}summary-page-info`,
  data,
  config
})