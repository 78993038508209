import React, { FC } from 'react';
import { Toast } from 'antd-mobile';

import Button from '../../components/Button';
import {
  BottomButtonWrapper,
  PageTipTitle,
  TitleDescription,
} from '../common/style';
import { ERROR_CODE_MSG } from './config';
import { closeMessengerBrowser } from '../../utils';

interface IFailedProps {
  errorCode: string | number;
}

const Failed:FC<IFailedProps> = ({
  errorCode,
}) => {
  const close = () => {
    closeMessengerBrowser();
  };

  return (
    <>
      <PageTipTitle>
        Failed to continue.
      </PageTipTitle>
      <TitleDescription>
        {errorCode || ERROR_CODE_MSG[1]}
      </TitleDescription>
      <BottomButtonWrapper>
        <Button onClick={close}>
          OK
        </Button>
      </BottomButtonWrapper>
    </>
  );
};

export default Failed;
