import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Toast } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { Content, Row, CloseButton } from './style';
import { Context } from '../../store';
import SuccessLogo from './pay-success.svg';
import FailedLogo from './pay-failed.svg';
import { closeLoading, openLoading, getQueryVariable, PAY_SUCCESS_CODE, BALANCE_INSUFFICIENT, closeMessengerBrowser } from '../../utils/index';
import { getOrderDetail } from '../../api';

const PayFailedError = 'Payment Failed Please try again.';
const PayFailedInsufficientError = 'Gcash Transaction Failed. Insufficient Balance ';

const PaySuccess = () => {
  document.title = 'PAYMENT';
  const history = useHistory();
  const pollingTime = 3500;
  const { dispatch } = useContext(Context);
  const loadingStr = 'loading';
  const [payFailed, setPayFailed] = useState<boolean>();
  const [payFailedCopywriting, setPayFailedCopywriting] = useState<string>();
  const [transactionId, setTransactionId] = useState<string>(loadingStr);
  const [policyNumber, setPolicyNumber] = useState<string>(loadingStr);
  const retry = () => {
    history.push('Payment');
  };

  const close = () => {
    closeMessengerBrowser();
  };

  const toastErr = useCallback(() => {
    closeLoading();
    Toast.fail('There\'s something wrong with the server.');
  }, []);

  const pollingOrder = useCallback(async (id: string) => {
    try {
      const polling = setTimeout(async () => {
        const orderDetailRes = await getOrderDetail(id).catch(toastErr);
        if (orderDetailRes.code === 0) {
          const { response_code, response_id } = orderDetailRes.data.payInfo;
          const payTransactionNumber = orderDetailRes?.data?.payTransactionNumber || response_id;
          if (response_code && PAY_SUCCESS_CODE.includes(response_code) && payTransactionNumber) { // 成功
            setTransactionId(payTransactionNumber);
            closeLoading();
            clearTimeout(polling);
          } else if (response_code && response_code !== 'GR033') { // 失败
            closeLoading();
            setPayFailed(true);
            setTransactionId(payTransactionNumber);
            clearTimeout(polling);
            setPayFailedCopywriting(response_code === BALANCE_INSUFFICIENT ? PayFailedInsufficientError : PayFailedError);
          } else { // 轮询
            await pollingOrder(id);
          }
          setPolicyNumber(orderDetailRes?.data?.policyNumber || '');
        }
      }, pollingTime);
    } catch (error) {
      console.log(error);
    }
  }, [toastErr]);

  const getDetailData = useCallback(async () => {
    const id = getQueryVariable('id') || '';
    if (id) {
      dispatch({
        type: 'setOrderId', orderId: getQueryVariable('id'),
      });
      openLoading();
      await pollingOrder(id);
      history.replace({ search: '' });
    }
  }, [pollingOrder, dispatch, history]);

  useEffect(() => {
    getDetailData();
  }, [getDetailData]);

  const RowNumber = payFailed ? (
    <Content>
      <Row>
        <div className="transaction">Transaction Number:&nbsp;</div>
        <div className="transaction-number">{transactionId}</div>
      </Row>
      <Row />
      <div className="pay-failed">{payFailedCopywriting}</div>
    </Content>
  ) : (
    <Content>
      <Row>
        <div className="transaction">Transaction Number:&nbsp;</div>
        <div className="transaction-number">{transactionId}</div>
      </Row>
      <Row>
        <div className="transaction">Policy Number:&nbsp;</div>
        <div className="transaction-number">{policyNumber}</div>
      </Row>
    </Content>
  );
  return (
    <div>
      {
        (payFailed || transactionId !== loadingStr) && (
          <Content>
            <img src={payFailed ? FailedLogo : SuccessLogo} alt="" style={{ marginTop: 97 }} />
            <div className="pay-success">{payFailed ? 'PAYMENT FAILED' : 'PAYMENT SUCCESSFUL'}</div>
            {RowNumber}
            <CloseButton onClick={payFailed ? retry : close}>{payFailed ? 'RETRY' : 'CLOSE'}</CloseButton>
          </Content>
        )
      }
    </div>
  );
};

export default PaySuccess;
