import React, { useState, useEffect, useImperativeHandle, forwardRef, useContext, useCallback } from 'react';
import { Toast } from 'antd-mobile';
import moment from 'moment-timezone';

import { DependentData, Context } from '../../store';
import { EditDependentItemContent, Row, EditButton } from './style';
import { getDependentCost } from '../../api';
import {
  // MAX_DEPENDENT_LENGTH,
  closeLoading,
  openLoading,
  CHILD_AGE,
  PARENT_AGE,
  CIVIL_RELATIONS,
  MIN_AGE_DAY,
  MAX_AGE,
  DATE_OF_BIRTH_EMPTY,
  ADULT_ERROR,
  YOUNG_ERROR,
  INPUT_NAME_ERROR,
  CHILD_RELATIONSHIP_CODE,
  PARENT_RELATIONSHIP_CODE,
  SPOUSE_RELATIONSHIP_CODE,
  SIBLING_RELATIONSHIP_CODE,
  RELATIONSHIP_ERROR,
  // NAME_REG,
  RELATIONSHIP_EMPTY,
  GENDER_EMPTY,
  isEmptyField,
  // SUFFIX_EMPTY,
} from '../../utils/index';
// import { Validator } from './Validator';
import {
  DEPENDENTS_CONFIG,
  DEPENDENTS_FIELD_NAME_MAP,
  getDValue,
  // PLACE_OF_BIRTH_REG,
} from './config';
import InputField from '../infoDetails/InputField';
import { formatDate } from '../infoDetails/config';

export interface IRef {
  addDependentClick: () => any
}
interface IEditDependentItem {
  editData?: DependentData;
  civil: string;
  addDependent: (addData: DependentData, edit?: DependentData) => void;
  cancel: (edit?: DependentData) => void;
  childRef?: React.RefObject<IRef>
}


/*
  获取生日与今天比较后的年、月、日
*/
export const getAge = (birthday: Date) => {
  const birthdayFormat: any = [birthday.getFullYear(), birthday.getMonth(), birthday.getDate()];
  const date = new Date();
  const today = [date.getFullYear(), date.getMonth(), date.getDate()];
  const age = today.map((value, index) => value - birthdayFormat[index]);
  if (age[2] < 0) {
    const lastMonth = new Date(today[0], today[1], 0);
    age[1]--;
    age[2] += lastMonth.getDate();
  }
  if (age[1] < 0) {
    age[0]--;
    age[1] += 12;
  }
  return age;
}

const EditDependentItem = ({ addDependent, cancel, childRef, editData, civil }: {
  editData?: DependentData;
  civil: string;
  addDependent: (addData: DependentData, edit?: DependentData, editType?: 'isEdit' | 'isRemove') => void;
  cancel: (edit?: DependentData) => void;
  childRef?: React.RefObject<IRef>
}) => {
  const maxParent = 2; const maxSpouse = 1;
  const [dateBirthday, setDateBirthday] = useState<Date>(editData?.date ? new Date(editData?.date) : new Date());
  const [editCost, setEditCost] = useState(editData?.amount.toString() || '--');
  const [loadingCost, setLoadingCost] = useState(!!editData);
  const [relationStrs] = useState(CIVIL_RELATIONS[civil]);

  const [dependentDetails, setDependentDetails] = useState({
    firstName: editData?.firstName || '',
    middleName: editData?.middleName || '',
    lastName: editData?.lastName || '',
    relationShip: editData?.relationShip || 0,
    dob: editData?.dob || '',
    suffix: editData?.suffix || '',
    // placeOfBirth: editData?.placeOfBirth || '',
    gender: editData?.gender || '',
  });

  const [detailsErrorState, setDetailsErrorState] = useState<Record<string, { state: boolean, msg: string }>>({
    relationShip: { state: false, msg: '' },
    dob: { state: false, msg: '' },
    gender: { state: false, msg: '' },
    firstName: { state: false, msg: '' },
    middleName: { state: false, msg: '' },
    lastName: { state: false, msg: '' },
    suffix: { state: false, msg: '' },
    // placeOfBirth: { state: false, msg: '' },
  });

  const { store: { dependentDatas, orderId } } = useContext(Context);

  // const isNotEmpty = (value: any) => !!value;
  // const validateLength = useCallback((curEditData: any) => (dependentDatas.length < MAX_DEPENDENT_LENGTH || curEditData), [dependentDatas]);
  // const isNotEmptySelect = (value: any) => !!value;
  const relationValidate = useCallback((relationShip: any) => {
    const spouseCount = dependentDatas.filter(item => item.relationShip === SPOUSE_RELATIONSHIP_CODE).length;
    const parentCount = dependentDatas.filter(item => item.relationShip === PARENT_RELATIONSHIP_CODE).length;
    const relationMaxmum = (spouseCount === maxSpouse && relationShip === SPOUSE_RELATIONSHIP_CODE) || (parentCount === maxParent && relationShip === PARENT_RELATIONSHIP_CODE);
    const validateResult = editData ? relationMaxmum && editData?.relationShip !== relationShip : relationMaxmum;
    return !validateResult;
  }, [dependentDatas, editData]);
  // const validateName = (value: string) => /^[A-Za-z ]+$/.test(value) && value.length > 0 && value.length <= 50;
  const valiYoung = useCallback((birthday: any) => {
    // const age = getAge(birthday);
    // const ageCompliance = (age[0] > 0 && age[0] < PARENT_AGE) || (age[0] === PARENT_AGE && age[1] === 0 && age[2] === 0) || (age[0] === 0 && age[1] > 0) || (age[0] === 0 && age[1] === 0 && age[2] >= MIN_AGE_DAY);
    const isYoungRelation = [CHILD_RELATIONSHIP_CODE, SIBLING_RELATIONSHIP_CODE].includes(dependentDetails.relationShip);
    const minDayDValue = moment().diff(formatDate(birthday), 'd');
    const maxParentAgeDValue = getDValue(birthday, PARENT_AGE, 'max');
    const ageCompliance = minDayDValue >= MIN_AGE_DAY && maxParentAgeDValue >= 0;
    return isYoungRelation ? ageCompliance : !isYoungRelation;
  }, [dependentDetails.relationShip]);
  const valiAdult = useCallback((birthday: any) => {
    // const age = getAge(birthday);
    // const ageCompliance = (age[0] >= CHILD_AGE && age[0] < MAX_AGE) || (age[0] === MAX_AGE && age[1] === 0 && age[2] === 0);
    const isAdultRelation = [SPOUSE_RELATIONSHIP_CODE, PARENT_RELATIONSHIP_CODE].includes(dependentDetails.relationShip);
    const minChildAgeDValue = getDValue(birthday, CHILD_AGE, 'min');
    const maxAgeDValue = getDValue(birthday, MAX_AGE, 'max');
    const ageCompliance = minChildAgeDValue <= 0 && maxAgeDValue >= 0;
    return isAdultRelation ? ageCompliance : !isAdultRelation;
  }, [dependentDetails.relationShip]);

  const handleErrorState = (dataKey: string, value: { state: boolean, msg: string }) => {
    setDetailsErrorState(prevState => ({
      ...prevState,
      [dataKey]: value,
    }));
  };

  const getCost = useCallback(async () => {
    setLoadingCost(false);
    if (!valiYoung(dateBirthday)) {
      handleErrorState('dob', {
        state: true,
        msg: YOUNG_ERROR,
      });
      return;
    }
    if (!valiAdult(dateBirthday)) {
      handleErrorState('dob', {
        state: true,
        msg: ADULT_ERROR,
      });
      return;
    }
    if (!dependentDetails.relationShip) {
      handleErrorState('relationShip', {
        state: true,
        msg: RELATIONSHIP_EMPTY,
      });
      return;
    }
    openLoading();
    await getDependentCost({
      PolicyOrderId: orderId || '',
      DependentBirthday: dependentDetails.dob,
      Relationship: dependentDetails.relationShip,
    }).then(res => {
      closeLoading();
      if (res?.code === 0) {
        setEditCost(res?.data.toString());
        setLoadingCost(true);
      }
    }).catch(() => {
      closeLoading();
    });
  }, [dateBirthday, valiYoung, orderId, valiAdult, dependentDetails.relationShip, dependentDetails.dob]);

  const addDependentClick = () => {
    const dobIsEmpty = !dependentDetails.dob;
    const emptyFirstName = !dependentDetails.firstName;
    const emptyLastName = !dependentDetails.lastName;
    const emptyGender = !dependentDetails.gender;
    const emptyRelationShip = !dependentDetails.relationShip;
    // const emptyPlaceOfBirth = !dependentDetails.placeOfBirth;
    // const emptySuffix = !dependentDetails.suffix;
    const currentErrorState = {
      dob: { state: detailsErrorState?.dob?.state || dobIsEmpty, msg: detailsErrorState?.dob?.msg || DATE_OF_BIRTH_EMPTY },
      firstName: { state: detailsErrorState?.firstName?.state || emptyFirstName, msg: detailsErrorState?.firstName?.msg || INPUT_NAME_ERROR },
      lastName: { state: detailsErrorState?.lastName?.state || emptyLastName, msg: detailsErrorState?.lastName?.msg || INPUT_NAME_ERROR },
      gender: { state: detailsErrorState?.gender?.state || emptyGender, msg: detailsErrorState?.gender?.msg || GENDER_EMPTY },
      relationShip: { state: detailsErrorState?.relationShip?.state || emptyRelationShip, msg: detailsErrorState?.relationShip?.msg || RELATIONSHIP_EMPTY },
      // suffix: { state: detailsErrorState?.suffix?.state || emptySuffix, msg: detailsErrorState?.suffix?.msg || SUFFIX_EMPTY },
    }
    if (dobIsEmpty || emptyFirstName || emptyLastName || emptyGender || emptyRelationShip) {
      setDetailsErrorState(prevState => ({
        ...prevState,
        ...currentErrorState,
      }));
      return;
    }
    if (isEmptyField(currentErrorState, 'boolean', false)){
      return;
    }
    if (loadingCost && editCost !== '--') {
      addDependent({
        firstName: dependentDetails.firstName,
        middleName: dependentDetails.middleName,
        lastName: dependentDetails.lastName,
        name: `${dependentDetails.firstName} ${dependentDetails.middleName} ${dependentDetails.lastName}`,
        dob: dependentDetails.dob,
        relationShip: dependentDetails.relationShip,
        // placeOfBirth: dependentDetails.placeOfBirth,
        gender: dependentDetails.gender,
        suffix: dependentDetails.suffix,
        amount: editCost,
        date: dateBirthday,
        edit: false,
      }, editData);
    } else {
      Toast.info('Waiting to calculate the price', Toast.SHORT);
    }
  };

  useImperativeHandle(childRef, () => ({
    addDependentClick,
  }));

  useEffect(() => {
    if (dependentDetails.dob) {
      const dobIsEmpty = !dependentDetails.dob;
      const dobYoung = !valiYoung(dateBirthday);
      const dobAdult = !valiAdult(dateBirthday);
      const dobIsEmptyMsg = dobIsEmpty ? DATE_OF_BIRTH_EMPTY : '';
      const dobYoungMsg = dobYoung ? YOUNG_ERROR : '';
      const dobAdultMsg = dobAdult ? ADULT_ERROR : '';
      handleErrorState('dob', {
        state: (dobIsEmpty || dobYoung || dobAdult),
        msg: (dobIsEmptyMsg || dobYoungMsg || dobAdultMsg),
      });
    }
  }, [dateBirthday, dependentDetails.dob, valiAdult, valiYoung]);


  useEffect(() => {
    if (dependentDetails.dob && !!dependentDetails.relationShip) {
      getCost();
    }
  }, [dependentDetails.relationShip, dependentDetails.dob, getCost]);

  const onChangeDetails = (dataKey: string, value: any) => {
    if (dataKey === 'dob') {
      setDateBirthday(new Date(value));
      const isDob = dataKey === 'dob';
      if (isDob) {
        const dobIsEmpty = !value;
        const dobYoung = !valiYoung(new Date(value));
        const dobAdult = !valiAdult(new Date(value));
        const dobIsEmptyMsg = dobIsEmpty ? DATE_OF_BIRTH_EMPTY : '';
        const dobYoungMsg = dobYoung ? YOUNG_ERROR : '';
        const dobAdultMsg = dobAdult ? ADULT_ERROR : '';
        handleErrorState(dataKey, {
          state: (dobIsEmpty || dobYoung || dobAdult),
          msg: (dobIsEmptyMsg || dobYoungMsg || dobAdultMsg),
        });
      }
    } else if (dataKey === 'relationShip') {
      const errorRelation = !relationValidate(value);
      handleErrorState(dataKey, {
        state: errorRelation,
        msg: errorRelation ? RELATIONSHIP_ERROR : '',
      });
      if (errorRelation) return;
    } else if (dataKey === 'gender') {
      handleErrorState(dataKey, {
        state: !value,
        msg: GENDER_EMPTY,
      });
    } else if (['firstName', 'lastName'].includes(dataKey)) {
      // let error = value && !NAME_REG.test(value);
      let error = !value;
      handleErrorState(dataKey, {
        state: error,
        msg: INPUT_NAME_ERROR,
      });
    }
    setDependentDetails(prevState => ({
      ...prevState,
      [dataKey]: value,
    }));
  };

  return (
    <EditDependentItemContent>
      <Row marginBottom={37}>
        <div className="title-text">{`Dependent ${editData ? dependentDatas.findIndex(data => (data === editData)) + 1 : dependentDatas.length + 1}`}</div>
        <div className="remove-text" onClick={() => cancel(editData)}>CANCEL</div>
      </Row>
      {
        DEPENDENTS_CONFIG.map((item, index) => (
          <InputField
            key={`${item.fieldName}-${index.toString()}`}
            details={dependentDetails}
            onChangeDetails={onChangeDetails}
            filedNameMap={DEPENDENTS_FIELD_NAME_MAP}
            width={290}
            {...item}
            option={item.fieldName === 'relationShip' ? relationStrs : item.option}
            errorState={detailsErrorState?.[item.fieldName]?.state}
            errMsg={detailsErrorState?.[item.fieldName]?.msg || item?.errMsg}
          />
        ))
      }
      <Row>
        <div className="addtional-text">Addtional Cost</div>
        <div className="PhP-text">
          {`PHP ${editCost}/month`}
        </div>
      </Row>
      <EditButton onClick={addDependentClick}>
        {editData ? 'CONFIRM THIS DEPENDENT' : 'ADD THIS DEPENDENT'}
      </EditButton>
    </EditDependentItemContent>
  );
};

export default forwardRef<IRef, IEditDependentItem>(EditDependentItem);
