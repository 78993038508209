import React,{FC, useEffect, useMemo, useState} from 'react';
import styled from 'styled-px2vw';
import branchList from './branchList.json';
import { List, SearchBar } from 'antd-mobile'

const SearchBranchWrapper = styled.div<{ visible?: boolean}>`
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  transition: all .3s;
  transform: translateX(${props => props.visible ? 0 : '100vw'});
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;

const SearchBranchHeader = styled.div`
  height: fit-content;
`;

const StyledList = styled(List)`
  overflow: auto;
  flex: 1;
`;

const NoData = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 48px;
`;

const StyledListItem = styled(List.Item)`
  border-bottom: 1px solid #ddd;
  .am-list-line {
    border-bottom: none !important;
    &::after {
      display: none !important;
    }
  }
`;

const BranchCode = styled.div`
  font-size: 24px;
  color: #aaa;
`;

interface ISearchBranch {
  onSelectBranch: (branch?: { name: string, code: string }) => void,
  onCancel: () => void,
  visible?: boolean,
  branchCodeOptions: {label: string, value: string}[]
}

interface BranchItem {
   name: string,
   code: string
}

const branchSortMethod = (a: BranchItem, b: BranchItem) => Number.isNaN(Number(a.name.substring(0, 1))) ? a.name.localeCompare(b.name) : 1

const SearchBranch: FC<ISearchBranch> = ({
  onSelectBranch,
  onCancel,
  visible,
  branchCodeOptions
}) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const onSearchBarChange = (value: string) => {
    setSearchValue(value)
  }

  const clickListItem = (item:{name:string, code:string}) => {
    onSelectBranch(item)
  }

  const renderBranchList = useMemo(() => {
    const list = branchCodeOptions.length ? branchCodeOptions.map(item => ({name: item.label, code: item.value})) : branchList;
    return list.sort(branchSortMethod).filter(item => {
      const {code, name} = item
      return code.indexOf(searchValue) === 0 || name.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) === 0;
    })
  }, [searchValue, branchCodeOptions])

  useEffect(() => {
    if (!visible) {
      setSearchValue('')
    }
  }, [visible])


  return (
    <SearchBranchWrapper visible={visible}>
      <SearchBranchHeader>
        <SearchBar
          value={searchValue}
          onChange={onSearchBarChange}
          placeholder="Search one"
          cancelText="cancel"
          onCancel={onCancel}
          showCancelButton
        />
      </SearchBranchHeader>
      {
        !!renderBranchList.length ? <StyledList>
          {
            renderBranchList.map(item => (
              <StyledListItem key={item.code} onClick={() => clickListItem(item)}>
                  {item.name}
                  <BranchCode>{item.code}</BranchCode>
              </StyledListItem>
            ))
          }
        </StyledList> : <NoData>- No data -</NoData>
      }
    </SearchBranchWrapper>
  )
}

export default React.memo(SearchBranch);
