import moment from 'moment-timezone';
import { IFieldProps } from './InputField';

// import OCCUPATION_OPTION from './occupation.json';
// import { commonSort } from '../CheckOut/config';
import {
  INPUT_NAME_ERROR,
  // NAME_REG,
} from '../../utils';

import branchList from './branchList.json';

export const FIELD_NAME_MAP: Record<string, string> = {
  firstName: 'First Name',
  noMiddleName: 'No Middle Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  birthday: 'Date of Birth',
  gender: 'Gender',
  occupation: 'Occupation',
  mobileNumber: 'Mobile Number',
  suffix: 'Suffix',
  branchCode: 'BPI Branch',
  isReferrer: 'Did somebody refer this product to you?',
  bankReferrerCode: 'Referrer Code',
  bse: 'Bancassurance Sales Executive Code'
};

export const formatDate = (date: Date) => {
  const pad = (n: any) => (n < 10 ? `0${n}` : n);
  const dateStr = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  return `${dateStr}`;
};

export const GENDER_CONFIG: { label: string, value: any }[] = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
];

export const REFER_YOU_OPTIONS: { label: string, value: any }[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const ERROR_CODE_MSG: Record<string, string> = {
  1001: '<Validation of Existing Dropoff Message>',
  1002: '<Validation of Actimize Dropoff Message>',
  1003: '<Validation of Existing & Actimize Dropoff Message>',
};

const COMMON_ERROR_TIP = `\n\nDon’t worry! BPI AIA has various other life and health protection plans you can check out.\n\nYou can talk with our Bancassurance Sales Executive and schedule an appointment here: `;

export const getFailedMsg: Record<string, any> = {
  1001: `Sorry, it seems that you have an existing coverage already.${COMMON_ERROR_TIP}`,
  1002: `Sorry, you should be 18 to 64 years old to avail this product.${COMMON_ERROR_TIP}`,
  1003: `Sorry, you should be 18 to 64 years old to avail this product and it seems that you have an existing coverage already.${COMMON_ERROR_TIP}`,
};

export const EXPIRE_MODAL_TEXT = 'Your session has expired.\n' +
  'You may restart your session by following these two easy steps:\n' +
  '1.Delete your Messenger chat with BPI AIA\n' +
  '2.Click the link you received from BPI or BPI AIA';

export const BETA_VISION_MODAL_TEXT = {
  text: 'This is a beta version. If you encounter any issues, please call us at (02) 8528-5501 (Monday to Friday, 8:00 a.m. to 5:00 p.m., except holidays), or send us an email at ',
  email: 'BPIAIA.CustomerService@aia.com',
}

export const MOBILE_CODE: any = (mobileNumber: any) => ({
  infos: {
    title: 'We like to know more about you!',
    desc: 'The information you share below will allow us to offer the best plan for you.',
  },
  info: {
    title: 'Please complete your details.',
    desc: 'The information to be collected are required to issue the policy application and that the data will be used for its intended purposes only.',
  },
  confirm: {
    title: 'Please confirm your mobile number.',
    desc: 'The information to be collected are required to issue the policy application and that the data will be used for its intended purposes only.',
    filedTitle: 'Mobile Number',
    buttonText: 'SEND SMS CODE',
  },
  otp: {
    title: 'Please confirm your verification code',
    desc: `Enter the 4-digit OTP (one-time password) we've sent to your mobile number +63 ${mobileNumber} valid in the next 180 seconds below.`,
    filedTitle: 'Verification Code',
    buttonText: 'NEXT',
  },
  vCode: {
    title: 'Please confirm your verification code',
    desc: `Enter the 6-digit OTP (one-time password) we've sent to your mobile number +63******${mobileNumber.slice(-2)} valid in the next 120 seconds below.`,
    filedTitle: 'Verification Code',
    buttonText: 'NEXT',
  },
});

export const SUFFIX_OPTION = [
  { label: 'None', value: 'NA' },
  { label: 'Jr', value: 'Jr' },
  { label: 'Sr', value: 'Sr' },
  { label: 'III', value: 'III' },
  { label: 'IV', value: 'IV' },
  { label: 'V', value: 'V' },
  { label: 'VI', value: 'VI' },
  { label: 'VII', value: 'VII' },
  { label: 'VIII', value: 'VIII' },
];

export const BRANCH_OPTIONS = [
  { label: 'AYALA PASEO', value: 'AYALA PASEO' },
  { label: 'GREENBELT CINEMA', value: 'GREENBELT CINEMA' },
  { label: 'PLAZA CERVANTES', value: 'PLAZA CERVANTES' },
  { label: 'GLORETTA MALL GROUND LEVEL', value: 'GLORETTA MALL GROUND LEVEL' },
  { label: 'LEGASPI SALCEDO', value: 'LEGASPI SALCEDO' },
  { label: 'MAGALLANES SOUTH', value: 'MAGALLANES SOUTH' },
];

export const INFO_DETAILS_MAP_FIELDS: IFieldProps[] = [
  {
    fieldName: 'branchCode',
    type: 'searchButton',
    option: branchList.map(item => ({ label: item.name, value: item.code })),
    showIcon: true,
    optional: true,
    clear: true,
    info: 'The branch where you opened your BPI account. For credit card accounts, you can leave it blank.'
  },
  {
    fieldName: 'isReferrer',
    type: 'radioButton',
    option: REFER_YOU_OPTIONS,
  },
  {
    fieldName: 'bankReferrerCode',
    type: 'telephone',
    inputMode: 'numeric',
    optional: true,
    info: 'A 9-digit code provided by a bank referrer. You may get this from the branch staff who referred you or request assistance from your Bancassurance Sales Executive.',
    maxLength: 9,
  },
  {
    fieldName: 'bse',
    type: 'telephone',
    inputMode: 'numeric',
    optional: true,
    maxLength: 9,
    info: 'You may get this 9-digit code from your Bancassurance Sales Executive (BSE) - the insurance expert assigned to each BPI branch.',
    errMsg: 'Please fill out Bank Referrer or Bancassurance Sales Executive code field.'
  },
  {
    fieldName: 'firstName',
    type: 'input',
    // rule: NAME_REG,
    errMsg: INPUT_NAME_ERROR,
  },
  {
    fieldName: 'noMiddleName',
    type: 'checkbox',
    // rule: NAME_REG,
    errMsg: INPUT_NAME_ERROR,
    optional: true,
  },
  {
    fieldName: 'middleName',
    type: 'input',
    // rule: NAME_REG,
    errMsg: INPUT_NAME_ERROR,
  },
  {
    fieldName: 'lastName',
    type: 'input',
    // rule: NAME_REG,
    errMsg: INPUT_NAME_ERROR,
  },
  {
    fieldName: 'suffix',
    type: 'picker',
    option: SUFFIX_OPTION,
    showIcon: true,
    optional: true,
    clear: true,
  },
  {
    fieldName: 'birthday',
    type: 'datePicker',
    minDate: moment().year(new Date().getFullYear() - 65).date(new Date().getDate() + 183).toDate(),
    maxDate: moment().year(new Date().getFullYear() - 18).toDate(),
  },
  {
    fieldName: 'gender',
    type: 'radioButton',
    option: GENDER_CONFIG,
  },
  // {
  //   fieldName: 'occupation',
  //   type: 'select',
  //   option: OCCUPATION_OPTION.sort(commonSort),
  // },
  {
    fieldName: 'mobileNumber',
    type: 'telephone',
    tip: 'SMS notification will be sent to your mobile number to make sure it\'s correct',
    phone: 0,
    maxLength: 10,
    inputMode: 'numeric',
    addonBefore: '+63',
    rule: /^(9\d{9})$/,
    errMsg: 'Please input a valid mobile number.',
    otherErrMsg: 'Please re-enter your mobile number.',
    placeholder: '9XXXXXXXXX',
  },
];
