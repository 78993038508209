/**
 * 检验对象中的值，默认检验为 ""、为 undefined
 * 如果其中有值为 ""、为 undefined 返回 true，反之则返回 false
 * @param object 需要检验的对象
 * @param type 需要检验的对象值
 *
 * type 为 'boolean' 时
 * object 类型要为 Record<string, boolean>，或者 Record<string, { ..., state: boolean }>，如：
 * {
 *   test1: {
 *     name: 'a',
 *     state: false
 *   },
 *   test2: {
 *     name: 'b',
 *     state: false
 *   },
 * }
 * 通常用于检测 Form 中的 errorState
 */
const isEmptyField = (object: Record<string, any>, type?: 'boolean', judgeFalse?: boolean) => (
  !!Object.keys(object).filter(
    key => {
      const value = object[key];
      const valueState = value?.state || value;
      if (type === 'boolean') {
        return valueState === !judgeFalse;
      }
      return (value === '' || value === undefined);
    },
  ).length
);

export const checkStringIsCoherent = (str: any) => {
  const data = str.split('');
  const chartCode = []; // 存放ASCII码
  let same = true;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const i in data) {
    chartCode.push(str.charCodeAt(i));
    if (chartCode.length === data.length) {
      for (let j = 1; j <= chartCode.length; j++) {
        if (Math.abs(Number(chartCode[j]) - Number(chartCode[j - 1])) > 1) {
          same = false;
          break;
        }
      }
    }
  }
  return same;
};

export default isEmptyField;
