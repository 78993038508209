import styled from 'styled-px2vw';
import { Statistic } from 'antd';

export const DateOfBirthWrap = styled.div<{ showIcon?: boolean; disabled?: boolean; width?: number }>`
  width: ${props => (`${2 * (props.width || 323)}px`)};
  height: 88px;
  padding: 0 28px;
  font-size: 28px;
  line-height: 88px;
  border: 2px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => (props?.disabled ? '#F5F5F5;' : '#FFFFFF')};
  position: relative;

  &.select-wrap {
    padding: 0;
    border: none;
  }

  .picker-dropdown-icon {
    width: 28px;
    height: 28px;
    ${props => (!props?.showIcon && 'display: none;')};
  }

  .picker-clear-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    right: ${props => (`${props?.showIcon ? 72 : 20}px`)};
  }

  &.no-date-data {
    color: #A0A0A0;
  }
`;

export const VCodeWrap = styled.div`
  width: 646px;
  margin-top: 52px;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-btn {
    font-size: 24px;
  }
`;

export const VCodeTipWrap = styled.div`
  position: relative;

  .code-error {
    font-size: 24px;
    line-height: 36px;
    color: #FF0000;
    position: absolute;
    width: max-content;
    top: -32px;
  }
`;

export const VCodeError = styled.div`
  width: max-content;
  margin-top: 16px;
  font-size: 24px;
  line-height: 36px;
  color: #FF0000;
`;

export const DigitDivGroup = styled.div`
  width: 646px;
  margin-top: 40px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: text;
  justify-content: space-between;

  .code-input {
    opacity: 0;
    z-index: -100;
  }
  .current-digit-div::after {
    content: '';
    position: absolute;
    width: 2px;
    background: #0084FF;
    height: 60px;
    top: 0;
    bottom: 0;
    right: 66px;
    margin: auto 0;
    animation: cursorFlash 1.2s infinite;
    @keyframes cursorFlash {
      0% { opacity: 0; }
      49% { opacity: 0; }
      50% { opacity: 1; }
      99% { opacity: 1; }
      100% { opacity: 0; }
    }
  }
  .current-digit-div2::after {
    right: 0;
  }
`;

export const DigitWrap = styled.div`
  position: absolute;
  display: flex;
  width: 646px;
  justify-content: space-between;
`;

export const DigitDiv = styled.div<{ digitNumber: number, error?: boolean }>`
  width: ${props => `${528 / props.digitNumber}px`};
  height: 96px;
  line-height: 96px;
  border: 2px solid ${props => (props?.error ? '#FF0000' : '#DCDCDC')};
  border-radius: 8px;
  text-align: center;
  position: relative;
`;

export const FailedWrap = styled.div`
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  white-space: pre-wrap;
  letter-spacing: -0.016em;

  .error-link {
    color: #1890ff;
    word-break: break-all;
    cursor: pointer;
  }
`;

export const CountDown = styled(Statistic.Countdown)`
  display: inline-block;
  .ant-statistic-content-value {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #666666;
  }
`;

export const VerificationCodeTitle = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
`;

export const BetaVersion = styled.div`
  margin-bottom: 52px;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: #6A6A6A;

  svg {
    width: 32px;
    height: 32px;
    margin-left: 20px;
    vertical-align: text-bottom;
    cursor: pointer;
  }
`;

export const EmailWrapper = styled.span`
  color: #0084FF;
  font-weight: 500;
`;
