import React, { FC } from 'react';

import { ReactComponent as CloseSvg } from '../assets/images/close.svg';
import { StyleModal, ModalContent } from './style';
import { Toast } from 'antd-mobile';
import { closeMessengerBrowser } from '../utils';

interface ModalProps {
  visible: boolean;
  onClose?: () => void;
  width?: number;
  modalContentWidth?: string;
  className?: string;
  notShowCloseSvg?: boolean;
  height?: number;
  closeMicrosite?: boolean;
  heightFitContent?: boolean;
}
const Modal:FC<ModalProps> = ({
  visible,
  onClose,
  width,
  modalContentWidth,
  className,
  notShowCloseSvg,
  height,
  closeMicrosite,
  children,
  heightFitContent
}) => {
  const close = () => {
    onClose?.();
    if (closeMicrosite) {
      closeMessengerBrowser();
    }
  }
  return (
    <StyleModal
      visible={visible}
      width={width || 306}
      height={height || 384}
      heightFitContent={heightFitContent}
      className={`common-modal ${className}`}
    >
      <CloseSvg className={`close-svg ${notShowCloseSvg ? 'hide-svg' : ''}`} onClick={close} />
      <ModalContent
        modalWidth={width || 306}
        modalContentWidth={modalContentWidth}
        notShowCloseSvg={notShowCloseSvg}
      >
        {children}
      </ModalContent>
    </StyleModal>
  );
};

export default Modal;
