import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/fonts';
import apm from './utils/apm';

if (process.env.REACT_APP_APM_SERVICE_NAME && process.env.REACT_APP_APM_SERVICE_URL) {
  apm();
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
