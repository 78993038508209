import styled from 'styled-px2vw';
import { Row as AntdRow, Button as AntdButton } from 'antd';
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  .pay-success {
    font-weight: bold;
    font-size: 32px;
    margin-top: 40px;
    line-height: 45px;
    text-align: center; 
  }
  img {
    margin: 0 auto;
    width: 160px;
    height: 160px;
  }
  .pay-failed {
    font-size: 32px;
    line-height: 40px;
    text-align: center; 
  }
`;
export const Row = styled(AntdRow)`
  margin: 8px auto 0 auto;
  .transaction {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center; 
  }
  .transaction-number {
    font-size: 32px;
    line-height: 40px;
    text-align: center; 
  }
`;

export const CloseButton = styled(AntdButton) <{}>`
  height: 96px;
  width: 648px;
  border-radius: 16px;
  background-color: #0084FF;
  color: #fff;
  font-size: 32px;
  margin-top: 500px;
  margin-bottom: 100px;
  margin-left: 51px;
  line-height: 80px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: none;
  &.ant-btn:not([disabled]):hover {
    color: #fff;
    background-color: #0084FF;
  }
  &.ant-btn:focus {
    color: #fff;
    background-color: #0084FF;
  }
`;