import { fetchGet, fetchPost } from '../utils/axios';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// ------FB Retail-------
const COMMON_URI = '/api/bpi-retail/';
// const COMMON_URI = 'http://192.168.10.8:5010/api/bpi-retail/';

export type AddDependentItem = {
  // dependentName: string,
  firstName: string,
  middleName: string,
  lastName: string,
  dob: string,
  relationShip: number
  // placeOfBirth: string,
  gender: string
};

interface DependentCost {
  (parameters: {
    PolicyOrderId: string;
    DependentBirthday: string;
    Relationship: number;
  }): Promise<any>
}

export interface ICommonConfirmParams {
  idType: string,
  idNo: string,
  idExpiryDate: string,
  // placeOfBirth: string,
  // addressLine1: string,
  // addressLine2?: string,
  // province: string,
  // city: string,
  // district: string,
  // zipCode: string,
  emailAddress: string,
  maritalStatus: string,
  policyOrderId: string,
}

interface IConfirmParams extends ICommonConfirmParams {
  infoDetailsId: string,
  dependents: AddDependentItem[];
  dependentsCount: number;
}

interface IDependentParams extends ICommonConfirmParams {
  dependents: AddDependentItem;
}

interface Confirm {
  (parameters: IConfirmParams): Promise<ConfirmResult>
}
interface ConfirmResult {
  data: any;
  code?: number;
  hint?: string;
}

interface Checkout {
  (parameters: {
    PolicyOrderId: string;
    // PaySuccessRedirectUrl: string;
  }): Promise<string>
}

export const getOrderDetail = (policyOrderId: string) => fetchGet({
  url: `${COMMON_URI}policy-order`,
  data: {
    policyOrderId,
  },
  config,
});

export const updateCheckout = (data: any) => fetchPost({
  url: `${COMMON_URI}policy-order/edit-check-out`,
  data,
  config,
});

export const getDependentCost: DependentCost = ({ PolicyOrderId, DependentBirthday, Relationship }) => fetchGet({
  url: `${COMMON_URI}policy-order/dependent-cost`,
  data: {
    PolicyOrderId,
    DependentBirthday,
    Relationship,
  },
  config,
});

export const payViaBPICard: Confirm = (data: IConfirmParams) => fetchPost({
  url: `${COMMON_URI}policy-order/confirm`,
  data,
  config,
});

export const addDependent = (data: IDependentParams) => fetchPost({
  url: `${COMMON_URI}policy-order/dependent`,
  data,
  config,
});

export const editDependent = (data: IDependentParams) => fetchPost({
  url: `${COMMON_URI}policy-order/edit-dependent`,
  data,
  config,
});

export const deleteDependent = (data: IDependentParams) => fetchPost({
  url: `${COMMON_URI}policy-order/delete-dependent`,
  data,
  config,
});

export const PaymentParameters: Checkout = ({
  PolicyOrderId,
  // PaySuccessRedirectUrl,
}) => fetchGet({
  url: `${COMMON_URI}policy-order/payment-parameters`,
  data: {
    PolicyOrderId,
    // PaySuccessRedirectUrl,
  },
  config,
});

export const recordPolicyOrderButton = (data: { userContext: string; type: number }) => fetchPost({
  url: `${COMMON_URI}policy-order/record-policy-order-button`,
  config,
  data,
});

export const policyOrder = (data: { infoDetailsId: string, insurancePlanId: string, userContext: string, facebookId: string }) => fetchPost({
  url: `${COMMON_URI}policy-order`,
  data,
  config,
});

export const timeRecord = (data: { timestamp: number, type: number, policyOrderId: string, start?: string, end?: string }) => fetchPost({
  url: `${COMMON_URI}payment-time-record/payment-time-record`,
  data,
  config,
});
