import moment from 'moment-timezone';

import { IFieldProps } from '../infoDetails/InputField';
import { MARRIED, SINGLE, SEPARATED, WIDOWED, SINGLE_PARENT, INPUT_NAME_ERROR } from '../../utils';
import localJson from './local.json';
import idTypeJson from './idType.json';
import { SUFFIX_OPTION } from '../infoDetails/config';

const DEFAULT_ID_TYPE_RULE_REG = /^[A-Za-z0-9]{3,15}$/;

export const ID_NO_RULES: Record<string, RegExp> = {
  'Alien / Immigrant Certificate of Registration': DEFAULT_ID_TYPE_RULE_REG,
  'Barangay Certification': DEFAULT_ID_TYPE_RULE_REG,
  'Company ID': DEFAULT_ID_TYPE_RULE_REG,
  'DSWD Certification': DEFAULT_ID_TYPE_RULE_REG,
  "Driver's License": DEFAULT_ID_TYPE_RULE_REG,
  'GOCC ID': DEFAULT_ID_TYPE_RULE_REG,
  GSIS: /^\d{11}$/,
  'Integrated Bar of the Philippines (IBP) ID': DEFAULT_ID_TYPE_RULE_REG,
  'NBI Clearance': DEFAULT_ID_TYPE_RULE_REG,
  'NCWDP Certificate': DEFAULT_ID_TYPE_RULE_REG,
  'OFW ID': DEFAULT_ID_TYPE_RULE_REG,
  'Overseas Workers Welfare (OWWA)': DEFAULT_ID_TYPE_RULE_REG,
  'Pag-ibig Loyalty Card': DEFAULT_ID_TYPE_RULE_REG,
  Passport: DEFAULT_ID_TYPE_RULE_REG,
  PhilHealth: DEFAULT_ID_TYPE_RULE_REG,
  'Police Clearance': DEFAULT_ID_TYPE_RULE_REG,
  'Postal ID': DEFAULT_ID_TYPE_RULE_REG,
  'Professional Regulations Commission (PRC) ID': DEFAULT_ID_TYPE_RULE_REG,
  SSS: /^\d{10}$|^\d{13}$/,
  "Seafarer's Registration Certificate": DEFAULT_ID_TYPE_RULE_REG,
  "Seaman's Book": DEFAULT_ID_TYPE_RULE_REG,
  'Senior Citizen Card': DEFAULT_ID_TYPE_RULE_REG,
  'Student ID': DEFAULT_ID_TYPE_RULE_REG,
  TIN: /^\d{9}$|^\d{13}$/,
  UMID: /^[A-Za-z0-9]{5,15}$/,
  "Voter's ID": DEFAULT_ID_TYPE_RULE_REG,
};

export const CHECKOUT_FILED_MAP: Record<string, any> = {
  idType: 'ID Type',
  idNo: 'ID No.',
  idExpiryDate: 'ID Expiry Date',
  placeOfBirth: 'Place of Birth',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  province: 'Province',
  city: 'City',
  district: 'District',
  zipCode: 'Zip Code',
  emailAddress: 'Email Address',
  maritalStatus: 'Marital Status',
};

export const MARITAL_STATUS_OPTION = [
  { label: SINGLE, value: SINGLE },
  { label: MARRIED, value: MARRIED },
  { label: SINGLE_PARENT, value: SINGLE_PARENT },
  { label: WIDOWED, value: WIDOWED },
  { label: SEPARATED, value: SEPARATED },
];

export const PLACE_OF_BIRTH_REG = /^[a-zA-Z ]+$/;

export const requiredIdType = ['Passport', "Driver's License", 'Professional Regulations Commission (PRC) ID', 'Postal ID', "Voter's ID", 'Barangay Certification', 'Overseas Workers Welfare (OWWA)', 'OFW ID', "Seaman's Book", 'Alien / Immigrant Certificate of Registration', 'GOCC ID', 'NCWDP Certificate', 'DSWD Certification','Integrated Bar of the Philippines (IBP) ID', "Seafarer's Registration Certificate"]

export const CHECKOUT_MAP_FIELDS: IFieldProps[] = [
  {
    fieldName: 'idType',
    type: 'select',
    option: idTypeJson,
  },
  {
    fieldName: 'idNo',
    type: 'input',
    errMsg: 'Please input a valid ID number.',
    checkIsCoherent: true,
  },
  {
    fieldName: 'idExpiryDate',
    type: 'datePicker',
    minDate: moment().add(1, 'day').toDate(),
    maxDate: moment().add(100, 'years').add(1, 'day').toDate(),
    errMsg: 'ID should have at least 6 months validity from the date of your application. Please use different ID.',
    optional: true,
    clear: true,
  },
  // {
  //   fieldName: 'placeOfBirth',
  //   type: 'input',
  //   maxLength: 20,
  //   placeholder: 'City / Municipality',
  //   rule: PLACE_OF_BIRTH_REG,
  //   errMsg: 'Please enter correct place of city.Thanks.',
  // },
  // {
  //   fieldName: 'addressLine1',
  //   type: 'input',
  //   maxLength: 100,
  // },
  // {
  //   fieldName: 'addressLine2',
  //   type: 'input',
  //   maxLength: 100,
  // },
  // {
  //   fieldName: 'province',
  //   type: 'select',
  // },
  // {
  //   fieldName: 'city',
  //   type: 'select',
  // },
  // {
  //   fieldName: 'district',
  //   type: 'select',
  // },
  // {
  //   fieldName: 'zipCode',
  //   type: 'input',
  //   disabled: true,
  // },
  {
    fieldName: 'emailAddress',
    type: 'input',
    rule: /^([a-zA-Z0-9]+[-\\,\\_|\\_|\\|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{1,20}$/,
    errMsg: 'Please enter the correct email address.',
  },
  {
    fieldName: 'maritalStatus',
    type: 'picker',
    option: MARITAL_STATUS_OPTION,
    showIcon: true,
  },
];

export const DEPENDENTS_FIELD_NAME_MAP: Record<string, string> = {
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  relationShip: 'Relationship',
  dob: 'Date of Birth',
  placeOfBirth: 'Place of Birth',
  gender: 'Gender',
  suffix: 'Suffix',
};

const GENDER_CONFIG: { label: string, value: any }[] = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
];

export const DEPENDENTS_CONFIG: IFieldProps[] = [
  {
    fieldName: 'firstName',
    type: 'input',
    // rule: NAME_REG,
    errMsg: INPUT_NAME_ERROR,
  },
  {
    fieldName: 'middleName',
    type: 'input',
    // rule: NAME_REG,
    errMsg: INPUT_NAME_ERROR,
    optional: true,
  },
  {
    fieldName: 'lastName',
    type: 'input',
    // rule: NAME_REG,
    errMsg: INPUT_NAME_ERROR,
  },
  {
    fieldName: 'suffix',
    type: 'picker',
    option: SUFFIX_OPTION,
    showIcon: true,
    optional: true,
    clear: true,
  },
  {
    fieldName: 'relationShip',
    type: 'picker',
    showIcon: true,
  },
  {
    fieldName: 'dob',
    type: 'datePicker',
    minDate: moment().subtract(120, 'years').add(1, 'day').toDate(),
    maxDate: new Date(),
  },
  // {
  //   fieldName: 'placeOfBirth',
  //   type: 'input',
  //   maxLength: 20,
  //   placeholder: 'City / Municipality',
  //   rule: PLACE_OF_BIRTH_REG,
  //   errMsg: 'Please enter correct place of city. Thanks.',
  // },
  {
    fieldName: 'gender',
    type: 'radioButton',
    option: GENDER_CONFIG,
    width: 140,
  },
];

export const commonSort = (a: any, b: any) => (a.label > b.label ? 1 : -1);

/**
 * 获取到的数据格式
  ALL_CITY = {
    province: []
    PROVINCE: {
      city: [],
      CITY: {
        district: [],
        DISTRICT: ZIPCODE,
      },
    },
  };
 */
export const getLocalOption = () => {
  const curArr = localJson.map((item: any) => item?.province);
  const duplicateRemovalProvince = curArr.filter((item, index) => curArr.indexOf(item) === index);
  const res: any = {};
  res.province = duplicateRemovalProvince.map(provinceItem => ({ label: provinceItem || '-', value: provinceItem || '-' })).sort(commonSort);
  duplicateRemovalProvince.forEach(provinceItem => {
    const curProvinceList = localJson.filter((aItem: any) => aItem?.province === provinceItem);
    const curProvince = curProvinceList.map((aItem: any) => aItem?.city);
    // 获取 city 去重数组
    const duplicateRemovalCity = curProvince.filter((cityItem, index) => curProvince.indexOf(cityItem) === index);
    // 获取 City 下的 district
    duplicateRemovalCity.forEach((cityItem: string) => {
      const curCityList = curProvinceList.filter((aItem: any) => aItem?.city === cityItem);
      const curDistrict = curCityList.map((aItem: any) => aItem.district || '-');
      curDistrict.forEach(districtItem => {
        const curDistrictItem = JSON.parse(JSON.stringify({ ...res[provinceItem || '-']?.[cityItem] }));
        res[provinceItem || '-'] = {
          ...res[provinceItem || '-'],
          city: duplicateRemovalCity.map((aItem: any) => ({ label: aItem, value: aItem })).sort(commonSort),
          [cityItem]: {
            ...curDistrictItem,
            district: curCityList.map((aItem: any) => ({ label: aItem.district || '-', value: aItem.district || '-' })).sort(commonSort),
            [districtItem]: curCityList.filter(aaa => aaa.district === districtItem || aaa.district === '')?.[0]?.zipCode,
          },
        };
      });
    });
  });
  return res;
};

export const getJudgeDependent = (data: Record<string, any>) => ({
  firstName: data?.firstName,
  middleName: data?.middleName,
  lastName: data?.lastName,
  dob: data?.dob,
  suffix: data?.suffix,
  gender: data?.gender,
});

export const PERSONAL_INFO: string[] = [
  'You will be sharing the following information to BPI AIA:',
  '· Name',
  '· Gender',
  '· Civil Status',
  '· Citizenship',
  '· Birthdate',
  '· Address',
  '· Contact Details',
  '· Occupation Details',
  '· Identification Details',
  '· Other Information needed to simplify the application',
];

export const getMinDValue = (age: number) => (
  moment().year(new Date().getFullYear() - age).format('YYYY-MM-DD')
);

export const getMaxDValue = (age: number) => (
  moment().year(new Date().getFullYear() - age).date(new Date().getDate() + 183).format('YYYY-MM-DD')
);

export const getDValue = (time: any, age: number, type: 'min' | 'max') => (
  moment(moment(time).format('YYYY-MM-DD')).diff((type === 'min' ? getMinDValue : getMaxDValue)(age), 'd')
)
