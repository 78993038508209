import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { Toast } from 'antd-mobile';
import { Checkbox } from 'antd';
import { cloneDeep } from '@apollo/client/utilities';

import {
  CheckboxWrap,
  Content,
  Row,
  DescContent,
  Button,
  SettlementContent,
  AddedDependentContent,
  EditDependentContent,
  ProceedButton,
  DependentContent,
  ContactDetailsUnderscore,
  SettlementItemContent,
  BeneficiaryGuideWrap,
  BeneficiaryGuideImage,
  ViewBeneficiaryGuideButton,
  ViewBeneficiaryGuideTips,
  MaximumContent
} from './style';
import { Context, DependentData } from '../../store';
import DependentSettlement from './DependentSettlement';
import DependentItem from './DependentItem';
import EditDependentItem, { IRef } from './EditDependentItem';
import {
  getOrderDetail,
  AddDependentItem,
  policyOrder,
  updateCheckout,
  recordPolicyOrderButton,
  getBranchCodeList,
  getInfoDetails,
  editInfoDetail,
} from '../../api';
import {
  closeLoading,
  getQueryVariable,
  isEmptyField,
  MAX_DEPENDENT_LENGTH,
  openLoading,
  PAY_SUCCESS_CODE,
  replaceString,
} from '../../utils/index';
import {
  CHECKOUT_FILED_MAP,
  CHECKOUT_MAP_FIELDS,
  requiredIdType,
  getJudgeDependent,
  // getLocalOption,
  ID_NO_RULES,
  PERSONAL_INFO,
} from './config';
import InputField from '../infoDetails/InputField';
import { ContentWrapper, DetailsContent, Logo, PageTipTitle } from '../common/style';
import { formatDate, EXPIRE_MODAL_TEXT, BETA_VISION_MODAL_TEXT } from '../infoDetails/config';
import { Modal } from '../../components';
import { TermsAndConditionsModal } from './modal';
import { EmailWrapper } from '../infoDetails/style';
import VCodeInfoDetails from '../infoDetails/VCodeInfoDetails';
import InfoDataView from './infoDataView';
import CommonButton from '../../components/Button';
import BenefitView from './BenefitView';

const ORDER_STATUS = {
  NORMAL: 0,
  PAY_SUCCESS: 2,
};

type IEditModuleType = 'personalInformation' | 'identification' | 'referrals'

const CheckOut = () => {
  const childRef = useRef<IRef>(null);
  const history = useHistory();
  const { store: { dependentDatas, orderId, checkoutBasicInfo: checkoutDetails, checkoutData, totalAmount }, dispatch } = useContext(Context);

  const [inSummaryPage, setInSummaryPage] = useState<boolean>(false);

  const [editModuleData, setEditModuleData] = useState<Record<IEditModuleType, boolean>>({personalInformation: false,identification: false, referrals:false});
  
  const infoDetailRef = useRef<any>();

  const [branchCodeOptions, setBranchCodeOptions] = useState<{label: string, value: string}[]>([])

  const infoDetailId = useRef<string>('');

  const originMainBeneficiariesInfoDetails = useRef<Record<string, any>>({});

  const [mainBeneficiariesInfoDetails, setMainBeneficiariesInfoDetails] = useState<Record<string, any>>({
    firstName: '',
    middleName: '',
    lastName: '',
    birthday: '',
    gender: '',
    mobileNumber: '',
    suffix: '',
  });

  const [referralDetails, setReferralDetails] = useState<Record<string, any>>({
    branchCode: '',
    bankReferrerCode: '',
    isReferrer: false,
    bse: '',
  })

  const originCheckoutBasicInfo = useRef<Record<string, any>>({});

  const [infoDetails, setInfoDetails] = useState<any>({
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    dob: '',
    gender: '',
  });

  const [errorState, setErrorState] = useState<Record<string, boolean>>({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedBenefitView, setIsExpandedBenefitView] = useState(false);
  // const [localOption, setLocalOption] = useState<Record<string, { label: string, value: any }[]>>({
  //   province: [],
  //   city: [],
  //   district: [],
  // });

  const [localOptionDisabled, setLocalOptionDisabled] = useState<Record<string, boolean>>({
    idNo: true,
    // city: true,
    // district: true,
  });

  const [openAddDependent, setOpenAddDependent] = useState(false);
  const [openExitDependent, setOpenExitDependent] = useState(false);
  const [checked, setChecked] = useState({
    read: false,
    personalData: false,
  });
  const [modalVisible, setModalVisible] = useState({
    share: false,
    isExpired: false,
    termsAndConditions: false,
    showBetaVersion: false,
    showMaximum: false,
    repeatPurchase: false,
  });

  const [journeyStartTime, setJourneyStartTime] = useState('');

  const getMainBeneficiariesInfoDetails = async () => {
    const userContext = getQueryVariable('UserContext')
    if (!userContext) return
    openLoading();
    try {
      const {code, data} = await getInfoDetails(userContext)
      if (code === 0) {
        const resData = replaceString(data)
        const { firstName, middleName, lastName, birthday, gender, mobileNumber, suffix, bankReferrerCode, branchCode, bse, isReferrer } = resData;
        originMainBeneficiariesInfoDetails.current = {
          firstName,
          middleName,
          lastName,
          birthday,
          gender,
          mobileNumber,
          suffix,
          bankReferrerCode,
          branchCode,
          bse,
          isReferrer
        }
        setMainBeneficiariesInfoDetails(originMainBeneficiariesInfoDetails.current)
        setReferralDetails({bse, isReferrer, branchCode, bankReferrerCode})
        infoDetailId.current = data.id
      }
    } catch(err) {
      console.error(err);
      Toast.fail('There\'s something wrong with the server.');
    }
    closeLoading();
  }

  const getBranchOptions = async () => {
    openLoading();
    try {
      const result = await getBranchCodeList()
      if (result.code === 0 && result.data.length) {
        setBranchCodeOptions(result.data.map((item:any) => ({label: item.name, value: item.code})))
      }
    } catch (err) {
      console.error(err);
      Toast.fail('There\'s something wrong with the server.');
    }
    closeLoading()
  }

  const onViewModuleAction = (module: IEditModuleType, action: string) => {
    switch (action) {
      case 'edit':
        if (module === 'personalInformation') {
          setEditModuleData(data => ({...data, [module]: true}))
        }else {
          if (editModuleData.personalInformation || openAddDependent || openExitDependent) {
            Toast.info('Please SAVE before Editing.')
          } else {
            setInSummaryPage(false)
            document.body.scrollTop = 0
          }
        }
        break;
      case 'cancel':
        setEditModuleData(data => ({...data, [module]: false}))
        if (module === 'personalInformation') {
          setMainBeneficiariesInfoDetails(originMainBeneficiariesInfoDetails.current)
        } else {
          dispatch({
            type: 'setCheckoutBasicInfo',
            checkoutBasicInfo: originCheckoutBasicInfo.current,
          });
        }
        break;
      default:
        break;
    }
  }

  const onChangeMainBeneficiariesInfoDetails = (dataKey: string, value: any) => {
    setMainBeneficiariesInfoDetails(prevState => ({
      ...prevState,
      [dataKey]: value,
    }));
  };

  useEffect(() => {
    getBranchOptions()
    getMainBeneficiariesInfoDetails()
  }, [])

  const handleModalVisible = (key: string, state: boolean) => {
    setModalVisible(prevState => ({
      ...prevState,
      [key]: state,
    }));
  };

  document.title = 'CHECKOUT';

  const toastErr = () => {
    closeLoading();
    Toast.fail('There\'s something wrong with the server.');
  };

  const showToast = (errorText: React.ReactNode) => {
    Toast.info(errorText, Toast.SHORT);
  };

  const getDetailData = useCallback(async (orderIds: string) => {
    openLoading()
    getOrderDetail(orderIds).then(orderDetailRes => {
      if (orderDetailRes.code === 0) {
        dispatch({
          type: 'setTotalAmount',
          totalAmount: orderDetailRes?.data?.totalAmount,
        });
        const defaultCheckoutDetails = {
          idType: orderDetailRes?.data?.idType || '',
          idNo: orderDetailRes?.data?.idNo || '',
          idExpiryDate: orderDetailRes?.data?.idExpiryDate || '',
          // placeOfBirth: orderDetailRes?.data?.placeOfBirth || '',
          // addressLine1: orderDetailRes?.data?.addressLine1 || '',
          // addressLine2: orderDetailRes?.data?.addressLine2 || '',
          // province: orderDetailRes?.data?.province || '',
          // city: orderDetailRes?.data?.city || '',
          // district: orderDetailRes?.data?.district || '',
          // zipCode: orderDetailRes?.data?.zipCode || '',
          emailAddress: orderDetailRes?.data?.emailAddress || '',
          maritalStatus: orderDetailRes?.data?.maritalStatus || '',
        };
        if (orderDetailRes?.data?.idExpiryDate) {
          handleErrorState('idExpiryDate', moment(orderDetailRes?.data?.idExpiryDate).diff(formatDate(new Date()), 'M') < 6);
        }
        originCheckoutBasicInfo.current = defaultCheckoutDetails
        dispatch({
          type: 'setCheckoutBasicInfo',
          checkoutBasicInfo: defaultCheckoutDetails,
        });
        // setCheckoutDetails(defaultCheckoutDetails);
        setInfoDetails(getJudgeDependent(orderDetailRes?.data?.defaultDependent));
        if (orderDetailRes.status === ORDER_STATUS.PAY_SUCCESS) {
          /*
          支付成功
          */
          history.push(`PaySuccess?id=${orderIds}`);
          return;
        }
        dispatch({
          type: 'setCheckoutData',
          checkoutData: {
            ...orderDetailRes?.data?.defaultDependent,
            name: orderDetailRes?.data?.insurancePlan?.name,
          },
        });
        const dependentData = orderDetailRes?.data?.dependents?.map((item: any) => ({
          name: `${item?.firstName} ${item?.middleName} ${item?.lastName}`,
          firstName: item?.firstName,
          middleName: item?.middleName,
          lastName: item?.lastName,
          dob: item?.dob,
          relationShip: item?.relationShip,
          // placeOfBirth: item?.placeOfBirth || '',
          gender: item?.gender || '',
          amount: item?.amount,
          suffix: item?.suffix,
          date: new Date(item?.dob),
          edit: false,
        })) || [];
        dispatch({
          type: 'addDependent',
          dependentDatas: dependentData,
        });
        if (orderDetailRes?.data?.payInfo?.response_code && PAY_SUCCESS_CODE.includes(orderDetailRes?.data?.payInfo?.response_code)) {
          history.push(`PaySuccess?id=${orderIds}`);
        } else if (!orderDetailRes?.data?.editable) {
          /*
          支付中
          */
          history.push(`Payment?OrderId=${orderIds}`);
        }
      } else {
        if (orderDetailRes?.subErrorCode === 1004) {
          handleModalVisible('isExpired', true);
          return;
        }
      }
      }).catch(toastErr).finally(closeLoading);
  }, [dispatch, history]);

  const generateOrder = useCallback(async (infoId: string, insurancePlanId: string, userContextId: string, facebookId: string) => {
    openLoading();
    policyOrder({
      infoDetailsId: infoId,
      insurancePlanId,
      facebookId,
      userContext: userContextId,
    }).then(generateOrderRes => {
      if (generateOrderRes?.code === 0) {
        dispatch({
          type: 'setOrderId', orderId: generateOrderRes.data.id,
        });
        getDetailData(generateOrderRes.data.id);
      } else {
        closeLoading();
        if (generateOrderRes?.subErrorCode === 1004) {
          handleModalVisible('isExpired', true);
          return;
        }
      }
    }).catch(toastErr);
  }, [dispatch, getDetailData]);

  useEffect(() => {
    if (!orderId) {
      const mInfoDetailsId = getQueryVariable('InfoDetailsId') || '3a00b08a-494e-19b5-fc5b-80beac772663';
      const userContextId = getQueryVariable('UserContext') || '3a0062a1-b014-b70f-1402-572867deee52';
      const insurancePlanId = getQueryVariable('InsurancePlanId') || '719b7799-17a7-62f8-9d91-2cdfd229e749';
      const facebookId = getQueryVariable('FacebookId') || '2418762589723692';
      const type = Number(getQueryVariable('Type') || 2);
      recordPolicyOrderButton({
        userContext: userContextId,
        type,
      }).then(res => {
        setJourneyStartTime(res.data);
      });
      dispatch({
        type: 'setPsid', psid: facebookId,
      });
      // history.replace({ search: '' });
      generateOrder(mInfoDetailsId, insurancePlanId, userContextId, facebookId);
    }
  }, [dispatch, orderId, generateOrder]);

  const editThisDependent = (addDependentData: DependentData, editData?: DependentData, editType?: 'isEdit' | 'isRemove', editIndex?: number) => {
    const judgeAddData = getJudgeDependent(addDependentData);
    if (JSON.stringify(infoDetails) === JSON.stringify(judgeAddData)) {
      // TODO 文案待确认
      showToast('Sorry, you couldn\'t add the same dependent as personal information, please check it. Thanks.');
      return;
    }
    const isRemove = editType === 'isRemove';
    const isEdit = editType === 'isEdit';
    let beforeData = dependentDatas.map(item => getJudgeDependent(item));
    if (isEdit) {
      beforeData = beforeData.filter((data, index) => index !== editIndex);
    }
    const findSameDataIndex = beforeData.findIndex(data => (JSON.stringify(data) === JSON.stringify(judgeAddData)));
    if (!isRemove && findSameDataIndex !== -1) {
      showToast('Sorry, you have entered the same dependent. Please check again. Thanks.');
      return;
    }
    let finalDependent = [];
    if (isRemove) {
      finalDependent = dependentDatas.filter((data, index) => index !== editIndex);
    } else if (isEdit && (editIndex || editIndex === 0)) {
      finalDependent = [...dependentDatas.slice(0, editIndex), addDependentData, ...dependentDatas.slice(Number(editIndex) + 1)];
    } else {
      finalDependent = [...dependentDatas, addDependentData];
    }
    let dependents = finalDependent.map(item => ({
      firstName: item.firstName,
      middleName: item.middleName,
      lastName: item.lastName,
      dob: item.dob,
      relationShip: item.relationShip,
      // placeOfBirth: item.placeOfBirth,
      gender: item.gender,
      suffix: item.suffix,
    }));

    openLoading();
    updateCheckout({
      policyOrderId: orderId,
      ...checkoutDetails,
      dependents,
      buttonType: 2,
      journeyStartTime,
      addDependentTime: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
    }).then(res => {
      closeLoading();
      if (res?.code === 0) {
        setOpenAddDependent(false);
        setOpenExitDependent(false);
        dispatch({
          type: 'setTotalAmount',
          totalAmount: res?.data?.totalAmount,
        });
        if (isRemove) {
          dependentDatas.splice(findSameDataIndex, 1);
        } else if (editData) {
          const findIndex = dependentDatas.findIndex(data => (JSON.stringify(data) === JSON.stringify(editData)));
          if (findIndex !== -1) {
            dependentDatas[findIndex] = addDependentData;
          }
        } else {
          dependentDatas.push(addDependentData);
        }
        dispatch({
          type: 'addDependent', dependentDatas,
        });
      } else if ([4500, 1001].includes(res.subErrorCode)) {
        handleModalVisible(res.subErrorCode === 1001 ? 'repeatPurchase' : 'showMaximum', true)
      }
    }).catch(toastErr);
  };

  const addAnotherDependent = () => {
    if (dependentDatas.length >= MAX_DEPENDENT_LENGTH) {
      showToast('Add up to 5 Dependent');
      return;
    } if (!checkoutDetails.maritalStatus) {
      showToast('Please Select Marital Status');
      return;
    }
    setOpenAddDependent(true);
    setOpenExitDependent(false);
    if (openAddDependent) {
      childRef.current!.addDependentClick();
    }
  };

  const disableContinue = useMemo(() => {
    const checkParams = cloneDeep(checkoutDetails) as any;
    if (!requiredIdType.includes(checkoutDetails.idType)) {
      delete checkParams.idExpiryDate;
    }
    const isEditStatus = openAddDependent || openExitDependent;

    return isEmptyField(checkParams) || totalAmount === 0 || isEmptyField(errorState, 'boolean') || isEditStatus;
  }, [checkoutDetails, totalAmount, errorState, openAddDependent, openExitDependent])

  const disableCheckoutIdSave = useMemo(() => {
    const checkParams = cloneDeep(checkoutDetails) as any;
    if (!requiredIdType.includes(checkoutDetails.idType)) {
      delete checkParams.idExpiryDate;
    }

    return isEmptyField(checkParams)  || isEmptyField(errorState, 'boolean');
  }, [checkoutDetails, errorState])


  const payDisabled = useMemo(() => {
    const isEditStatus = openAddDependent || openExitDependent;
    return (
      isEmptyField(editModuleData, 'boolean') || isEmptyField(checked, 'boolean', true) || isEditStatus
    );
  }, [checked, openAddDependent, openExitDependent, editModuleData]);

  /**
   * buttonType
   * 点击 “PAY VIA BPI CARD” 时，buttonType=1
   * 添加/编辑/删除 dependent 时 buttonType=2
   * 更改 Martial status 时，buttonType=3
   *  Continue Button = 4 when
   */
  const jumpToPayment = useCallback(async (buttonType: number, isSave?: boolean, maritalStatus?: string, goSummaryPage?: boolean) => {
    const addDependents: AddDependentItem[] = dependentDatas.map((target: DependentData) => ({
      firstName: target.firstName,
      middleName: target.middleName,
      lastName: target.lastName,
      dob: target.dob,
      relationShip: target.relationShip,
      // placeOfBirth: target.placeOfBirth,
      gender: target.gender,
      suffix: target.suffix,
    } as AddDependentItem));
    openLoading();
    updateCheckout({
      policyOrderId: orderId,
      ...checkoutDetails,
      maritalStatus: maritalStatus || checkoutDetails.maritalStatus,
      dependents: maritalStatus ? [] : addDependents,
      buttonType,
      journeyStartTime,
    }).then(res => {
      originCheckoutBasicInfo.current = cloneDeep(checkoutDetails)
      closeLoading();
      if (res?.code === 0) {
        dispatch({
          type: 'setTotalAmount',
          totalAmount: res?.data?.totalAmount,
        });
        if (!isSave) {
          history.push(`friendlyReminder?OrderId=${orderId}`);
        }
        if (goSummaryPage) {
          document.body.scrollTop = 0
          setInSummaryPage(true)
        }
        if (inSummaryPage && buttonType === 3) {
          setEditModuleData(data => ({...data, identification: false}))
        }
      } else if (res.subErrorCode === 4500) {
        handleModalVisible('showMaximum', true)
      } else {
        toastErr();
      }
    }).catch(toastErr);
  
  }, [checkoutDetails, dependentDatas, history, orderId, dispatch, journeyStartTime, inSummaryPage]);

  const onCheckoutDetailsSave = () => {
    let maritalStatus = ''
    if (checkoutDetails.maritalStatus !== originCheckoutBasicInfo.current.maritalStatus) {
      maritalStatus = checkoutDetails.maritalStatus
      dispatch({
        type: 'addDependent', dependentDatas: [],
      });
    }
    jumpToPayment(3, true, maritalStatus)
}

  const cancelClick = (edit?: DependentData) => {
    setOpenExitDependent(false);
    setOpenAddDependent(false);
    if (edit) {
      const findIndex = dependentDatas.findIndex(data => (data === edit));
      dependentDatas[findIndex].edit = false;
      dispatch({
        type: 'addDependent', dependentDatas,
      });
    }
  };

  function changeTwoDecimal_f(x: any) {
    let f_x = parseFloat(x);
    if (isNaN(f_x)) {
      // alert('function:changeTwoDecimal->parameter error');
      return false;
    }
    f_x = Math.round(x * 100) / 100;
    let s_x = f_x.toString();
    let pos_decimal = s_x.indexOf('.');
    if (pos_decimal < 0) {
      pos_decimal = s_x.length;
      s_x += '.';
    }
    while (s_x.length <= pos_decimal + 2) {
      s_x += '0';
    }
    return s_x;
  }

  const handleErrorState = (dataKey: string, state: boolean) => {
    setErrorState(prevState => ({
      ...prevState,
      [dataKey]: state,
    }));
  };

  const onChangeCheckoutDetails = async (dataKey: string, value: any) => {
    // setCheckoutDetails((prevState: any) => ({
    //   ...prevState,
    //   idNo: dataKey === 'idType' && value !== prevState?.[dataKey] ? '' : prevState?.idNo,
    //   // city: dataKey === 'province' && value !== prevState?.[dataKey] ? '' : prevState?.city,
    //   // district: ['province', 'city'].includes(dataKey) && value !== prevState?.[dataKey] ? '' : prevState?.district,
    //   // zipCode: ['province', 'city'].includes(dataKey) && value !== prevState?.[dataKey] ? '' : prevState?.zipCode,
    //   [dataKey]: value,
    // }));
    dispatch({
      type: 'setCheckoutBasicInfo',
      checkoutBasicInfo: {
        ...checkoutDetails,
        idNo: dataKey === 'idType' && value !== checkoutDetails?.[dataKey] ? '' : checkoutDetails?.idNo,
        // city: dataKey === 'province' && value !== checkoutDetails?.[dataKey] ? '' : checkoutDetails?.city,
        // district: ['province', 'city'].includes(dataKey) && value !== checkoutDetails?.[dataKey] ? '' : checkoutDetails?.district,
        // zipCode: ['province', 'city'].includes(dataKey) && value !== checkoutDetails?.[dataKey] ? '' : checkoutDetails?.zipCode,
        [dataKey]: dataKey === 'emailAddress' && value ? value.slice(0, 128) : value,
      },
    });
    if (dataKey === 'idExpiryDate') {
      handleErrorState(dataKey, moment(value).diff(formatDate(new Date()), 'M') < 6);
    }
    if (dataKey === 'maritalStatus' && !inSummaryPage) {
      setOpenAddDependent(false);
      setOpenExitDependent(false);
      dispatch({
        type: 'addDependent', dependentDatas: [],
      });
      jumpToPayment(3, true, value);
      }
  };

  useEffect(() => {
    // const OPTIONS = getLocalOption();
    // const districtConfig = OPTIONS?.[checkoutDetails.province]?.[checkoutDetails.city];
    setLocalOptionDisabled({
      // city: !checkoutDetails.province,
      // district: !checkoutDetails.city,
      idNo: !checkoutDetails.idType,
    });
    // setLocalOption({
    //   province: OPTIONS?.province || [],
    //   city: OPTIONS?.[checkoutDetails.province]?.city || [],
    //   district: districtConfig?.district || [],
    // });
    // if (checkoutDetails.district) {
    //   setCheckoutDetails((prevState: any) => ({
    //     ...prevState,
    //     zipCode: districtConfig?.[checkoutDetails.district],
    //   }));
    //   dispatch({
    //     type: 'setCheckoutBasicInfo',
    //     checkoutBasicInfo: {
    //       ...checkoutDetails,
    //       zipCode: districtConfig?.[checkoutDetails.district],
    //     },
    //   });
    // }
  }, [
    // checkoutDetails.province,
    // checkoutDetails.city,
    // checkoutDetails.district,
    checkoutDetails.idType,
  ]);

  const onCheckboxChange = (e: any) => {
    setChecked(prevState => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
    if (e.target.name === 'read' && e.target.checked) {
      handleModalVisible('termsAndConditions', true);
    }
  };

  const downloadFile = async () => {
    handleModalVisible('termsAndConditions', true);
    /**
     * 2022-01-24
     * 客户更改需求：
     * 将下载 PDF 更改为打开弹窗显示
     */
    // await jumpToPayment(true);
    // 文件已确认，为 src/assets/files/assets/files/Pamilya Protect Terms and Conditions_Exclusion_10012022[5221].pdf
    // 此链接后端同事 丁武初 帮忙上传到 S3
    // const fileUrl = 'https://s3.ap-southeast-1.amazonaws.com/fb-retail.dev.connect.coherent.global/static/media/Pamilya%20Protect%20Terms%20and%20Conditions_Exclusion_10012022%5B5221%5D.pdf';
    // window.open(fileUrl);
  };

  const curFieldsConfigs = useMemo(() => {
    const curConfigs = cloneDeep(CHECKOUT_MAP_FIELDS)
    if (requiredIdType.includes(checkoutDetails.idType)) {
      curConfigs[2].optional = false
    }
    return curConfigs
  }, [checkoutDetails.idType])

  const onMainBeneficiariesInfoDetailsSave = async () => {
    const currentDetail = cloneDeep(mainBeneficiariesInfoDetails)
    if (!currentDetail.isReferrer) {
      delete currentDetail.bankReferrerCode
      delete currentDetail.bse
      setMainBeneficiariesInfoDetails(currentDetail)
    }
    openLoading()
    try {
      const result = await editInfoDetail({
        ...originCheckoutBasicInfo.current,
        ...currentDetail,
        infoDetailId: infoDetailId.current,
        userContext: getQueryVariable('UserContext'),
        policyOrderId: orderId,
        dependents: dependentDatas,
        journeyStartTime,
      })
      if (result.code === 0) {
        originMainBeneficiariesInfoDetails.current = { ...currentDetail }
        dispatch({
          type: 'setTotalAmount',
          totalAmount: result?.data?.totalAmount,
        });
        if (result?.data?.defaultAmount) {
          dispatch({
            type: 'setCheckoutData',
            checkoutData: {
              ...checkoutData,
              amount: result?.data?.defaultAmount,
            },
          });
        }
        setEditModuleData(data => ({...data, personalInformation: false}))
      }  else if ([4500, 1001].includes(result.subErrorCode)) {
        handleModalVisible(result.subErrorCode === 1001 ? 'repeatPurchase' : 'showMaximum', true)
      }
    } catch (err) {
      console.error(err);
      Toast.fail('There\'s something wrong with the server.');
    }
    closeLoading()
  }

  return (
    <Content>
      <ContentWrapper inSummaryPage={inSummaryPage}>
        <Logo />
        <DetailsContent inSummaryPage={inSummaryPage}>
          <PageTipTitle>
            { inSummaryPage ? 'Review your information.' : 'The information you share below will allow us to serve you better.' }
          </PageTipTitle>
           {
            inSummaryPage && (
              <InfoDataView 
                type="referrals"
                data={referralDetails}
                isEdit={false}
                onAction={(action) => onViewModuleAction('referrals',action)}
                branchCodeOptions={branchCodeOptions}
              />
            )
          }
          {
            inSummaryPage && (
              <InfoDataView 
                type="info"
                data={mainBeneficiariesInfoDetails}
                isEdit={editModuleData.personalInformation}
                onAction={(action) => onViewModuleAction('personalInformation',action)}
                branchCodeOptions={branchCodeOptions}
              />
            )
          }
          {
            editModuleData.personalInformation && (
              <VCodeInfoDetails 
                infoDetails={mainBeneficiariesInfoDetails}  
                pageId="infos"
                onChangeInfoDetails={onChangeMainBeneficiariesInfoDetails}
                setPageId={() => {}}
                onInfoNext={onMainBeneficiariesInfoDetailsSave}
                userContext=""
                ref={infoDetailRef}
                inSummaryPage
                branchCodeOptions={branchCodeOptions}
              />
            )
          }
          {
            inSummaryPage && (
              <InfoDataView 
                hasMarginTop
                type="identification"
                data={checkoutDetails}
                isEdit={editModuleData.identification}
                disableEdit={editModuleData.personalInformation || openAddDependent || openExitDependent}
                onAction={(action) => onViewModuleAction('identification',action)}
              />
            )
          }
          {
            (!inSummaryPage || editModuleData.identification) && curFieldsConfigs.map(item => (
              <InputField
                {...item}
                key={item?.fieldName}
                details={checkoutDetails}
                onChangeDetails={onChangeCheckoutDetails}
                filedNameMap={CHECKOUT_FILED_MAP}
                errorState={!!errorState?.[item?.fieldName]}
                setErrorState={handleErrorState}
                // option={['province', 'city', 'district'].includes(item.fieldName) ? localOption?.[item.fieldName] : item.option}
                // disabled={['city', 'district', 'idNo'].includes(item.fieldName) ? !!localOptionDisabled?.[item.fieldName] : !!item.disabled}
                disabled={['idNo'].includes(item.fieldName) ? !!localOptionDisabled?.[item.fieldName] : !!item.disabled}
                rule={item.fieldName === 'idNo' ? ID_NO_RULES?.[checkoutDetails.idType] : item?.rule}
              />
            ))
          }
          {
            editModuleData.identification && (
              <CommonButton
                onClick={onCheckoutDetailsSave}
                margin="margin-top: 48px"
                disabled={disableCheckoutIdSave}
              >
                SAVE 
              </CommonButton>
            )
          }
        </DetailsContent>
      </ContentWrapper>
      <DependentContent>
        {
          !inSummaryPage && (<>
            <ContactDetailsUnderscore />
            <DescContent edit={openAddDependent || dependentDatas.length > 0}>
              <div className="title-description-text">Any loved ones you would like to protect?</div>
              <div className="content-description-text">
                {'With this plan, you can also protect your family. Should anything happen to any of your dependents, the beneficiary will receive 100% of the benefits.\n\nNote: Dependents below 5 years old are covered up to 75% of the benefits and dependents below 4 years old are covered up to 50% of the benefits. HIB is only applicable to you as the policy owner.'}
              </div>
            </DescContent>
          </>
          )  
        }
        {dependentDatas.length > 0 && (
          <AddedDependentContent>
            {dependentDatas.filter(item => item.edit === false).map((item, index) => (
              <DependentItem
                confirmDependent={editThisDependent}
                editIndex={index}
                data={item}
                civil={checkoutDetails.maritalStatus}
                addDependent={openAddDependent}
                key={`edit${index.toString()}`}
                setOpenExitDependent={setOpenExitDependent}
                cannotRemove={inSummaryPage}
              />
            ))}
          </AddedDependentContent>
        )}
        {openAddDependent && (
          <EditDependentContent marginLeft={51}>
            <EditDependentItem
              addDependent={editThisDependent}
              cancel={cancelClick}
              childRef={childRef}
              civil={checkoutDetails.maritalStatus}
            />
          </EditDependentContent>
        )}
        {
          !inSummaryPage && (
            <Button onClick={addAnotherDependent} disabled={dependentDatas.length >= 5}>
              {openAddDependent || dependentDatas.length ? '+ Add another dependent' : '+ Add a dependent'}
            </Button>
          )
        }
      </DependentContent>
      <BeneficiaryGuideWrap>
        <BeneficiaryGuideImage isExpanded={isExpanded} />
        <ViewBeneficiaryGuideButton
          type='ghost'
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Collapse' : 'View Beneficiary Guide'}
        </ViewBeneficiaryGuideButton>
        <ViewBeneficiaryGuideTips>
          You may call us at (02) 8528 5501 or send us an email at BPIAIA.CustomerService@aia.com to change your set of beneficiaries after your policy has been issued.
        </ViewBeneficiaryGuideTips>
      </BeneficiaryGuideWrap>
      {
        inSummaryPage && (
          <BeneficiaryGuideWrap>
            <BenefitView isExpanded={isExpandedBenefitView} dependentDatas={dependentDatas} plan={checkoutData?.name}/>
            <ViewBeneficiaryGuideButton
              type='ghost'
              onClick={() => setIsExpandedBenefitView(!isExpandedBenefitView)}
            >
              {isExpandedBenefitView ? 'Collapse' : 'View Benefiit Details'}
            </ViewBeneficiaryGuideButton>
          </BeneficiaryGuideWrap>
        )
      }
      <SettlementContent>
        {
          dependentDatas?.length ? (
            <SettlementItemContent>
              <Row>
                <div className="title-text">{checkoutData?.name || '-'}</div>
                <div className="value-text">{`PHP ${checkoutData?.amount || '0.00'}`}</div>
              </Row>
            </SettlementItemContent>
          ) : null
        }
        {
          dependentDatas.map((item, index) => (
            <DependentSettlement
              data={item}
              dataIndex={+index + 1}
              key={`dependent${index.toString()}`}
            />
          ))
        }
        <Row margin_top={32}>
          <div className="total-title-text">Total Cost</div>
          <div className="total-value-text">
            {`PHP ${changeTwoDecimal_f(Number(totalAmount))}`}
            <span className="value-text">/month</span>
          </div>
        </Row>
      </SettlementContent>
      {
        inSummaryPage && (
          <>
            <CheckboxWrap>
              <Checkbox checked={checked.read} name="read" onChange={onCheckboxChange} />
              <div className="personal-data">
                I agree to the
                <span
                  className="download-pdf"
                  onClick={downloadFile}
                >
                  TERMS AND CONDITIONS and AMLA DECLARATION
                </span>
              </div>
            </CheckboxWrap>
            <CheckboxWrap marginTop={32}>
              <Checkbox checked={checked.personalData} name="personalData" onChange={onCheckboxChange} />
              <div className="personal-data">
                I hereby authorize BPI to share my
                <span
                  className="download-pdf"
                  onClick={() => handleModalVisible('share', true)}
                >
                  personal data&nbsp;
                </span>
                with BPI AIA Life Assurance Corporation (formerly BPI-Philam). The information I share will enable BPI AIA to offer me relevant life insurance solutions and a simpler journey by pre-populating data fields and simplifying application requirements.
              </div>
            </CheckboxWrap>
          </>
        )
      }
      <ProceedButton
        onClick={() => jumpToPayment(inSummaryPage ? 1 : 4, !inSummaryPage, '', !inSummaryPage)}
        disabled={inSummaryPage ? payDisabled : disableContinue}
      >
        {inSummaryPage ? 'PAY VIA BPI CARD' : 'Continue'} 
      </ProceedButton>
      <Modal
        visible={modalVisible.share}
        onClose={() => handleModalVisible('share', false)}
      >
        {
          PERSONAL_INFO.map(item => (
            <div key={item}>{item}</div>
          ))
        }
      </Modal>
      <TermsAndConditionsModal
        visible={modalVisible.termsAndConditions}
        onClose={() => handleModalVisible('termsAndConditions', false)}
      />
      <Modal
        visible={modalVisible.isExpired}
        height={237}
        closeMicrosite
      >
        {EXPIRE_MODAL_TEXT}
      </Modal>
      <Modal
        visible={modalVisible.repeatPurchase}
        height={120}
        onClose={() => handleModalVisible('repeatPurchase', false)}
      >
        Sorry, it seems that you have an existing coverage already.
      </Modal>
      <Modal
        visible={modalVisible.showMaximum}
        height={280}
        onClose={() => handleModalVisible('showMaximum', false)}
      >
        <MaximumContent>
         Oops! You have reached the maximum allowable premium for this product. Consider changing your plan or removing a dependent to be able to proceed. Otherwise, you may contact your Bancassurance Sales Executive by scheduling an appointment here: <a href="https://bit.ly/pp-fbmsgr-fna"  target="_blank"  rel="noopener noreferrer">https://bit.ly/pp-fbmsgr-fna</a> 
        </MaximumContent>
      </Modal>
      <Modal
        visible={modalVisible.showBetaVersion}
        height={230}
        onClose={() => handleModalVisible('showBetaVersion', false)}
      >
        {BETA_VISION_MODAL_TEXT.text}
        <EmailWrapper>{BETA_VISION_MODAL_TEXT.email}</EmailWrapper>.
      </Modal>
    </Content>
  );
};

export default CheckOut;
