import styled from 'styled-px2vw';
import {
  Button as AntdButton,
  Input as AntdInput,
  Row as AntdRow,
  Switch as AntdSwitch,
  Checkbox as AntdCheckbox,
} from 'antd'
import { DatePicker as AntdDatePicker, Picker as AntdPicker } from 'antd-mobile';

import beneficiaryGuidePng from '../../assets/images/beneficiary-guide.png';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DependentContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 53px;
`;

export const DescContent = styled.div<{ edit?: boolean }>`
  width: 100%;
  padding: 52px;
  padding-bottom: 0;
  white-space: pre-wrap;
  .title-description-text{
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    font-weight: bold;
  }
  .content-description-text{
    margin-top: 24px;
    font-size: 28px;
    line-height: 40px;
    color: #6A6A6A;
  }
`;

export const Button = styled(AntdButton)`
  height: 74px;
  width: 648px;
  border-radius: 48px;
  margin-top: 60px;
  background-color: #fff;
  margin-left: 51px;
  color: #0084FF;
  font-size: 32px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  border: 2px solid #0084FF;
  &:hover, &:active, &.am-button-active {
    text-decoration: none;
  }
  &.ant-btn:hover, .ant-btn:focus {
    color: #0084FF;
    background: #fff;
  }
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    color: #666666 !important;
    border-color: #F1F0F0 !important;
    background: #F1F0F0 !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
`;

export const Switch = styled(AntdSwitch) <{ existing_data?: number }>`
  margin: 30px 0 0 15px;
`;

export const SettlementContent = styled.div`
  width: 100%;
  background-color: #EFEFEF;
  padding: 40px 44px;
  .total-title-text {
    width: 150px;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
  }
  .total-value-text{
    width: 525px;
    /* margin-left: 180px; */
    font-weight: bold;
    font-size: 64px;
    line-height: 40px;
    text-align: right;
    color: #000000;

    .value-text {
      font-size: 28px;
      font-weight: normal;
    }
  }
`;

export const SettlementItemContent = styled.div`
  margin-bottom: 8px;
  .title-text {
    width: 466px;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
  }
  .value-text{
    width: 192px;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    text-align: right;
    color: #000000;
  }
`;

export const DependentItemContent = styled.div<{ addDependent?: number }>`
  width: 646px;
  /* height:350px; */
  background-color: #F1F1F1;
  border-radius: 16px;
  margin-top: 51px;
  padding: 30.5px 40.5px 30.5px 0;
  .title-text {
    width: 332px;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-left: 34.5px;
  }
  .remove-text {
    width: 110px;
    font-size: 24px;
    line-height: 40px;
    color: #0084FF;
    font-weight: bold;
    margin-left: 30px;
  }
  .edit-text {
    visibility:${props => (props.addDependent === 0 ? 'hidden' : 'visible')};
    font-size: 24px;
    line-height: 40px;
    color: #0084FF;
    font-weight: bold;
    margin-left: 90px;
  }
  .sub-title-text {
    height: 44px;
    width: 220px;
    font-size: 28px;
    line-height: 44px;
    color: #000000;
    margin-left: 41.76px;
  }
  .sub-value-text{
    width: 344px;
    font-size: 28px;
    line-height: 44px;
    text-align: right;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
  }

`;

export const EditDependentItemContent = styled.div`
  width: 646px;
  background-color: #F1F1F1;
  border-radius: 16px;
  padding: 32px;

  .title-text {
    width: 332px;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
  }

  .remove-text {
    width: 110px;
    font-size: 24px;
    line-height: 40px;
    color: #0084FF;
    font-weight: bold;
    margin-left: 150px;
  }

  .input-title-assist {
    margin-bottom: 20px;
  }

  .input-title {
    margin-top: 30px;
    height: 44px;
    width: 256.4px;
    font-size: 28px;
    line-height: 44px;
    color: #000000;
    margin-left: 41.76px;
  }

  .input-switch-title {
    margin-top: 30px;
    height: 44px;
    font-size: 28px;
    line-height: 44px;
    color: #000000;
    margin-left: 70px;
  }

  .detail-title-text {
    margin-top: 32px;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
    transition: all .3s;

    .required {
      margin-left: 8px;
      color: red;
    }
  }
`;

export const Input = styled(AntdInput) <{ width?: number, phone?: number, error?: boolean }>`
  position: relative;
  display: block;
  width: ${props => `${2 * (props.width || 323)}px`};
  height: 88px;
  color: #000;
  background: #fff;
  font-weight: normal;
  margin-top: 20px;
  font-size: 28px;
  padding-left: 28px;
  line-height: 40px;
  box-sizing: border-box;
  outline: 0;
  padding: 0 ${props => (props.phone === 0 ? '0px' : '28px')} 0 ${props => (props.phone === 0 ? '0px' : '28px')};
  border: 2px solid #DCDCDC;
  border-radius: 8px;
  ${props => (props.error && 'border-color: #D50B42 !important;')}
  &::placeholder {
    color: #A0A0A0;
    font-size: 28px;
  }
  .ant-input-affix-wrapper-borderless{
    height: 88px;
  }
  &.ant-input:hover {
    border-color: #DCDCDC;
  }
  .ant-input-group-addon {
    border: 0;
    border-right: 2px solid #DCDCDC;
    background: none;
  }
  .ant-input {
    border: 0px;
    height: 84px;
  }
  .ant-input:focus, .ant-input-focused {
    border: 1px solid #DCDCDC;
  }
`;
export const AddedDependentContent = styled.div`
  display: flex;
  margin-left: 51px;
  flex-direction: column;
`;

export const EditDependentContent = styled.div<{ marginLeft?: number }>`
  margin-top: 48.3px;
  display: flex;
  margin-left: ${props => (props.marginLeft !== 0 ? `${props.marginLeft}px` : '0px')} ;
  flex-direction: column;
  margin-bottom: 0px;
  .addtional-text {
    font-size: 28px;
    width: 256.4px;
    line-height: 40px;
    margin-right: 89px;
    margin-top: 46px;
    color: #000000;
  }
  .PhP-text {
    font-size: 28px;
    line-height: 40px;
    margin-top: 46px;
    color: #000000;
    width: 256.4px;
    text-align: right;
  }
`;
export const ContactDetailsUnderscore = styled.div`
  border-top:1px solid #DCDCDC;
`;
export const ContactDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 19px;
  height: 1460px;
  width: 100%;
  .contact-title-text {
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 44px 60px 8px 35px;
  }
`;
export const DatePicker = styled(AntdDatePicker)`
  &.ant-picker {
    height: 88px;
    border-radius: 8px;
    margin-left: 35.24px;
  }
  .ant-picker-suffix {
    color: transparent;
  }

  &.ant-picker:hover, .ant-picker-focused{
    border-color: #DCDCDC;
    outline: none;
    box-shadow: 0 0 0 #fff;
  }
`;
export const Picker = styled(AntdPicker) <{}>`
`;
export const PickerContent = styled.div`
  width: 675px;
`;

export const MobileListItem = styled.div<{ disabled: boolean | undefined, error?: number }>`
  display: flex;
  height: 88px;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px;
  margin-left: 35.24px;
  color: #000;
  background: transparent;
  margin-top: 20px;
  font-size: 28px;
  box-sizing: border-box;
  outline: 0;
  margin-bottom: 0px;
  border-radius: 8px;
  text-align: left;
  border: 2px solid ${props => (props.error ? '#D50B42' : '#dfdfdf')};
  background-color: #fff;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-width: 6px;
    border-color: #000 #fff #fff;
    border-style: solid;
  }

`;
export const SelectDate = styled.div <{ disabled?: boolean, error?: number, selected?: boolean, }>`
  position: relative;
  display: block;
  width: 570px;
  height: 88px;
  color: #000;
  background: ${props => (props.disabled ? '#f5f5f5' : '#fff')} !important;
  margin-left: 35.24px;
  margin-bottom: 27px;
  font-size: 28px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid ${props => (props.error ? '#D50B42' : '#d9d9d9')};
  outline: 0;
  .date-text {
    color: ${props => (props.selected ? '#000000' : '#A0A0A0')};
    line-height: 80px;
    margin-left: 20px;
  }
`;
export const Row = styled(AntdRow) <{ margin_top?: number, marginBottom?: number, cannotRemove?: boolean }>`
  white-space: nowrap;
  flex-flow: row nowrap;
  /* flex-flow: null wrap; */
  margin-top: ${props => (props.margin_top !== 0 ? `${props.margin_top}px` : '0px')} ;
  margin-bottom: ${props => (props.marginBottom !== 0 ? `${props.marginBottom}px` : '0px')} ;
  ${props => props.cannotRemove ? 'justify-content: space-between;' : ''}
`;

export const EditButton = styled(AntdButton)`
  height: 65.4px;
  width: 580px;
  border-radius: 40px;
  background-color: #FCFCFC;
  color: #000;
  font-size: 24px;
  margin-top: 46px;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: none;
  &.ant-btn:focus {
    color: #000;
  }
  &.am-button.am-button-disabled {
    color: #fff;
    background: #DCDCDC;
  }
`;

export const ProceedButton = styled(AntdButton)`
  height: 96px;
  width: 648px;
  border-radius: 16px;
  background-color: #0084FF;
  color: #fff;
  font-size: 32px;
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: 51px;
  line-height: 80px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: none;
  &.ant-btn:not([disabled]):hover {
    color: #fff;
    background-color: #0084FF;
  }
  &.ant-btn:focus {
    color: #fff;
    background-color: #0084FF;
  }
`;

export const CheckboxWrap = styled.div<{ marginTop?: number }>`
  margin-top: ${props => `${props?.marginTop || 50}px`};
  margin-left: 50px;
  font-size: 24px;
  line-height: 32px;

  .ant-checkbox-wrapper {
    margin-top: -8px;
    vertical-align: top;
    .ant-checkbox {
      margin-right: 20px;

      .ant-checkbox-inner {
        width: 32px;
        height: 32px;
        &:after {
          width: 11.5px;
          height: 18px;
        }
      }
    }
  }

  .personal-data {
    width: 590px;
    display: inline-block;
  }

  .download-pdf {
    margin-left: 8px;
    color: #0084FF;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const BeneficiaryGuideWrap = styled.div`
  width: 750px;
  padding: 52px;
  border-top: 2px Solid #DCDCDC;
`;

export const BeneficiaryGuideImage = styled.div<{ isExpanded: boolean }>`
  width: 646px;
  height: ${props => `${2 * (props?.isExpanded ? 1005 : 102)}px`};
  overflow: hidden;
  background-image: url(${beneficiaryGuidePng});
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.5s;

  &::after {
    content: '';
    display: ${props => props?.isExpanded ? 'none' : 'block'};
    width: 646px;
    height: 80px;
    margin-top: 124px;
    background: linear-gradient(rgba(255, 255, 255, 0), #FFFFFF);
  }
`;

export const ViewBeneficiaryGuideButton = styled(AntdButton)`
  height: 74px;
  width: 380px;
  padding: 0;
  display: block;
  margin: 32px auto 0;
  border-radius: 0;
  color: #0084FF;
  font-size: 28px;
  font-weight: 700;
  border: 2px solid #0084FF;
`;

export const ViewBeneficiaryGuideTips = styled.div`
  margin-top: 36px;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
`;

export const TermsAndConditions = styled.div`
  width: 100%;
  height: 692px;
  overflow: auto;
`;

export const TermsAndConditionsContentWrapper = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 24px;
`;

export const TermsAndConditionsContentItem = styled.div<{ bgColor: string }>`
  width: 100%;
  padding: 42px;
  background: ${props => props.bgColor};
  .title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 24px;
    font-weight: 500;
  }
  .order-wrapper {
    display: flex;
    .order {
      min-width: 30px;
      margin-right: 12px;
      text-align: right;
      letter-spacing: -1px;
    }

    .order-text {
      width: 578px;
    }
  }
  .text-list-order-wrapper {
    .order {
      min-width: 50px;
    }

    .order-text {
      width: 558px;
    }
  }
`;

export const ModalCloseButton = styled(AntdButton)`
  display: block;
  height: 74px;
  width: 280px;
  padding: 0;
  margin: auto;
  margin-top: 32px;
  border-radius: 8px;
  color: #0084FF;
  font-size: 28px;
  font-weight: 700;
  border: 2px solid #0084FF;
`;

export const SearchButton = styled.div`
  height: 88px;
  padding: 0 28px;
  font-size: 28px;
  line-height: 88px;
  border: 2px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .picker-dropdown-icon {
    width: 28px;
    height: 28px;
    transform: rotate(-90deg);
  }

  .picker-clear-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 72px;
  }

  &.no-date-data {
    color: #A0A0A0;
  }
`;

export const SearchButtonContent = styled.div`
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MaximumContent = styled.div`
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
  a {
    text-decoration: none;
    color: #0084FF;
  }
`;

export const StyledCheckbox = styled(AntdCheckbox)`
  font-size: 28px;
  .ant-checkbox .ant-checkbox-inner {
    height: 28px;
    width: 28px;
  }
  .ant-checkbox-inner::after {
    height: 14px;
    width: 14px;
  }
`
