import styled from 'styled-px2vw';
import { Modal as AntdModal } from 'antd-mobile';

export const StyleModal = styled(AntdModal) <{ width: number, height: number, heightFitContent?: boolean  }>`
  &&.common-modal {
    width: ${props => `${2 * props.width}px`} !important;
    height: ${props => props.heightFitContent ? 'fit-content' : `${2 * props.height}px`} !important;
  }

  .am-modal-content {
    ${props => props.heightFitContent ? 'padding-bottom: 48px;' : ''}
    border-radius: 16px;
    .am-modal-body {
      overflow: hidden;
    }

    .close-svg {
      position: absolute;
      right: 24px;
      top: 24px;

      &.hide-svg {
        display: none;
      }
    }

    .not-show-close-svg {

    }
  }
`;

export const ModalContent = styled.div<{ modalWidth: number, modalContentWidth?: string, notShowCloseSvg?: boolean }>`
  width: ${props => `${2 * (props.modalWidth - 52)}px`};
  ${props => props.modalContentWidth && `width: ${props.modalContentWidth};`}
  margin: ${props => `${props?.notShowCloseSvg ? 36 : 72}px auto 0`};
  text-align: left;
  white-space: pre-wrap;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
`;
