import React, { FC } from 'react';
import { Toast } from 'antd-mobile';

import Button from '../../components/Button';
import { FailedWrap } from './style';
import { BottomButtonWrapper } from '../common/style';
import { getFailedMsg } from './config';
import { closeMessengerBrowser } from '../../utils';

interface IFailedProps {
  errorCode: any;
}

const FAILED_URL = 'https://www.bpi-aia.com.ph/en/bancassurance/financial-and-wellness-needs-analysis.html';

const Failed:FC<IFailedProps> = ({
  errorCode,
}) => {
  const close = () => {
    closeMessengerBrowser();
  };

  return (
    <>
      <FailedWrap>
        {`${getFailedMsg?.[errorCode || 1001]}`}
        <span className="error-link" onClick={() => window.open(FAILED_URL)}>
          {FAILED_URL}
        </span>
      </FailedWrap>
      <BottomButtonWrapper>
        <Button onClick={close}>
          OK
        </Button>
      </BottomButtonWrapper>
    </>
  );
};

export default Failed;
