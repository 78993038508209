import React from 'react';

export interface IUserInfo {
}

export interface DependentData {
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  relationShip: number;
  // placeOfBirth: string;
  gender: string;
  amount: string;
  suffix: string;
  date: Date;
  edit?: boolean;
}
interface IInitState {
  progress: number;
  loading: boolean;
  orderId: string;
  psid: string;
  timeStamp: number;
  startTimeStamp: string;
  dependentDatas: DependentData[];
  checkoutBasicInfo: {
    idType: string,
    idNo: string,
    idExpiryDate: string,
    // placeOfBirth: any,
    // addressLine1: any,
    // addressLine2: any,
    // province: any,
    // city: any,
    // district: any,
    // zipCode: any,
    emailAddress: string,
    maritalStatus: string,
  };
  checkoutData: Record<string, any>;
  totalAmount: number;
}

export const initialState: IInitState = {
  progress: 0,
  loading: false,
  orderId: '',
  psid: '',
  timeStamp: 0,
  startTimeStamp: '',
  dependentDatas: [
    //   {
    //   name: 'string',
    //   dob: 'string',
    //   relationShip: 'string',
    // }
  ],
  checkoutBasicInfo: {
    idType: '',
    idNo: '',
    idExpiryDate: '',
    // placeOfBirth: '',
    // addressLine1: '',
    // addressLine2: '',
    // province: '',
    // city: '',
    // district: '',
    // zipCode: '',
    emailAddress: '',
    maritalStatus: '',
  },
  checkoutData: {},
  totalAmount: 0,
};

export const reducer = (state: IInitState, action: { type: string, [propsName: string]: any }) => {
  switch (action.type) {
    case 'progress':
      return { ...state, progress: action.progress };
    case 'loading':
      return { ...state, loading: action.loading };
    case 'setOrderId':
      return { ...state, orderId: action.orderId };
    case 'setPsid':
      return { ...state, psid: action.psid };
    case 'setTimeStamp':
      return { ...state, timeStamp: action.timeStamp ,startTimeStamp: action.startTimeStamp};
    case 'addDependent':
      return { ...state, dependentDatas: action.dependentDatas };
    case 'setCheckoutBasicInfo':
      return { ...state, checkoutBasicInfo: action.checkoutBasicInfo };
    case 'setCheckoutData':
      return { ...state, checkoutData: action.checkoutData };
    case 'setTotalAmount':
      return { ...state, totalAmount: action.totalAmount };
  }
};

export const Context = React.createContext({ store: initialState, dispatch: (payload: any) => { } });
