import React, { FC } from 'react'
import styled from 'styled-px2vw'
import { ProceedButton } from '../CheckOut/style'
import friendlyReminderIconSrc from '../../assets/images/friendly-reminder-icon.png';
import { useHistory } from 'react-router-dom';
import { getQueryVariable } from '../../utils';

const FriendlyReminderWrapper = styled.div``

const FriendlyReminderIcon = styled.img`
  display: block;
  width: 276px;
  margin: 0 auto;
`

const FriendlyReminderTitle = styled.div`
  text-align: center;
  height: 40px;
  font-weight: 700;
  font-size: 52px;
  line-height: 40px;
  color: #568f63;
  margin-top: 40px;
`

const FriendlyReminderTip = styled.div`
  margin: 0 auto;
  margin-top: 40px;
  width: 580px;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #202020;
`

const FriendlyReminderSteps = styled.div`
  margin: 40px auto;
  width: 580px;
`

const FriendlyReminderStepRow = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color: #202020;
`

const BPILink = styled.a`
  color: #0084ff;
  text-decoration: none;
`

const CurProceedButton = styled(ProceedButton)`
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 50px;
`

const PlaceholderBlock = styled.div`
  height: 146px;
`

const FriendlyReminder: FC = () => {
  const history = useHistory();

  const urlOrderId = getQueryVariable('OrderId');

  const goPaymentPage = () => {
    history.push(`Payment?OrderId=${urlOrderId}`);
  }

  return (
    <FriendlyReminderWrapper>
      <FriendlyReminderIcon src={friendlyReminderIconSrc} />
      <FriendlyReminderTitle>Friendly Reminder!</FriendlyReminderTitle>

      <FriendlyReminderTip>
        To ensure a smooth payment transaction, please enable your online paymentby following these 6 easy steps:
      </FriendlyReminderTip>

      <FriendlyReminderSteps>
        <FriendlyReminderStepRow>
          1. Go to BPI Online (<BPILink href="https://www.bpi.com.ph/online"  target="_blank"  rel="noopener noreferrer">Online</BPILink> | <BPILink>BPI</BPILink>) or BPI Mobile App
        </FriendlyReminderStepRow>
        <FriendlyReminderStepRow>2. Log-in your account</FriendlyReminderStepRow>
        <FriendlyReminderStepRow>3. Check menu and select “Other Services”</FriendlyReminderStepRow>
        <FriendlyReminderStepRow>
          4. Select “Card Control” and select the card that you will use for the online payment.
        </FriendlyReminderStepRow>
        <FriendlyReminderStepRow>5. Enable the “E-Commerce access”</FriendlyReminderStepRow>
        <FriendlyReminderStepRow>6. Enter your one-time-pin</FriendlyReminderStepRow>
      </FriendlyReminderSteps>
      <PlaceholderBlock />
      <CurProceedButton onClick={goPaymentPage}>CONTINUE</CurProceedButton>
    </FriendlyReminderWrapper>
  )
}

export default FriendlyReminder
