import React, { useContext } from 'react';
import { Toast } from 'antd-mobile';

import { Content, CreditContent, Row } from './style';
import { Context } from '../../store';
import PayLogo from './pay.svg';
import VISALogo from './VISA.svg';
import { PaymentParameters } from '../../api';
import { getQueryVariable } from '../../utils';

const Payment = () => {
  document.title = 'PAYMENT';
  const { store: { orderId } } = useContext(Context);
  const urlOrderId = getQueryVariable('OrderId');
  const closeLoading = () => {
    Toast.hide();
  };

  const openLoading = () => {
    Toast.loading('loading...', 0, undefined, true);
  };

  const toastErr = () => {
    closeLoading();
    Toast.fail('There\'s something wrong with the server.');
  };

  const paymentParameters = () => {
    openLoading();
    PaymentParameters({
      PolicyOrderId: orderId || urlOrderId || '',
      // PaySuccessRedirectUrl: `${window.location.origin}/PaySuccess?id=${orderId || urlOrderId}`,
    }).then(res => {
      if (res) {
        closeLoading();
        window.location.href = res;
      } else {
        toastErr();
      }
    }).catch(toastErr);
  };

  return (
    <Content>
      <div className="select-text">Select a Payment Method</div>
      <CreditContent onClick={paymentParameters}>
        <Row>
          <div className="button-text">Credit / Debit Card</div>
          <img src={PayLogo} alt="" />
          <img src={VISALogo} alt="" style={{ marginLeft: '7.93px' }} />
        </Row>
      </CreditContent>
    </Content>
  );
};

export default Payment;
