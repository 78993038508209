import React, { FC } from 'react';

import { Modal } from '../../../components';
import { ModalCloseButton, TermsAndConditions, TermsAndConditionsContentWrapper, TermsAndConditionsContentItem } from '../style';
import TERMS_AND_CONDITIONS_CONFIG from './config';

interface ITermsAndConditionsModal {
  visible: boolean;
  onClose: () => void;
}
const TermsAndConditionsModal:FC<ITermsAndConditionsModal> = ({
  visible,
  onClose,
}) => (
  <Modal
    width={356}
    modalContentWidth="100%"
    visible={visible}
    onClose={onClose}
    notShowCloseSvg
  >
    <TermsAndConditions>
      <TermsAndConditionsContentWrapper>
        {
          TERMS_AND_CONDITIONS_CONFIG.map(({ src, content }) => (
            <div key={src}>
              <img width="100%" alt="" src={src} />
              {
                content?.map((contentItem, contentItemIndex) => (
                  <TermsAndConditionsContentItem
                    bgColor={contentItemIndex % 2 === 0 ? '#ccdce9' : '#e6e4ed'}
                    key={`${contentItem?.order || '-'}-${contentItem?.text || '-'}-${contentItem?.title || '-'}-${contentItemIndex.toString()}`}
                  >
                    {contentItem?.title ? <div className="title">{contentItem.title}</div> : null}
                    {contentItem?.order ? (
                      <div className="order-wrapper">
                        <div className="order">{contentItem.order}</div>
                        <div className="order-text">{contentItem.text}</div>
                      </div>
                    ) : (
                      contentItem?.text ? <div className="order-text">{contentItem.text}</div> : null
                    )}
                    {contentItem?.desc ? <div>{contentItem.desc}</div> : null}
                    {contentItem?.textList ? (
                      contentItem.textList.map((textListItem: any) => (
                        <div
                          className={`order-wrapper ${contentItem?.text && contentItem?.order ? 'text-list-order-wrapper' : ''}`}
                          key={textListItem.text}
                        >
                          {textListItem?.order ? <div className="order">{textListItem.order}</div> : null}
                          <div className="order-text">{textListItem.text}</div>
                        </div>
                      ))
                    ) : null}
                  </TermsAndConditionsContentItem>
                ))
              }
            </div>
          ))
        }
      </TermsAndConditionsContentWrapper>
      {/*<img width="100%" alt="" src={termsAndConditions} />*/}
      <ModalCloseButton onClick={onClose}>
        Close
      </ModalCloseButton>
    </TermsAndConditions>
  </Modal>
);

export default TermsAndConditionsModal;
