/**
 * 2022年1月7日下午四点
 * 得到的新需求，需要将原本的代码改回 OTP
 * 但是跟第一版有所不同：
 * · 这次的验证码由原本的六位改到了四位，
 * · 并且将 NEXT 和 Resend code 的倒计时做了一个统一
 */
import React, { useImperativeHandle, Dispatch, FC, SetStateAction, useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { cloneDeep } from '@apollo/client/utilities';
import { Toast } from 'antd-mobile';
import Button from '../../components/Button';
import {
  PageTipTitle,
  TitleDescription,
  DetailsContent, BottomButtonWrapper,
  ReferralCodesTitle
} from '../common/style';
import { FIELD_NAME_MAP, INFO_DETAILS_MAP_FIELDS, MOBILE_CODE } from './config';
import { closeLoading, closeMessengerBrowser, isEmptyField, openLoading } from '../../utils';
import InputField from './InputField';
import { Row } from '../CheckOut/style';
// import { CountDown } from './style';
import { backToInfosPage, getOTPVerificationCode, verificationOTPCode } from '../../api';
import VerifyMobileNumber from './VerifyMobileNumber';
import { Modal } from '../../components';
import SearchBranch from './SearchBranch';

interface IInfoProps {
  pageId: string;
  setPageId: React.Dispatch<React.SetStateAction<string>>;
  infoDetails: Record<string, any>;
  onChangeInfoDetails: (dataKey: string, value: any) => void;
  onInfoNext: () => void;
  noMiddleName?: boolean;
  setNoMiddleName?: Dispatch<SetStateAction<boolean>>;
  userContext: string;
  ref?: React.Ref<{
    setDeadLine: (resCountDown: number) => void;
  }>;
  disableInfoInput?: string[];
  confirmErrorCode?: number | null;
  setConfirmErrorCode?: (code: number | null) => void;
  branchCodeOptions: {label: string, value: string}[];
  inSummaryPage?: boolean
}

const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));

const checkReferredKeyArr:string[] = ['bankReferrerCode', 'bse'];

const errorCodeCtrlEnum: Record<number, string[]> = {
  4012: ['bse', 'bankReferrerCode'],
  4010: ['bse'],
  4011: ['bankReferrerCode']
}

const VCodeInfoDetails:FC<IInfoProps> = React.forwardRef(({
  pageId,
  setPageId,
  infoDetails,
  onChangeInfoDetails,
  onInfoNext,
  userContext,
  disableInfoInput,
  confirmErrorCode,
  setConfirmErrorCode,
  branchCodeOptions,
  inSummaryPage,
}, ref) => {
  const [mobileReEnter, setMobileReEnter] = useState(false);
  const [errorState, setErrorState] = useState<Record<string, boolean>>({
    firstName: false,
    middleName: false,
    lastName: false,
    birthday: false,
    gender: false,
    // occupation: false,
    mobileNumber: false,
    suffix: false,
    bankReferrerCode: false,
    bse: false
  });

  const [countDown, setCountDown] = useState<number>(0);
  const [deadLine, setDeadLine] = useState<number>(0);
  const [mobileNumber, setMobileNumber] = useState('');
  const [otpVCode, setOTPVCode] = useState('');
  const [sendError, setSendError] = useState<boolean>(false);
  const [showInputInfoModal, setShowInputInfoModal] = useState<boolean>(false);
  const [inputInfoContent, setInputInfoContent] = useState<string>('')
  const [showSearchBranch, setShowSearchBranch] = useState<boolean>(false);

  const curCountDown = useRef(0);

  useEffect(() => {
    if (deadLine) {
      let isNotExpired = true;
      const run = async () => {
        do {
          const expiredSecond = curCountDown.current;
          curCountDown.current = expiredSecond - 1;
          if (expiredSecond <= 0) {
            isNotExpired = false;
            setDeadLine(0);
            setCountDown(0);
          } else {
            setCountDown(expiredSecond);
          }
          await sleep(1000);
        } while (isNotExpired);
      }
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadLine]);

  useEffect(() => {
    setMobileNumber(infoDetails?.mobileNumber || '');
  }, [infoDetails]);

  const handleChangeInfoDetails = (dataKey: string, value: any) => {
    if (dataKey === 'mobileNumber') {
      setMobileReEnter(false);
    }
    if (checkReferredKeyArr.includes(dataKey) && confirmErrorCode && value !== infoDetails[dataKey]) {
      setErrorState(prevState => {
        const data = {
          ...prevState,
          [dataKey]: false,
        }
        if (!data.bankReferrerCode && !data.bse) {
          setConfirmErrorCode?.(null);
        }
        return data;
      });
    }
    onChangeInfoDetails(dataKey, value);
  };

  const handleErrorState = (dataKey: string, state: boolean) => {
    if (!checkReferredKeyArr.includes(dataKey)) {
      setErrorState(prevState => ({
        ...prevState,
        [dataKey]: state,
      }));
    }
  };

  const close = () => {
    closeMessengerBrowser();
  };

  const onBack = () => {
    openLoading();
    backToInfosPage({
      userContext,
      pageId: 'infos',
    }).then(res => {
      closeLoading();
      if (res?.code === 0) {
        setPageId('infos');
        setOTPVCode('');
        setSendError(false);
        setMobileReEnter(true);
      }
    }).catch(() => {
      closeLoading();
      Toast.fail('There\'s something wrong with the server.');
    });
  };

  const setDeadLineStart = (resCountDown: number) => {
    setDeadLine(resCountDown);
    curCountDown.current = resCountDown;
  };

  useImperativeHandle(ref, () => ({
    setDeadLine: setDeadLineStart,
  }));

  const onChangeCountDown = (val: any) => {
    if (val < 1000) {
      setDeadLine(0);
    }
  };

  const onChange = (val: any) => {
    setOTPVCode(val);
    setSendError(false);
  };

  const sendSMSCode = () => {
    setOTPVCode('');
    openLoading();
    getOTPVerificationCode({
      userContext,
    }).then(res => {
      closeLoading();
      if (res.code === 0) {
        setPageId('otp');
        setDeadLineStart(res.data?.countDown);
      }
    }).catch(() => {
      closeLoading();
      Toast.fail('There\'s something wrong with the server.');
    });
  };

  const verificationCode = () => {
    openLoading();
    verificationOTPCode({
      userContext,
      verifyCode: otpVCode,
    }).then(res => {
      closeLoading();
      const vCodeFailed = res.code !== 0;
      setSendError(vCodeFailed);
      setOTPVCode('');
      if (!vCodeFailed) {
        close();
      }
    }).catch(() => {
      closeLoading();
      Toast.fail('There\'s something wrong with the server.');
    });
  };

  const nextDisabled = useMemo(() => {
    const checkParams = cloneDeep(infoDetails);
    delete checkParams.middleName;
    delete checkParams.suffix;
    delete checkParams.bse;
    delete checkParams.bankReferrerCode;
    delete checkParams.branchCode;

    const curErrorState = cloneDeep(errorState);

    if (!infoDetails.isReferrer) {
      delete curErrorState.bankReferrerCode
      delete curErrorState.bse
    }

    return isEmptyField(checkParams) || checkParams.mobileNumber.length !== 10 || isEmptyField(curErrorState, 'boolean') || !!countDown || checkParams.isReferrer === undefined;
  }, [infoDetails, errorState, countDown]);

  const curPageText = useMemo(() => (
    MOBILE_CODE(mobileNumber)?.[pageId]
  ), [mobileNumber, pageId]);

  const sendDisabled: Record<string, any> = useMemo(() => {
    const commonError = !mobileNumber || mobileNumber.length !== 10;
    return {
      confirm: commonError || countDown,
      otp: otpVCode.length !== 4,
    };
  }, [mobileNumber, countDown, otpVCode]);

  const infoInputsConfig = useMemo(() => {
    const newConfig = cloneDeep(INFO_DETAILS_MAP_FIELDS)
    const middleNameFieldIndex = INFO_DETAILS_MAP_FIELDS.findIndex((el) => el.fieldName === 'middleName')
    if (infoDetails.noMiddleName) {
      newConfig.splice(middleNameFieldIndex, 1)
    }
    if (!infoDetails.isReferrer) {
      newConfig.splice(2, 2)
    } else if (infoDetails.bse || infoDetails.bankReferrerCode) {
      let errorMsg = 'Please fill in 9-digit code.'
    if (confirmErrorCode) {
      if (errorCodeCtrlEnum[confirmErrorCode]) {
        errorMsg = 'Invalid code.Please request assistance from your Bancassurance Sales Executive.'
      }
    }
    newConfig[2].errMsg = newConfig[3].errMsg = errorMsg
   }
   if (branchCodeOptions.length) {
    newConfig[0].option = branchCodeOptions 
   }
   return newConfig
  }, [infoDetails, confirmErrorCode, branchCodeOptions])


  useEffect(() => {
    if (confirmErrorCode) {
      const keys = errorCodeCtrlEnum[confirmErrorCode]
      if (keys) {
        setErrorState(data => {
          const curData = cloneDeep(data)
          keys.forEach(key => {
            curData[key] = true
          })
          return curData
        })
        const dom = document.getElementById(`input-${keys[0]}`);
        dom?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [confirmErrorCode]);

  useEffect(() => {
    const flag = infoDetails.isReferrer && !infoDetails.bse && !infoDetails.bankReferrerCode
    setErrorState((data) => {
      const curData = cloneDeep(data)
      if (flag || !infoDetails.isReferrer) {
        curData.bankReferrerCode = curData.bse = flag
      } else if (!confirmErrorCode){
          checkReferredKeyArr.forEach(key => {
          curData[key] = infoDetails[key] && !/^(\d{9})$/.test(infoDetails[key])
        })
      }
      return curData
    })
  }, [infoDetails, confirmErrorCode])


  const onClickInfoIcon = (info:string) => {
    setInputInfoContent(info);
    setShowInputInfoModal(true);
  }

  const closeSearchBranch = useCallback(() => {
    setShowSearchBranch(false);
  }, [])

  const onSelectBranch = useCallback((branch?: { name: string, code: string }) => {
    onChangeInfoDetails('branchCode', branch ? branch.code : '');
    closeSearchBranch();
    // eslint-disable-next-line
  }, [closeSearchBranch])

  const infoButtonText = useMemo(() => {
    let text = countDown ? `${countDown}s` : 'NEXT'
    if (inSummaryPage) {
      text = 'SAVE'
    }
    return text
  }, [countDown, inSummaryPage])

  return (
    <>
      {
        !inSummaryPage && (
          <>
            <PageTipTitle>{curPageText?.title}</PageTipTitle>
            <TitleDescription>{curPageText?.desc}</TitleDescription>
            {pageId === 'infos' && <ReferralCodesTitle>Referral Codes</ReferralCodesTitle>}
          </>
        )
      }
      <Modal
        visible={showInputInfoModal}
        heightFitContent
        onClose={() => setShowInputInfoModal(false)}
      >
        {inputInfoContent}
      </Modal>
      <SearchBranch
        visible={showSearchBranch}
        branchCodeOptions={branchCodeOptions}
        onSelectBranch={onSelectBranch}
        onCancel={closeSearchBranch}
      />
      <DetailsContent inSummaryPage={inSummaryPage}>
        {
          pageId === 'infos' ? (
            infoInputsConfig.map(item => (
              <InputField
                {...item}
                key={item.fieldName}
                id={`input-${item.fieldName}`}
                disabled={disableInfoInput?.includes(item.fieldName) || (inSummaryPage && item.fieldName === 'mobileNumber')}
                details={infoDetails}
                onChangeDetails={handleChangeInfoDetails}
                filedNameMap={FIELD_NAME_MAP}
                setErrorState={handleErrorState}
                errorState={!!errorState?.[item.fieldName]}
                showOtherError={item.fieldName === 'mobileNumber' ? mobileReEnter : false}
                hideErrorIcon={['bse', 'bankReferrerCode'].includes(item.fieldName)}
                onClickInfoIcon={onClickInfoIcon}
                onClick={item.type === 'searchButton' ? () => setShowSearchBranch(true) : undefined}
              />
            ))
          ) : (
            <VerifyMobileNumber
              mobileNumber={mobileNumber}
              vCode={otpVCode}
              pageId={pageId}
              onChange={onChange}
              countDown={countDown}
              sendSMSCode={sendSMSCode}
              sendError={sendError}
              onChangeCountDown={onChangeCountDown}
            />
          )
        }
      </DetailsContent>
      {
        pageId === 'infos' ? (
          <Button
            onClick={onInfoNext}
            types={nextDisabled ? 'disabled' : 'primary'}
          >
            {infoButtonText}
          </Button>
        ) : (
          <BottomButtonWrapper>
            <Row>
              <Button
                width={80}
                types="ordinary"
                onClick={onBack}
              >
                BACK
              </Button>
              <Button
                width={232}
                margin="margin-left: 22px;"
                types={sendDisabled?.[pageId] ? 'disabled' : 'primary'}
                onClick={verificationCode}
              >
                NEXT
              </Button>
            </Row>
          </BottomButtonWrapper>
        )
      }
    </>
  );
});

export default VCodeInfoDetails;
