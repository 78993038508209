import React, { FC, useMemo } from 'react'
import styled from 'styled-px2vw'
import {RELATIONSHIP_DATA} from '../../utils'

const BenefitViewWrapper = styled.div<{isExpanded?: boolean}>`
  transition: all .3s;
  overflow: hidden;
  max-height: ${props => props.isExpanded ? '3400px' : '120px'} ;
`;

const BenefitViewRow = styled.div`
  display: flex;
  align-items: center;
`;

const BenefitViewTitleItem = styled.div`
  font-size: 24px;
  white-space: nowrap;
  font-weight: 600;
  color: #000;
  flex: 1;
`;

const BenefitViewItem = styled.div`
  font-size: 20px;
  margin-top: 20px;
  flex: 1;
  padding-right: 20px;
  line-height: 30px;
`

const BenefitViewListItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #DCDCDC;
  }
`

const BenefitViewTitle = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 36px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-bottom: 32px;
`

const MemberTypeTitle = styled.div`
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 600;
`

const BenefitEnum: Record<string, any> = {
  'PLAN 1M': {
    1: '1,000,000.00',
    2: '1,000,000.00',
    3: '1,000,000.00',
    4: '1,000,000.00',
    5: '1,000,000.00'
  },
  'PLAN 2M': {
    1: '2,000,000.00',
    2: '2,000,000.00',
    3: '2,000,000.00',
    4: '2,000,000.00',
    5: '2,000,000.00'
  }
}

const BenefitView: FC<{ isExpanded?: boolean, dependentDatas: any[], plan?: string }> = ({isExpanded, dependentDatas, plan = 'PLAN 1M'}) => {
 
  const curBenfitEnum = useMemo(() => (BenefitEnum[plan]), [plan])
  return (
    <BenefitViewWrapper isExpanded={isExpanded}>
      <BenefitViewTitle>SCHEDULE OF INSURANCE</BenefitViewTitle>
      <BenefitViewListItem>
        <MemberTypeTitle>Member Type: Insured-Main</MemberTypeTitle>
        <BenefitViewRow>
          <BenefitViewTitleItem>Benefit</BenefitViewTitleItem>
          <BenefitViewTitleItem>Amount of Insurance</BenefitViewTitleItem>
        </BenefitViewRow>
        <BenefitViewRow>
          <BenefitViewItem>Life Insurance Benefit</BenefitViewItem>
          <BenefitViewItem>Php {curBenfitEnum[1]}</BenefitViewItem>
        </BenefitViewRow>
        <BenefitViewRow>
          <BenefitViewItem>Accidental Death,Dismemberment and Disablement Benefit</BenefitViewItem>
          <BenefitViewItem>Php {curBenfitEnum[1]}</BenefitViewItem>
        </BenefitViewRow>
        <BenefitViewRow>
          <BenefitViewItem>Hospital Income Benefit</BenefitViewItem>
          <BenefitViewItem>Php 1,000.00</BenefitViewItem>
        </BenefitViewRow>
      </BenefitViewListItem>
      {
        dependentDatas.map(dependentItem => (
          <BenefitViewListItem>
            <MemberTypeTitle>Member Type: Insured-{RELATIONSHIP_DATA[dependentItem.relationShip]}</MemberTypeTitle>
            <BenefitViewRow>
              <BenefitViewTitleItem>Benefit</BenefitViewTitleItem>
              <BenefitViewTitleItem>Amount of Insurance</BenefitViewTitleItem>
            </BenefitViewRow>
            <BenefitViewRow>
              <BenefitViewItem>Life Insurance Benefit</BenefitViewItem>
              <BenefitViewItem>Php {curBenfitEnum[dependentItem.relationShip]}</BenefitViewItem>
            </BenefitViewRow>
            <BenefitViewRow>
              <BenefitViewItem>Accidental Death,Dismemberment and Disablement Benefit</BenefitViewItem>
              <BenefitViewItem>Php {curBenfitEnum[dependentItem.relationShip]}</BenefitViewItem>
            </BenefitViewRow>
          </BenefitViewListItem>
        ))
      }
    </BenefitViewWrapper>
  )
}

export default BenefitView
