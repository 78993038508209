import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import moment from 'moment-timezone';

import Button from '../../components/Button';
import { ErrorMessage, TitleDescription, StyleSelect } from '../common/style';
import { DateOfBirthWrap } from './style';
import { FIELD_NAME_MAP, formatDate } from './config';
import {
  DatePicker,
  Input,
  Picker,
  Row,
  SearchButton,
  SearchButtonContent,
  StyledCheckbox,
  // Switch,
} from '../CheckOut/style';
import { ReactComponent as DropdownSvg } from '../../assets/images/dropdown-icon.svg';
import { ReactComponent as ClearSvg } from '../../assets/images/clear.svg';
import { ReactComponent as ErrorSvg } from '../../assets/images/error.svg';
import { checkStringIsCoherent } from '../../utils/check-params';
import { ReactComponent as QuestionCircle } from '../../assets/images/question-circle.svg';

export interface IFieldProps {
  width?: number;
  fieldName: string;
  type: string;
  option?: {
    label: string,
    value: any,
  }[];
  tip?: string;
  showIcon?: boolean;
  phone?: number;
  maxLength?: number;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  addonBefore?: string;
  minDate?: Date;
  maxDate?: Date;
  optional?: boolean;
  rule?: RegExp;
  hasOtherRule?: RegExp;
  errMsg?: string;
  errorState?: boolean;
  setErrorState?: (dataKey: string, state: boolean) => void;
  disabled?: boolean;
  checkIsCoherent?: boolean;
  placeholder?: string;
  showOtherError?: boolean;
  otherErrMsg?: string;
  clear?: boolean;
  info?: string;
}

interface InputFieldProps extends IFieldProps {
  details: Record<string, any>;
  onChangeDetails: (dataKey: string, value: any) => void;
  noMiddleName?: boolean;
  onChangeMiddleName?: (state: boolean) => void;
  filedNameMap: Record<string, string>;
  hideErrorIcon?: boolean;
  onClickInfoIcon?: (info:string) => void;
  onClick?: () => void;
  id?: string;
}

const InputField:FC<InputFieldProps> = ({
  details,
  fieldName,
  onChangeDetails,
  // noMiddleName,
  // onChangeMiddleName,
  filedNameMap,
  type,
  option,
  tip,
  showIcon,
  phone,
  maxLength,
  inputMode,
  addonBefore,
  minDate,
  maxDate,
  optional,
  rule,
  errMsg,
  errorState,
  setErrorState,
  disabled,
  checkIsCoherent,
  width,
  placeholder,
  showOtherError,
  otherErrMsg,
  clear,
  hideErrorIcon,
  info,
  onClickInfoIcon,
  onClick,
  id
}) => {
  const selectRef = useRef<any>(null);
  const selectIsFocus = useRef<boolean>(false);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const isMobileNumber = useMemo(() => (type === 'telephone'), [type]);

  const onBlurFocus = (state: boolean) => {
    selectIsFocus.current = state;
    setDefaultOpen(state);
    if (state) {
      selectRef.current?.focus();
    } else {
      selectRef.current?.blur();
    }
  };

  const onChange = useCallback((dataKey: string, value: any) => {
    onBlurFocus(false);
    onChangeDetails(dataKey, value);
    if (rule) {
      let error = value && !rule.test(value);
      if (checkIsCoherent) {
        error = value && (!rule.test(value) || checkStringIsCoherent(value));
      }
      setErrorState?.(dataKey, !!error);
    }
  }, [onChangeDetails, setErrorState, rule, checkIsCoherent]);

  const filterOption = (input: any, options: any) => {
    const types = typeof options?.label === 'string';
    const lower = types ? options?.label : options?.children?.props?.id;
    return lower?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const field = useMemo(() => {
    const onClear = (e: any) => {
      e.stopPropagation();
      if (details?.[fieldName]) {
        onChange(fieldName, '');
      }
    };

    const activeOption = details?.[fieldName]
      ? option?.find(item => item.value === details?.[fieldName])?.label
      : 'Select one';

    switch (type) {
      case 'select':
        return (
          <StyleSelect
            width={width}
            ref={selectRef}
            options={option || []}
            value={details?.[fieldName] ? details?.[fieldName] : null}
            onChange={val => onChange(fieldName, val)}
            disabled={disabled}
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={filterOption}
            open={defaultOpen}
            onFocus={() => {
              selectIsFocus.current = true;
            }}
            onClick={() => onBlurFocus(selectIsFocus.current)}
            onBlur={() => onBlurFocus(false)}
            getPopupContainer={triggerDom => triggerDom}
          />
        );
      case 'picker':
        return (
          <Picker
            data={option || []}
            cols={1}
            okText="OK"
            dismissText="CANCEL"
            value={[details?.[fieldName]]}
            onOk={val => onChange(fieldName, val[0])}
            disabled={disabled}
          >
            <DateOfBirthWrap
              width={width}
              className={details?.[fieldName] ? '' : 'no-date-data'}
              showIcon={!!showIcon}
              disabled={disabled}
            >
              {activeOption}
              {clear && !!details?.[fieldName] ? <ClearSvg onClick={onClear} className="picker-clear-icon" /> : null}
              <DropdownSvg className="picker-dropdown-icon" />
            </DateOfBirthWrap>
          </Picker>
        );
      case 'searchButton':
          return (
            <SearchButton onClick={onClick}>
              <SearchButtonContent>{activeOption}</SearchButtonContent>
              {clear && !!details?.[fieldName] ? <ClearSvg onClick={onClear} className="picker-clear-icon" /> : null}
              <DropdownSvg className="picker-dropdown-icon" />
            </SearchButton>
          )
      case 'radioButton':
        return (
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            {
              option?.map(({ value, label }, index) => (
                <Button
                  types={details?.[fieldName] === value ? 'secondary' : 'ordinary'}
                  active={details?.[fieldName] === value}
                  width={width || 156}
                  disabled={disabled}
                  onClick={() => onChange(fieldName, value)}
                  margin={index === option.length - 1 ? 'margin-left: 20px;' : ''}
                >
                  {label}
                </Button>
              ))
            }
          </Row>
        );
      case 'datePicker':
        return (
          <DatePicker
            locale={enUs}
            mode="date"
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date: Date) => onChange(fieldName, formatDate(date))}
            okText="OK"
            dismissText="CANCEL"
            extra="YYYY/MM/DD"
            value={details?.[fieldName] ? new Date(details?.[fieldName]) : undefined}
            disabled={disabled}
          >
            <DateOfBirthWrap
              className={details?.[fieldName] ? '' : 'no-date-data'}
              disabled={disabled}
              width={width}
            >
              {details?.[fieldName] ? moment(new Date(details?.[fieldName])).utc().format('YYYY/MM/DD') : 'YYYY/MM/DD'}
              {clear && !!details?.[fieldName] ? <ClearSvg onClick={onClear} className="picker-clear-icon" /> : null}
            </DateOfBirthWrap>
          </DatePicker>
        );
      case 'checkbox':
        return (
          <StyledCheckbox checked={details?.[fieldName]} onChange={({ target }) => onChange(fieldName, target.checked)}>
            {FIELD_NAME_MAP[fieldName]}
          </StyledCheckbox>
        )
      default:
        return (
          <Input
            value={details?.[fieldName]}
            onChange={e => onChange(fieldName, isMobileNumber ? e.target.value.replace(/[^\d]/g, '') : e.target.value)}
            width={width}
            onBlur={e => onChange(fieldName, e.target.value.trim())}
            disabled={disabled}
            phone={phone || phone === 0 ? phone : 1}
            maxLength={maxLength || 40}
            inputMode={inputMode || 'text'}
            addonBefore={addonBefore}
            error={errorState}
            placeholder={placeholder}
          />
        );
    }
  }, [fieldName, details, isMobileNumber, option, addonBefore, inputMode, minDate, maxDate, maxLength, phone, showIcon, type, errorState, onChange, disabled, defaultOpen, width, placeholder, clear, onClick]);

  return (
    <>
      {/* {
        fieldName === 'middleName' ? (
          <Row>
            <div className={`detail-title-text ${noMiddleName ? 'disabled' : ''}`}>Middle Name</div>
            <div className="switch-text">No Middle Name</div>
            <Switch
              checked={noMiddleName}
              onChange={onChangeMiddleName}
            />
          </Row>
        ) : (
          <div className="detail-title-text">
            {filedNameMap?.[fieldName]}
            {
              optional && <span className="optional">Optional</span>
            }
          </div>
        )
      } */}
      <div className="detail-title-text" id={id}>
        {type!=='checkbox' && filedNameMap?.[fieldName]}
        {/*{*/}
        {/*  optional && <span className="optional">Optional</span>*/}
        {/*}*/}
        {
          !optional && <span className="required">*</span>
        }
        {
          info && <QuestionCircle onClick={() => onClickInfoIcon?.(info || '')}  className="info-icon" />
        }
      </div>
      {field}
      {
        showOtherError || (errMsg && errorState) ? (
          <ErrorMessage>
            {!hideErrorIcon && <ErrorSvg />}
            <span className="error-message">{showOtherError ? otherErrMsg : errMsg}</span>
          </ErrorMessage>
        ) : null
      }
      {
        tip ? (
          <TitleDescription marginTop={10}>
            {tip}
          </TitleDescription>
        ) : null
      }
    </>
  );
};

export default InputField;
