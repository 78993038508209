import React, { Dispatch, FC, SetStateAction, useRef, useEffect, useMemo, useState } from 'react';
import { Toast } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
// import moment from 'moment-timezone';

import Info from './Info';
import Failed from './Failed';
import VFailed from './VFailed';
import { ContentWrapper, Logo } from '../common/style';
import {
  // createUpdateInfoDetails,
  infoDetailsConfirm,
  getInfoDetails,
  recordInfoDetailsButton,
  getBranchCodeList,
} from '../../api';
import { getQueryVariable, closeLoading, openLoading, replaceString } from '../../utils';
import FinalInfoDetails from './FinalInfoDetails';
import VCodeInfoDetails from './VCodeInfoDetails';
import { Modal } from '../../components';
import { EXPIRE_MODAL_TEXT } from './config';

interface IPageContentProps {
  pageId: string;
  setPageId: React.Dispatch<React.SetStateAction<string>>;
  infoDetails: Record<string, any>;
  onChangeInfoDetails: (dataKey: string, value: any) => void;
  errorCode: string | number;
  mobile: string;
  onInfoNext: () => void;
  noMiddleName: boolean;
  setNoMiddleName: Dispatch<SetStateAction<boolean>>;
  userContext: string;
  contentRef?: React.Ref<{
    setDeadLine: (resCountDown: number) => void;
  }>;
  disableInfoInput?: string[];
  confirmErrorCode?: number | null;
  setConfirmErrorCode?: (code: number | null) => void;
  branchCodeOptions:{label:string, value: string}[]
}

const PageContent:FC<IPageContentProps> = ({
  pageId,
  setPageId,
  infoDetails,
  onChangeInfoDetails,
  errorCode,
  mobile,
  onInfoNext,
  noMiddleName,
  setNoMiddleName,
  userContext,
  contentRef,
  disableInfoInput,
  confirmErrorCode,
  setConfirmErrorCode,
  branchCodeOptions
}) => {
  switch (pageId) {
    case 'failed':
      return (
        <Failed errorCode={errorCode} />
      );
    case 'vFailed':
      return (
        <VFailed errorCode={errorCode} />
      );
    case 'infos':
    case 'otp':
      return (
        <VCodeInfoDetails
          infoDetails={infoDetails}
          onChangeInfoDetails={onChangeInfoDetails}
          onInfoNext={onInfoNext}
          noMiddleName={noMiddleName}
          setNoMiddleName={setNoMiddleName}
          pageId={pageId}
          setPageId={setPageId}
          userContext={userContext}
          ref={contentRef}
          disableInfoInput={disableInfoInput}
          confirmErrorCode={confirmErrorCode}
          setConfirmErrorCode={setConfirmErrorCode}
          branchCodeOptions={branchCodeOptions}
        />
      );
    case 'infoDetails':
    case 'mobileNumberVerification':
      return (
        <FinalInfoDetails
          infoDetails={infoDetails}
          onChangeInfoDetails={onChangeInfoDetails}
          onInfoNext={onInfoNext}
          noMiddleName={noMiddleName}
          setNoMiddleName={setNoMiddleName}
          pageId={pageId}
          setPageId={setPageId}
          userContext={userContext}
          ref={contentRef}
        />
      );
    default: // info
      return (
        <Info
          infoDetails={infoDetails}
          onChangeInfoDetails={onChangeInfoDetails}
          mobile={mobile}
          pageId={pageId}
          setPageId={setPageId}
          onInfoNext={onInfoNext}
        />
      );
  }
};

const InfoDetails:FC = () => {
  const history = useHistory();

  const [pageId, setPageId] = useState('infos');
  // const [pageId, setPageId] = useState('otp');
  const [details, setDetails] = useState<Record<string, any>>({
    firstName: '',
    middleName: '',
    lastName: '',
    birthday: '',
    gender: '',
    // occupation: '',
    mobileNumber: '',
    suffix: '',
  });
  const [mobile, setMobile] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [noMiddleName, setNoMiddleName] = useState(false);
  const [journeyStartTime, setJourneyStartTime] = useState('');

  const [isExpired, setIsExpired] = useState(false);

  const contentRef = useRef<any>();

  const [disableInfoInput, setDisableInfoInput] = useState<string[]>([])

  const [confirmErrorCode, setConfirmErrorCode] = useState<number | null>(null);

  const [branchCodeOptions, setBranchCodeOptions] = useState<{label: string, value: string}[]>([])

  const onChangeInfoDetails = (dataKey: string, value: any) => {
    setDetails(prevState => ({
      ...prevState,
      [dataKey]: value,
    }));
  };

  document.title = 'INFO DETAILS';

  const facebookId = useMemo(() => (getQueryVariable('FacebookId') || '2418762589723692'), []);
  // 打开会话框时的唯一 ID
  const userContext = useMemo(() => (getQueryVariable('UserContext') || '3a0062a1-b014-b70f-1402-572867deee52'), []);

  useEffect(() => {
    const type = Number(getQueryVariable('Type') || 0);
    recordInfoDetailsButton({
      userContext,
      type,
    }).then(res => {
      setJourneyStartTime(res.data);
    });
  }, [userContext]);

  const getBranchOptions = async () => {
    openLoading();
    try {
      const result = await getBranchCodeList()
      if (result.code === 0 && result.data.length) {
        setBranchCodeOptions(result.data.map((item:any) => ({label: item.name, value: item.code})))
      }
    } catch (err) {
      console.error(err);
      Toast.fail('There\'s something wrong with the server.');
    }
    closeLoading()
  }

  useEffect(() => {
    getBranchOptions()
  }, [])

  // 获取当前用户输入的数据详情
  useEffect(() => {
    openLoading();
    getInfoDetails(userContext).then(res => {
      closeLoading();
      if (res.code === 0) {
        const resData = replaceString(res?.data)
        const { firstName, middleName, lastName, birthday, gender, mobileNumber, suffix, hasPolicyOrder, pageId: curPageId, bankReferrerCode, branchCode, bse, isReferrer, refsInUrl } = resData;

        const hasBseOrReferrerCode = !!(bse || bankReferrerCode)
        let currentDetail: Record<string, any> = {
          firstName,
          middleName,
          lastName,
          birthday,
          gender,
          mobileNumber,
          suffix,
          branchCode,
        }
        if (isReferrer || refsInUrl) {
          currentDetail = {
            ...currentDetail,
            isReferrer: refsInUrl && hasBseOrReferrerCode ? true : isReferrer,
            branchCode: branchCode || undefined,
            bse: bse || undefined,
            bankReferrerCode: bankReferrerCode || undefined,
          }
          if (refsInUrl) {
            setDisableInfoInput(['isReferrer', 'bankReferrerCode', 'bse'])
          }
        }
        setDetails(currentDetail)

        setMobile(res?.mobileNumber || '');
        setNoMiddleName(!middleName);
        if (curPageId) {
          setPageId(curPageId);
          contentRef?.current?.setDeadLine(res.data?.countDown);
        }
        if (hasPolicyOrder) {
          const { id, insurancePlanId } = res?.data;
          history.push(`Checkout?InsurancePlanId=${insurancePlanId}&InfoDetailsId=${id}&FacebookId=${facebookId}&userContext=${userContext}`);
        }
      } else {
        if (res?.subErrorCode === 1000) return;
        if (res?.subErrorCode === 1004) {
          setIsExpired(true);
          return;
        }
        setErrorCode(res?.subErrorCode || 1001);
        setPageId('vFailed');
      }
    }).catch(err => {
      console.log(err);
      closeLoading();
      // Toast.fail('There\'s something wrong with the server.');
    });
  }, [history, facebookId, userContext]);

  // const onInfoNext = () => {
  //   openLoading();
  //   createUpdateInfoDetails({
  //     facebookId,
  //     pageId: 'confirm',
  //     ...details,
  //   }).then(res => {
  //     console.log(res);
  //     closeLoading();
  //     if (res?.errCode !== 0) {
  //       setErrorCode(res?.errCode || 1001);
  //     } else {
  //       console.log('confirm');
  //       setPageId('confirm');
  //     }
  //   }).catch(err => {
  //     console.log(err);
  //     closeLoading();
  //     Toast.fail(JSON.stringify(err), 1);
  //   });
  // };

  const onInfoNext = () => {
    openLoading();
    const currentDetail = { ...details };
    if (!currentDetail.isReferrer) {
      delete currentDetail.bankReferrerCode
      delete currentDetail.bse
    }
    if(currentDetail.noMiddleName){
      delete currentDetail.middleName
    }
    delete currentDetail.noMiddleName
    let info:any = {
      facebookId,
      userContext,
      journeyStartTime,
      pageId: 'otp',
      ...currentDetail,
    }
    info = replaceString(info, `'`, `\\'`)
    
    infoDetailsConfirm(info).then(res => {
      closeLoading();
      if (res?.code === 0) {
        contentRef?.current?.setDeadLine(res.data?.countDown);
        // setPageId('mobileNumberVerification');
        setPageId('otp');
        // close();
      } else {
        if ([4012, 4010, 4011].includes(res?.subErrorCode)) {
          setConfirmErrorCode(res?.subErrorCode)
        } else {
          setErrorCode(res?.subErrorCode || 1001);
          setPageId('vFailed');
        }
      }
    }).catch(err => {
      console.log(err);
      closeLoading();
      // Toast.fail('There\'s something wrong with the server.');
    });
  };

  return (
    <ContentWrapper>
      <Logo />
      <PageContent
        pageId={pageId}
        setPageId={setPageId}
        infoDetails={details}
        mobile={mobile}
        onChangeInfoDetails={onChangeInfoDetails}
        errorCode={errorCode}
        onInfoNext={onInfoNext}
        noMiddleName={noMiddleName}
        setNoMiddleName={setNoMiddleName}
        userContext={userContext}
        contentRef={contentRef}
        confirmErrorCode={confirmErrorCode}
        setConfirmErrorCode={setConfirmErrorCode}
        disableInfoInput={disableInfoInput}
        branchCodeOptions={branchCodeOptions}
      />
      <Modal
        visible={isExpired}
        height={237}
        closeMicrosite
      >
        {EXPIRE_MODAL_TEXT}
      </Modal>
    </ContentWrapper>
  );
};

export default InfoDetails;
