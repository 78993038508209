import React, { useImperativeHandle, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from '@apollo/client/utilities';

import Button from '../../components/Button';
import {
  PageTipTitle,
  TitleDescription,
  DetailsContent, BottomButtonWrapper,
  MobileNumberVerification,
} from '../common/style';
import { FIELD_NAME_MAP, INFO_DETAILS_MAP_FIELDS } from './config';
import { closeLoading, closeMessengerBrowser, isEmptyField, openLoading } from '../../utils';
import InputField from './InputField';
import { Row } from '../CheckOut/style';
import { CountDown } from './style';
import { infoDetailsYes } from '../../api';

interface IInfoProps {
  pageId: string;
  setPageId: React.Dispatch<React.SetStateAction<string>>;
  infoDetails: Record<string, any>;
  onChangeInfoDetails: (dataKey: string, value: any) => void;
  onInfoNext: () => void;
  noMiddleName: boolean;
  setNoMiddleName: Dispatch<SetStateAction<boolean>>;
  userContext: string;
  ref?: React.Ref<{
    setDeadLine: (verifyCodeSendTime: any) => void;
  }>;
}

const FinalInfoDetails:FC<IInfoProps> = React.forwardRef(({
  pageId,
  setPageId,
  infoDetails,
  onChangeInfoDetails,
  onInfoNext,
  userContext,
  // noMiddleName,
  // setNoMiddleName,
}, ref) => {
  const [mobileReEnter, setMobileReEnter] = useState(false);
  const [errorState, setErrorState] = useState<Record<string, boolean>>({
    firstName: false,
    middleName: false,
    lastName: false,
    birthday: false,
    gender: false,
    // occupation: false,
    mobileNumber: false,
  });
  const [deadLine, setDeadLine] = useState<number>(0);
  // const onChangeMiddleName = (state: boolean) => {
  //   setNoMiddleName(state);
  //   if (state) {
  //     onChangeInfoDetails('middleName', '');
  //   }
  // };

  const handleChangeInfoDetails = (dataKey: string, value: any) => {
    if (dataKey === 'mobileNumber') {
      setMobileReEnter(false);
    }
    onChangeInfoDetails(dataKey, value);
  };

  const nextDisabled = useMemo(() => {
    const checkParams = cloneDeep(infoDetails);
    delete checkParams.middleName;
    // if (noMiddleName) {
    //   delete checkParams.middleName;
    // }
    return isEmptyField(checkParams) || checkParams.mobileNumber.length !== 10 || isEmptyField(errorState, 'boolean') || !!deadLine;
  // }, [infoDetails, noMiddleName]);
  }, [infoDetails, errorState, deadLine]);

  const handleErrorState = (dataKey: string, state: boolean) => {
    setErrorState(prevState => ({
      ...prevState,
      [dataKey]: state,
    }));
  };

  const close = () => {
    closeMessengerBrowser();
  };

  const onClickYes = () => {
    openLoading();
    infoDetailsYes(userContext).then(res => {
      closeLoading();
      if (res.code === 0) {
        close();
      }
    }).catch(() => {
      closeLoading();
    })
  }

  useEffect(() => {
    document.title = pageId === 'infoDetails' ? 'INFO DETAILS' : 'MOBILE NUMBER VERIFICATION';
  }, [pageId]);

  const onBack = () => {
    setPageId('infoDetails');
    setMobileReEnter(true);
  };

  useImperativeHandle(ref, () => ({
    setDeadLine: (verifyCodeSendTime: string) => setDeadLine(Date.parse(verifyCodeSendTime) + 1000 * 60),
  }));

  const onChangeCountDown = (val: any) => {
    if (val < 1000) {
      setDeadLine(0);
    }
  };

  return (
    <>
      {
        pageId === 'infoDetails' ? (
          <>
            <PageTipTitle>
              We like to know more about you!
            </PageTipTitle>
            <TitleDescription>
              The information you share below will allow us to offer the best plan for you.
            </TitleDescription>
            <DetailsContent>
              {
                INFO_DETAILS_MAP_FIELDS.map(item => (
                  <InputField
                    {...item}
                    key={item.fieldName}
                    details={infoDetails}
                    onChangeDetails={handleChangeInfoDetails}
                    // onChangeMiddleName={onChangeMiddleName}
                    // noMiddleName={noMiddleName}
                    filedNameMap={FIELD_NAME_MAP}
                    setErrorState={handleErrorState}
                    errorState={!!errorState?.[item.fieldName]}
                    showOtherError={item.fieldName === 'mobileNumber' ? mobileReEnter : false}
                    // disabled={noMiddleName && infoDetails.fieldName === 'middleName'}
                  />
                ))
              }
            </DetailsContent>
          </>
        ) : (
          <MobileNumberVerification>
            We sent you an SMS to make sure your mobile number is correct; did you receive it?
            <br/>
            <br/>
            Mobile Number:  <span className="mobile-number">+63 {infoDetails?.mobileNumber}</span>
          </MobileNumberVerification>
        )
      }
      {
        pageId === 'infoDetails' ? (
          <Button
            onClick={onInfoNext}
            types={nextDisabled ? 'disabled' : 'primary'}
          >
            {deadLine ? (
              <>
                <CountDown value={deadLine} format="ss" onChange={onChangeCountDown} />
                s
              </>
            ) : 'NEXT'}
          </Button>
        ) : (
          <BottomButtonWrapper>
            <Row>
              <Button
                width={232}
                types="primary"
                onClick={onClickYes}
              >
                YES
              </Button>
              <Button
                width={80}
                margin="margin-left: 22px;"
                types="ordinary"
                onClick={onBack}
              >
                NO
              </Button>
            </Row>
          </BottomButtonWrapper>
        )
      }
    </>
  );
});

export default FinalInfoDetails;
